var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup graphics_popup project_manager_popup"},[_c('div',{staticClass:"popup_header"},[_c('h3',[_vm._v("project manager")]),_c('a',{staticClass:"close_popup_btn",on:{"click":function($event){return _vm.changePopup('projectManager', false)}}},[_c('span',{staticClass:"inch-icon inch-close-icon"})])]),(_vm.isOffline)?_c('div',{staticClass:"popup_body"},[_c('div',{staticClass:"is_offline"},[_c('Offline')],1)]):_vm._e(),(_vm.isOnline)?_c('div',{staticClass:"popup_body"},[_c('div',{staticClass:"graphics_popup_left"},[_c('div',{staticClass:"panel_row"},[_c('div',{staticClass:"input_with_icon"},[_c('input',{staticClass:"text_input search_input",staticStyle:{"padding-right":"36px"},attrs:{"type":"text","placeholder":"Search projects"},on:{"input":function($event){return _vm.filterProjectsByText($event)}}}),_c('span',{staticClass:"inch-search-icon input_icon"})])]),_c('ul',{staticClass:"categories_list"},[_vm._m(0),_vm._l((_vm.getLocalProjects),function(item){return _c('li',{key:item.id},[(!item.hide)?_c('a',{on:{"click":function($event){return _vm.selectProjectToOpen(item.id)}}},[(!item._isLocal)?[_vm._v(_vm._s(item.name))]:[_vm._v("Untitled Project")]],2):_vm._e()])})],2)]),_c('div',{staticClass:"graphics_popup_right"},[_c('div',{staticClass:"project_items_wrpr",style:({ 'justify-content': 'left' })},[_vm._l((_vm.getLocalProjects),function(item){return [(!item.hide)?_c('div',{key:item.id,ref:'project-ref-' + item.id,refInFor:true,staticClass:"thumb-wrap project radius-4 hover-overlay project_item",style:({
							width: '275px',
							height: '275px',
							background: `url(${item._isLocal ? item.thumb : 'storage/' + item.thumbnail}) #000 center center / cover no-repeat`,
						})},[_c('h3',{staticClass:"project_item_title"},[(!item._isLocal)?[_vm._v(_vm._s(item.name || item.pname))]:[_vm._v("Untitled Project")]],2),_c('div',{staticClass:"date_print"},[_vm._v(_vm._s(item.date_plain ? _vm.parseDatePlain(item.date_plain) : 'Not saved on the server'))]),_c('div',{staticClass:"thumb-icons hidden bottom-right",style:({ 'z-index': 7 })},[(!item._isLocal)?_c('span',{staticClass:"delete inch-icon active inch-trash-icon",on:{"click":function($event){return _vm.deleteProject(item.id, true)}}},[_vm._m(1,true)]):_vm._e()]),_c('div',{staticClass:"project_item_handle",style:({ 'z-index': 6 }),on:{"click":function($event){return _vm.selectProjectToOpen(item.id)}}})]):_vm._e()]})],2)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("recent projects")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumb-tooltip"},[_c('p',[_vm._v("Delete")])])
}]

export { render, staticRenderFns }