<template>
  <div class="tool-item-panel edit_vignette_panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="closeIt">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>Vignette</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" />
      </div>
    </div>
    <div v-bar>
      <div class="panel-content wo_pad ofw-visible">
        <div class="tripple_btns_block panel_row">
          <label class="text_label has_mb">Shape</label>
          <div class="btns_wrpr">
            <div class="multi_line_checkboxes_wrpr vignette_line_checkbox">
              <label class="btn-action-left multi_line_checkbox">
                <input
                  type="radio"
                  name="vignette_shape_radio"
                  value="radial"
                  checked
                  @change="setType"
                />
                <span class="btn-action-left multi_line_checkbox_text" v-tooltip.top="'Radial'">
                  <span class="inch-icon inch-vignette-radial-icon"></span>
                </span>
              </label>
              <label class="multi_line_checkbox">
                <input
                  type="radio"
                  name="vignette_shape_radio"
                  value="square"
                  @change="setType"
                />
                <span class="multi_line_checkbox_text" v-tooltip.top="'Square'">
                  <span class="inch-icon inch-vignette-square-icon"></span>
                </span>
              </label>
              <label class="btn-action-right multi_line_checkbox">
                <input
                  type="radio"
                  name="vignette_shape_radio"
                  value="linear"
                  @change="setType"
                />
                <span class="btn-action-right multi_line_checkbox_text" v-tooltip.top="'Linear'">
                  <span class="inch-icon inch-vignette-linear-icon"></span>
                </span>
              </label>
            </div>
            <a
              href="#"
              class="btn-action-all btn-action btn-action-w-74"
              @click="resetVignetteParams"
              v-tooltip.top="'Reset'"
            >
              <span class="inch-icon inch-clear-reset-icon"></span>
            </a>
          </div>
        </div>
        <div class="panel_row">
          <ColorInput
            :key="updateColor"
            :defColor="color"
            textLabel="Color"
            @input="setColor"
            :suckerHide="true"
            :checkmarkDisabled="true"
            :isFixed="true"
          />
        </div>
        <div class="panel_row">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="getOpacity"
            rangeSliderLabel="Opacity"
            rangeSliderMeasure="%"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            @update="setOpacity"
          />
        </div>

        <!-- <div class="panel_row">
                    <Range
                    rangeSliderId="editOpacity"
                    :rangeSliderValue="getIntensity"
                    rangeSliderLabel="Intensity"
                    rangeSliderMeasure="%"
                    rangeSliderClass="range-blue" 
                    :minValue = 0
                    :maxValue = 100 
                    @update="setIntensity"/>
                </div> -->
        <div class="panel_row">
          <div class="btns-group mt_18">
            <a tabIndex="0" class="grey_btn" v-on:click="closeIt">Cancel</a>
            <a tabIndex="0" class="blue_btn" v-on:click="applyIt">Apply</a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-circle-icon"></span>
            Choose between <i>Radial</i>, <i>Square</i> or <i>Linear</i> <br />
            vignette radius
          </p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-circle-icon"></span>
            Use the onscreen controls to adjust the<br />
            width and height of the vignette effect
          </p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-circle-icon"></span>
            Experiment with <i>Color</i> and <i>Opacity</i> tools <br />
            to achieve the desired look and feel
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import Range from "@/components/RangeLib.vue";
import ColorInput from "@/components/ColorInput.vue";
import Tips from "@/components/Tips.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: "EditVignettePanel",
  components: {
    Range,
    ColorInput,
    Tips,
    AdLeftPanel,
  },
  props: {
    editPanelClass: String,
    editPanelPropChange: Function,
    applyChanges: Function,
    closeCurrentEditor: Function,
  },
  data: function() {
    const defaultVignette = {
      opacity: 30,
      intensity: 100,
      color: "#000000",
    };

    return {
      tipsText:
        "The Vignette tool creates a darkened and feathered border around your image, giving your photos an elegant lens effect drawing attention to the center. ",
      helpIcon: require("@/assets/img/help-icon.svg"),
      libVignette: false,
      vigneteType: "radial",
      opacity: defaultVignette.opacity,
      intensity: defaultVignette.intensity,
      color: defaultVignette.color,
      defaultVignette,
      updateColor: 0,
    };
  },
  mounted() {
  	this.$store.state.lockZoom = true;
  	
    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.$store.dispatch("fabricSlide", true);
    this.initVignette();

    // this.libVignette.vignetteEffect.setFillColor - задать цвет в hex
    // Первый параметр цвет, второй рендерить или нет после сейва цвета, булевое значение

    // this.libVignette.vignetteEffect.setOpacity
    // так же как и с цветом, второй параметр будет рендерить или нет, первый само opacity

    // this.libVignette.vignetteEffect.setIntensity
    // интенсивность

    // this.libVignette.destroy() дестрой

    // this.libVignette.initSelect(type)
    // this.libVignette.vignetteEffect.setType(type, isRender)
    // сменить тип вигнетки, radial, square and linear, значение по умолчанию “radial”
  },

  beforeDestroy() {
  	this.$store.state.lockZoom = false;
  	
    this.libVignette.destroy();
    this.$store.dispatch("fabricSlide", false);
  },

  methods: {
    resetVignetteParams() {
      this.opacity = this.defaultVignette.opacity;
      this.intensity = this.defaultVignette.intensity;
      this.color = this.defaultVignette.color;

      this.updateColor += 1;
      this.initVignette();
    },

    initVignette() {
      window.q = this.libVignette = new this.$lib.edit.Vignette(
        this.$lib.app.stage.children[0],
        this.vigneteType,
        this.intensity / 100,
        this.opacity / 100,
        this.color
      );

      window.libVignette = this.libVignette;
      this.libVignette.initSelect(this.vigneteType, true);
      this.updateColor += 1;
    },

    async applyIt() {
      //.select.destroyRender();
      await this.libVignette.vignetteEffect.addToCache();
      // await this.$lib._cache.addToBgCacheUpdating(
      //     this.$lib.CONST.MAP_CHANGES.BG_VIGNETTE_EFFECT,
      //     {  }, true, { isThumb: true }
      // );

      this.libVignette.select.destroyRender();
      this.applyChanges(this.$lib);
      this.$store.dispatch("changesApplied");
      //this.libVignette.destroy()
    },

    closeIt() {
      this.libVignette.destroy();
      this.closeCurrentEditor(this.$lib);
    },

    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },

    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },

    changesAppliedShow() {
      this.changePopup("changesApplied", true);
      this.editPanelPropChange();
      setTimeout(() => {
        this.changePopup("changesApplied", false);
      }, 1000);
    },

    setColor(val) {
      this.color = val;
      this.libVignette.vignetteEffect.setFillColor(val, true);
      this.$lib.app.reRenderCanvas();
    },

    setType(val) {
      //console.log({ setType: val, val: val.target.value });
      this.vigneteType = val.target.value;
      this.initVignette();
      this.$lib.app.reRenderCanvas();
    },

    setOpacity(val) {
      this.opacity = val;
      this.libVignette.vignetteEffect.setOpacity(val / 100, true);
      this.$lib.app.reRenderCanvas();
    },
    setIntensity(val) {
      this.intensity = val;
      this.libVignette.vignetteEffect.setIntensity(val / 100, true);
      this.$lib.app.reRenderCanvas();
    },
  },
  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyIt();
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
  },
  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },
    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },
    getOpacity() {
      return this.opacity;
    },
    getIntensity() {
      return this.intensity;
    },
  },
};
</script>
