<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="closeIt">
            <div class="back">
                <a href="#" class="back-icon">
                    <span class="inch-icon inch-back-icon"></span>
                </a>
                <h3>Replace Color</h3>
            </div>
            <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
                <img :src="helpIcon" alt="" />
            </div>
        </div>

        <div v-bar>
            <div class="panel-content wo_pad">
                <div id="tabs">
                    <ul class="button-tabs-instrumets tabs-nav">
                        <li class="btn-action margin_bottom_15 full">
                            <a href="#" :class="{ active: tab == 'adjust' }" @click="changeTab('adjust')" draggable="false">Adjust</a>
                        </li>

                        <li class="btn-action margin_bottom_15 full" :class="{ disabled: !uniforms.from || !uniforms.to }">
                            <a href="#" :class="{ active: tab == 'erase' }" @click="changeTab('erase')" draggable="false">Erase</a>
                        </li>
                    </ul>
                </div>

                <EraseTool v-show="tab == 'erase'"
                    :tool="tool" :eraseConfig="eraseData" @resetDrawing="resetDrawing(true, true)"
                    @changeConfig="changeConfig" @changeToolName="changeToolName"
                />

                <div class="panel_row" v-show="tab == 'adjust'">
                    <div class="color-range">
                        <div>
                            From
                            <div class="color-replace-input t">
                                <div class="color-picker-replace" @click.self="$refs.fromColor.showColorPicker = !$refs.fromColor.showColorPicker">
                                    <ColorInput
                                        ref="fromColor"
                                        :defColor="uniforms.from || '#ffffff'"
                                        :suckerHide="false" :checkmarkDisabled="true"
                                        :isFixed="true" :class="{ hiidddde: !uniforms.from }"
                                        @input="changeUniformValue('from', $event)"
                                        :suckerPixels="suckerPixels"
                                    />

                                    <div class="color-ln" v-if="!uniforms.from"></div>
                                </div>

                                <img :src="require('@/assets/img/pick.svg')" draggable="false" @click="openPicker('fromColor')"/>
                            </div>
                        </div>

                        <div>
                            To
                            <div class="color-replace-input">
                                <div class="color-picker-replace" @click.self="$refs.toColor.showColorPicker = !$refs.toColor.showColorPicker">
                                    <ColorInput
                                        ref="toColor"
                                        :defColor="uniforms.to || '#000000'"
                                        :suckerHide="false"
                                        :checkmarkDisabled="true"
                                        :isFixed="true"
                                        :class="{ hiidddde: !uniforms.to }"
                                        @input="changeUniformValue('to', $event)"
                                        :suckerPixels="suckerPixels"
                                    />

                                    <div class="color-ln" v-if="!uniforms.to"></div>
                                </div>

                                <img :src="require('@/assets/img/pick.svg')" draggable="false" @click="openPicker('toColor')"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel_row" v-show="tab == 'adjust'">
                    <RangeLib :rangeSliderValue="uniforms.range"
                        rangeSliderLabel="Color Range"
                        rangeSliderMeasure="%"
                        rangeSliderClass="range-blue" :minValue="1" @update="changeUniformValue('range', $event)" :maxValue="100"/>
                </div>

                <div class="panel_row margin_bottom_15" v-show="tab == 'adjust'">
                    <RangeLib
                            rangeSliderId="editOpacity"
                            :rangeSliderValue="uniforms.opacity"
                            rangeSliderMeasure="%"
                            rangeSliderLabel="Opacity"
                            rangeSliderClass="range-blue"
                            :minValue="0"
                            :defaultValue="1"
                            :maxValue="100"
                            @update="changeUniformValue('opacity', $event)"
                    />
                </div>

                <div class="d-flex between two-column panel_row">
                    <div class="half">
                        <a tabIndex="0" class="button btn-action cancel" v-on:click="closeIt">cancel</a>
                    </div>

                    <div class="half">
                        <a tabIndex="0" class="button btn-action upgrade" v-on:click="applyIt">{{ !isPremium ? 'upgrade' : 'apply'}}</a>
                    </div>
                </div>
                <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
            </div>
        </div>

        <div class="pop_up_closer" v-if="getPopupStatus" @click="popUpPropChange('popupsToggleStatus', false)"></div>
        <Tips v-if="getPopupStatus" :text="tipsText">
            <template v-slot:body>
                <div class="bottom-line">
                    <div class="tooltip_row_item">
                        <p class="simple_light_p with_icon">
                            <span class="inch-icon"><img :src="eyeDropperIcon" alt="" /></span>
                            Use the Eye Dropper tool to select the from/to color in your photo
                        </p>
                    </div>
                    <div class="tooltip_row_item">
                        <p class="simple_light_p with_icon">
                            <span class="inch-icon"><img :src="sliderIcon" alt="" /></span>
                          Choose a low percentage <i>Color Range</i> to replace colors very similar to the pixel you select, or raise the percentage to replace a broader range of colors
                        </p>
                    </div>
                </div>

                <div :class="{'bottom-line': !isPremium, 'top-padding': true }">
                    <div class="tooltip_row_item">
                        <p class="simple_light_p">
                          Use <i>Erase</i> mode to selectively apply color changes to a specific part of your image.
                        </p>
                    </div>
                    <div class="tooltip_row_item">
                        <p class="simple_light_p with_icon">
                            <span class="inch-icon"><img :src="eraseIcon" alt="" /></span>
                            Manually erase parts of the image
                        </p>
                    </div>
                    <div class="tooltip_row_item">
                        <p class="simple_light_p with_icon">
                            <span class="inch-icon"><img :src="paintIcon" alt="" /></span>
                            Manually restore parts of the image
                        </p>
                    </div>
                </div>

                <div v-if="!isPremium" class="tooltip_row_item top-padding">
                    <p class="simple_light_p with_icon">
                        <span class="inch-icon"><img :src="membershipIcon" alt="" /></span>
                        Indicates the tool is a Colorcinch Plus feature. Get <a class="blue-color-text" v-on:click="upgradePlan()">Colorcinch Plus</a>
                    </p>
                </div>
            </template>
        </Tips>
    </div>
</template>

<script>
import RangeLib from '@/components/RangeLib.vue';
import Tips from '@/components/Tips.vue';
import ColorInput from '@/components/ColorInput.vue';
import _ from 'lodash';
import Range from "@/components/Range.vue";
import EraseTool from "@/components/EraseTool.vue";

import MixinPopupBrush from '@/mixin/MixinPopupBrush';
import EarseToolMixin from "@/mixin/EarseToolMixin";
import LoaderMixin from '@/mixin/LoaderMixin';
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: 'ReplaceColorPanel',
  mixins: [MixinPopupBrush, EarseToolMixin, LoaderMixin],
  components: { RangeLib, Tips, ColorInput, Range, EraseTool, AdLeftPanel },

  props: {
      editPanelClass: String,
      editPanelPropChange: Function,
      closeCurrentEditor: Function,
      applyChanges: Function,
  },

  data() {
      return {
          tipsText: 'The Replace Color tool allows you intelligently replace any color range in your image with another color.',
          helpIcon: require("@/assets/img/help-icon.svg"),
          eyeDropperIcon: require("@/assets/images/eyedropper-icon.svg"),
          sliderIcon: require("@/assets/images/slider.svg"),
          eraseIcon: require("@/assets/images/erase-icon.svg"),
          paintIcon: require("@/assets/images/paint.svg"),
          membershipIcon: require("@/assets/images/Plus-Membership-Icon.svg"),
          suckerHide: true,
          colorPickers: {
              from: false,
              to: false
          },

          uniforms: {
              from: false,
              to: false,
              range: 50,
              opacity:70,
          },
          _isCurrentMixin: true,
          pickerKey: 1,
          suckerPixels: false
      };
  },

  created() {
      window._isCurrentMixin = true;
  },

  mounted() {
    // _LIB.app.createSmallImage();

    this.updatePixels();
    this.updateGlobalEarseConfig();
    this.selectPointerEvents(true);
    this.changeConfig({ key: 'brushSize', value: this.eraseData.brushSize });

    this.$store.dispatch("fabricSlide", false);
    this.applyFilter();

    //this.$store.commit("changePremiumStatus", false);
    window.tt = this;
  },

  async beforeDestroy() {
    this.closeIt();
    // await this.closeCurrentEditor();
    // window._isCurrentMixin = false;
  },

  methods: {
      upgradePlan() {
        // if (window.abVariant && window.abVariant == 2) {
              this.$store.dispatch("popupsToggle", {
                  property: "stripeCheckoutPopup",
                  value: true,
              });
        //   }
        //   else{
        //       this.$store.dispatch("popupsToggle", {
        //           property: "upgradePopup",
        //           value: true,
        //       });
        //   }
      },
    updatePixels() {
        const region = new PIXI.Rectangle(0, 0, _LIB.app.stage.width, _LIB.app.stage.height);
        const texture = _LIB.app.renderer.generateTexture(_LIB.app.stage, 1, 1, region);

        this.suckerPixels = _LIB.app.renderer.extract.pixels(new PIXI.Sprite(texture));
        texture.destroy(true);
    },

    changeTab(tab) {
        this.tab = tab;
        this.$store.dispatch("fabricSlide", tab == 'erase');

        if(tab == 'erase') {
            this.lzLock();
            this.$refs.toColor.showColorPicker = false;
            this.$refs.fromColor.showColorPicker = false;

            this.updateCanvasSizeByZoom();
            this.changeConfig({ key: 'brushSize', value: this.eraseData.brushSize });
        } else {
            this.lzDestroy();
        }
    },

    ticker() {
        const sprite = this.exportDraw();
        this.setMaskTexture(sprite, true, true);
    },

    setupEffect() {
        this.applyFilter();
    },

    changeUniformValue(key, value) {
        this.uniforms[key] = value;
        if(['to', 'from'].includes(key)) {
            this.updatePixels();
        }

        this.applyFilter();
    },

    async applyFilter() {
        if(!this.uniforms.from || !this.uniforms.to) return;
        const uniforms = _.cloneDeep({
            originalColor: this.uniforms.from,
            newColor: this.uniforms.to,
            epsilon: this.uniforms.range / 100,
            preserveLuminosity: 0,
            targetAlpha: this.uniforms.opacity / 100,
        });

        await this.$lib.effects.replaceColor(uniforms, this.maskTexture, this.eraseData, () => this.ticker());

        if(this.tab == 'adjust') {
            this.$nextTick(() => {
                this.$store.dispatch("fabricSlide", false);
            });
        }
    },

    openPicker(key) {
        const pickers = ['fromColor', 'toColor'];
        for(const pick of pickers) {
            const r = this.$refs[pick];

            r.openSucker(false);
            r.showColorPicker = false;
        }

        const ref = this.$refs[key];

        // if(this.colorPickers[key]) {
        //     ref.openSucker(false);
        //     this.suckerHide = true;
        // } else {
            //this.suckerHide = false;
        if(ref) {
            ref.showColorPicker = true;
            this.$nextTick(() => {
                //setTimeout(() => {
                    ref.openSucker(true);
                    this.$store.dispatch("fabricSlide", false);
                //}, 2000);
            });
        //}

            this.colorPickers[key] = !this.colorPickers[key];
        }
    },

    closeIt() {
        this.lzDestroy();
        this.$lib.Drawing.initDraw(false);

        this.closeCurrentEditor();
        window._isCurrentMixin = false;
        this.$store.commit("changePremiumStatus", false);
        this.$store.dispatch("fabricSlide", false);
    },

    async applyIt() {
        if(!this.isPremium){
            // if (window.abVariant && window.abVariant == 2) {
              return this.$store.dispatch("popupsToggle", {
                  property: "stripeCheckoutPopup",
                  value: true,
              });
        //   }
        //   else{
        //       return this.$store.dispatch("popupsToggle", {
        //           property: "upgradePopup",
        //           value: true,
        //       });
        //   }
        }

        await this._preloader(true);
        try {
            await this.applyMixin();

            this.eraseData.showMask = false;
            await this.applyFilter();
            await this.$lib._cache.addToBgCacheUpdating(this.$lib.CONST.MAP_CHANGES.BG_VIBRANCE_EFFECT, {}, true, { isThumb: true });

            await this.applyChanges(this.$lib);
            this.$store.dispatch("changesApplied");

            this.closeIt();
        } catch (err) {
            console.error(err);
        }

        await this._preloader(false);
    },

    popUpPropChange(property, value) {
        this.$store.dispatch('popupsToggleStatus', { property, value });
    }
  },

  watch: {
      currentZoomPosition() {
        this.updatePixels();
      }
  },

  computed: {
      isLeftAd() {
          return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
      },
    getPopupStatus() {
        return this.$store.state.global.popupStatus.value;
    },

    isPremium() {
        return this.$store.state.isPremiumAccount;
    },

    suckerCanvas() {
        return document.getElementById('canvasDashboard');
    },

    currentZoomPosition() {
      return this.$store.state.currentZoomPosition;
    },
  }
}
</script>
<style scoped src="@/assets/css/popupStyles.css"></style>

<style scoped>
    .bottom-line{
        padding-bottom: 18px;
        border-bottom: 1px solid #303030;
    }
    .top-padding{
        padding-top: 18px;
    }
    .blue-color-text{
        color: #249ACF;
    }
    .color-range {
        display: flex;
        justify-content: space-between;
    }

    .color-range > div {
        /* flex: 1; */
        text-align: left;
        font: normal normal normal 14px/17px Proxima Nova;
        letter-spacing: 0px;
        color: #8C8C8C;
        position: relative;
    }

    .color-replace-input {
        border: 1px solid #3F3F3F;
        border-radius: 4px;
        height: 36px;
        width: 104px;
        margin-top: 7px;
        display: flex;
        justify-content: space-between;
    }

    .color-picker-replace {
        width: 36px;
        height: 20px;
        background: #303030 0% 0% no-repeat padding-box;
        border-radius: 4px;
        margin: 8px 0 0 8px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color-picker-replace > div {
        width: 100%;
        height: 100%;
        overflow: hidden;
        justify-content: center;
    }

    .color-replace-input img {
        width: 16px;
        height: 16px;
        margin: 8px;
    }

    .color-replace-input > * {
        cursor: pointer;
    }

    .color-replace-input.t::after {
        position: absolute;
        content: "\e90d";
        color: #8c8c8c;
        right: -7px;
        top: 42px;
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 3.5px;
        height: 6px;
        transform: rotate(270deg) scale(.30);
    }
</style>

<style>
    .hide_picker .color_picker_component {
        display: none;
    }

    .color-ln {
        pointer-events: none;
    }
</style>
