<template>
    <label class="def_checkmark" :class="getClass()">
        <input type="checkbox" :disabled="disabled" :checked="checkboxCheck" @input="$emit('update', $event)">
        <span class="def_checkmark_icon"></span>
        <span v-if="msg" class="def_checkmark_text">{{msg}}</span>
    </label>
</template>


<script>
    export default {
        name: 'Checkmark',
        props: {
            msg: String,
            classs: {
                type: String,
                default: ""
            },
            checkboxCheck: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data: function() {
            return {
                checked: this.checkboxCheck || false
            }
        },
        methods: {
            getClass() {
                return this.classs + (this.disabled ? " disabled" : "");
            }
        }
    }
</script>