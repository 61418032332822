import { render, staticRenderFns } from "./Checkmark.vue?vue&type=template&id=3a800936&"
import script from "./Checkmark.vue?vue&type=script&lang=js&"
export * from "./Checkmark.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports