<template>
	<div class="popup save_popup" v-if="savePopupShow"  :class="{'portret_image':portraitImage}">
		<div class="popup_header">
			<h3>save project</h3>
			<a class="close_popup_btn" v-on:click="changePopup('SaveAsProject', false)">
				<span class="inch-icon inch-close-icon"></span>
			</a>
		</div>
		<div class="popup_body">
			<div class="save_project_img">
				<img :src="saveProjectImg" alt id="project_preview_img" @load="previewImageLoad"/>
			</div>
			<div class="popup_body_row">
				<div class="popup_body_row_item">
					<label class="text_label has_mb">Filename</label>
					<div class="input_text_wrpr">
						<input
							type="text"
							class="text_input"
							autofocus ref="projectName"
							v-model="textInputValue"
							v-on:input="checkInputChange"
						/>
						<span
							class="input_empty_error"
							v-bind:class="{ active: inputEmptyError }"
						>Oops... Filename missing</span>
					</div>
				</div>
				<div class="popup_body_row_item save_popup_text_row">
					<p class="simple_light_p">
						Projects are saved to your Colorcinch library. You can continue to edit a project with Colorcinch after it's been saved.
						<!-- {{saveAsProjectText}} -->
					</p>
				</div>
				<div class="popup_body_row_item">
					<div class="btns-group mt0">
						<a class="grey_btn" v-on:click="changePopup('SaveAsProject', false)">Cancel</a>
						
						<div class="save-btn-block">
							<a class="blue_btn btn_with_dropdown">
								<div class="btn_left_side" @click.self.stop="checkInputEnter(textInputValue)">save</div>
								<div class="btn_inner_arrowdown" v-if="(projectSave && projectSave.project_id)"
									v-tooltip.click="{ html: 'tooltipHtml', bottom: true, class: 'weee' }" :class="{ 'active':openSaveDropdown }">
									<svg xmlns="http://www.w3.org/2000/svg" width="9" height="5">
										<path d="M5.04 4.841a.574.574 0 01-.4.159h-.282a.59.59 0 01-.4-.159L.111 1.128a.351.351 0 010-.512L.644.104a.378.378 0 01.525 0l3.329 3.214L7.829.106a.386.386 0 01.533 0l.525.512a.351.351 0 010 .512z" fill="#ffffff"></path>
									</svg>
								</div>
							</a>
							<!-- <a v-if="(projectSave && projectSave.project_id)" draggable="false" class="row_arr"
								@click.prevent v-tooltip.click="{ html: 'tooltipHtml', bottom: true, class: 'weee' }">
								<span class="accordion_arrow">
									<span class="inch-down-arrow-icon"></span>
								</span>
							</a> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="tooltipHtml" class="tooltip-content">
			<a href="#" @click="checkInputEnter(textInputValue, true)">Save as New Project</a>
		</div>
	</div>
</template>

<script>

import OfflineProjects from "@/libs/offline-projects";
import indexdb from '../libs/indexdb';

export default {
	name: "SaveAsProject",
	props: {
		savingThisProject: Function,
		saveAsProjectText: String
	},
	methods: {
		previewImageLoad(e){
			if(e.target && e.target.clientWidth < e.target.clientHeight){
				this.portraitImage = true;
			} else {
				this.portraitImage = false;
			}
		},
		changePopup(property, value) {
			this.$store.dispatch("popupsToggle", { property, value });
		},

		async checkInputEnter(value, isNew = false) {
			if(!this.projectSave || !this.projectSave.project_id) isNew = true;
			if (value.length) {
				// this.changePopup("SaveAsProject", "YES");
				this.savePopupShow = false;

				var obj = await this.$lib._project.downloadProjectCache(
					value,
					false,
					data => {
						if (data.percent < 100) {
							this.$store.dispatch("preloader", {
								isShow: true,
								message: `Saving as project ${
									data.percent > 0 ? " " + parseInt(data.percent) + "%" : "..."
								}`
							});
						} else {
							console.log(data);
							this.$store.dispatch("preloader", {
								isShow: true,
								message: `Saving as project ${data.percent >= 100 ? '100' : '0'}%`
							});
						}
					}
				);

				obj.pname = value;
				obj.thumb = await this.$lib.app.extractThumbByBase64(
					this.saveProjectImg, 400, 400
				);
				
				if(!isNew) {
					obj = { ...(this.projectSave ? this.projectSave : {}), ...obj };
				}

				if(navigator.onLine){
					await this.$store.dispatch("saveProject", obj);
				}else{
					var op = new OfflineProjects();
					await op.init();
					await op.add(obj);
				}
				
				this.$store.dispatch("preloader", {
					isShow: true,
					message: "Project saved to library!"
				});

				setTimeout(() => {
					this.$store.dispatch("preloader", {
						isShow: false,
						message: ""
					});
				}, 500);

				this.changePopup("SaveAsProject", false);

				const historyChanges = this.$lib._cache._storeData;
				const lastSave = historyChanges[historyChanges.length - 1];

				if(lastSave) this.autosave.lastSaveId = lastSave._id;
				// this.savingThisProject('Saving as project...', 'Project saved to library', 3000, 'without_bg');
			} else {
				this.inputEmptyError = true;

				let input = document.querySelector("[autofocus]");
				input.focus();
			}
		},
		checkInputChange() {
			if (this.textInputValue.length) {
				this.inputEmptyError = false;
			}
		},

    async setProjectDownloadImg() {
      //const blob = await _LIB.app.getExportImage('jpeg', 90);
      this.saveProjectImg = await this.$lib.app.extractStage("base64", { isFull: true }, true);
    }
	},

	computed: {
		projectSave() {
			return this.$store.state.projectSave;
		},

		autosave: {
			get() { return this.$store.state.autosave; },
			set(val) { return this.$store.state.autosave = val; }
		},
	},

	data: function() {
		return {
			textInputValue: "",
			inputEmptyError: false,
			savePopupShow: true,
			saveProjectImg: "",
			openSaveDropdown: false,
			portraitImage: false,
		};
	},
	mounted() {
		let input = document.querySelector("[autofocus]");
		if (input) { input.focus(); }
		this.textInputValue = 'Untitled Project';
		if(this.projectSave && (this.projectSave.pname || this.projectSave.name)) {
			this.textInputValue = this.projectSave.pname || this.projectSave.name;
		}

		this.$nextTick(() => this.$refs.projectName.select());
		this.setProjectDownloadImg();
	}
};
</script>

<style scoped>
	.save-btn-block .blue_btn {
		border-radius: 2px 0 0 2px;
	}

	.save-btn-block .row_arr:hover {
    	background: #1D7EA8;
	}

	.save-btn-block .row_arr {
		    margin: 0 !important;
			width: 32px !important;
			background: #249ACF;
			font-weight: inherit;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 0.24px;
			color: #fff;
			padding: 11px 0;
			border: 0;
			text-align: center;
			max-height: 36px;
			display: inline-block;
			cursor: pointer;
			border-radius: 0 2px 2px 0;
	}

	.save-btn-block .inch-down-arrow-icon:before {
		color: #fff !important;
	}

	.save-btn-block .accordion_arrow {
		user-select: none;
		pointer-events: none;
	}
</style>

<style>
	@keyframes slidein {
		from {
			margin-top: -10px;
		}
		to {
			margin-top: 5px;
		}
	}
	.vue-tooltip.weee {
		opacity: 0;
	}
	.vue-tooltip.weee.vue-tooltip-visible {
		opacity: 1;
		animation-duration: 0.3s;
  		animation-name: slidein;
	}
	.vue-tooltip.weee {
		/* -webkit-transition: 0s !important;
		transition: 0s !important; */
		transition: 0.15s opacity, top, zoom !important;
		height: 45px;
		width: 176px;
		left: -69px !important;

		background: #1D1D1D;
    	border-radius: 6px;
	}

	.vue-tooltip.weee div {
		width: 100%;
    	height: 100%;
	}

	.vue-tooltip.weee a {
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #D7DCDD;
    	font-size: 14px;
	}

	.vue-tooltip.weee .tooltip-arrow {
		left: 146px !important;
		width: auto;
		height: auto;
		display: block;
		border-bottom-color: #1D1D1D !important;
	}
</style>
