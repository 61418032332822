<template>
    <div>
        <div class="plus-feature-wrap" @click="openUpgradePop()" style="pointer-events: all;">
            <div class="plus-feature">
                <span class="inch-icon inch-plus-membership-icon"></span>
                <div class="title">Plus Feature</div>
                <p>
                    Upgrade to Colorcinch Plus to unlock this feature
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PremiumInfo",
    methods: {
        openUpgradePop() {
            // if (window.abVariant && window.abVariant == 2) {
				this.$store.dispatch("popupsToggle", { property: "stripeCheckoutPopup", value: true });
			// }
			// else{
			// 	this.$store.dispatch("popupsToggle", { property: "upgradePopup", value: true });
			// }
        }
    }
}
</script>
