//import store from '@/store/index.js';

import {loadStripe} from '@stripe/stripe-js/pure';
export default class{
	constructor(store, that){
		this.parent = that;
		
		this.store = store;
		this.paymentToken = false;
		this.merchantId = "BCR2DN6TZ7WNRRIX";
		this.environment = "PRODUCTION"
		
		this.acceptedPromos = [];
		
		this.baseRequest = {
			apiVersion: 2,
			apiVersionMinor: 0
		};

		this.tokenizationSpecification = {
			type: 'PAYMENT_GATEWAY',
			parameters: {
				"gateway": "stripe",
				"stripe:version": "2018-10-31",
				"stripe:publishableKey": stripe_pub,
			}
		};

		this.allowedCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"];
		this.allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
		this.baseCardPaymentMethod = { type: 'CARD', parameters: { allowedAuthMethods: this.allowedCardAuthMethods, allowedCardNetworks: this.allowedCardNetworks } };
		this.cardPaymentMethod = Object.assign(
			{},
			this.baseCardPaymentMethod,
			{tokenizationSpecification: this.tokenizationSpecification},
		);
		this.paymentsClient = false;
	}


	getGoogleTransactionInfo(){
		return {
			displayItems: [
				{
					label: this.plan_data.title,
					type: 'SUBTOTAL',
					price: this.plan_data.price,
					status: 'FINAL'
				}
			],
			currencyCode: 'USD',
			totalPriceStatus: 'FINAL',
			totalPrice: this.plan_data.price,
			totalPriceLabel: 'Total'
		};
	}


	getGooglePaymentDataRequest(plan_data={}){
		const paymentDataRequest = Object.assign({}, this.baseRequest);
		paymentDataRequest.allowedPaymentMethods = [this.cardPaymentMethod];

		paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo(); //TODO FIX ???
		paymentDataRequest.merchantInfo = { merchantName: 'Colorcinch', merchantId: this.merchantId  }; //TODO change
		paymentDataRequest.callbackIntents = ['OFFER'];
		paymentDataRequest.shippingAddressRequired = false;
//		paymentDataRequest.shippingAddressParameters = this.getGoogleShippingAddressParameters();
		return paymentDataRequest;
	}
	
	
	async buttonClick(){
		this.parent.formErrors = [];
		/*if(!this.parent.agree_terms){
			this.parent.formErrors.push("You must agree to the Colorcinch Privacy Policy and Terms");
			return false;
		}*/
		
		

		var plan_data = await this.sendAjax(ajax_urls.stripe.getPlan, {plan_id: this.parent.selectedPlanId});
		this.plan_data = plan_data;

		if(!plan_data){
			this.parent.formErrors.push("Unknown error. Reload page, and try again");
			return false;
		}

		this.parent.formErrors = [];
		
		const paymentDataRequest = this.getGooglePaymentDataRequest(plan_data);
		paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();
		
		var paymentData = await this.paymentsClient.loadPaymentData(paymentDataRequest);
		var paymentToken = JSON.parse(paymentData.paymentMethodData.tokenizationData.token);
		this.promoCode = paymentData.offerData.redemptionCodes[0] || false;
		
		const event = new CustomEvent('gpay_token_generated', {detail: {paymentToken: paymentToken, promocode: this.promoCode}});
		document.dispatchEvent(event);
		
		return false;
	}
	
	onPaymentDataChanged(intermediatePaymentData){
		return new Promise(async (resolve, reject)=>{
		
			let redemptionCodes = []
			let paymentDataRequestUpdate = {};
			paymentDataRequestUpdate.newTransactionInfo = this.getGoogleTransactionInfo();

			if(typeof intermediatePaymentData.offerData != 'undefined') {
				redemptionCodes = intermediatePaymentData.offerData.redemptionCodes;
			}
			if (intermediatePaymentData.callbackTrigger === 'OFFER') {
				paymentDataRequestUpdate.newOfferInfo = {};
				paymentDataRequestUpdate.newOfferInfo.offers = [];
				
				var promo = redemptionCodes[0] || false;
				if(typeof redemptionCodes[1]!=='undefined'){
					paymentDataRequestUpdate.error = this.getGoogleOfferInvalidError(redemptionCodes[1], "Only one promo code may be used per order");
				}else{
					var checked_promo = await fetch(
						`${window.prefixURL}/check_promocode?code=`+redemptionCodes[0]+"&gpay=1"
					).then((res) => res.json());				
					if(checked_promo.success){
						checked_promo.value = parseFloat(checked_promo.value);
						switch(checked_promo.type){
							case 'percent':	
								paymentDataRequestUpdate = this.percentageDiscount(checked_promo, paymentDataRequestUpdate);
							break;
							case 'fixed':
								paymentDataRequestUpdate = this.staticDiscount(checked_promo, paymentDataRequestUpdate);
							break;
							default:
								paymentDataRequestUpdate.error = this.getGoogleOfferInvalidError(redemptionCodes[0]);					
							break;
						}
						//paymentDataRequestUpdate = this.validPromoCodes[redemptionCode].function
					}else{
						if(redemptionCodes[0]){
							paymentDataRequestUpdate.error = this.getGoogleOfferInvalidError(redemptionCodes[0]);					
						}
					}
				}
			}
			
			paymentDataRequestUpdate.newTransactionInfo = this.calculateNewTransactionInfo(
				paymentDataRequestUpdate.newTransactionInfo
			)
			

			
			resolve(paymentDataRequestUpdate);
		});
	}

	calculateNewTransactionInfo(newTransactionInfo) {
		let totalPrice = 0.00;
		newTransactionInfo.displayItems.forEach(
			function(displayItem) {
				totalPrice += parseFloat(displayItem.price);
			}
		);
		newTransactionInfo.totalPrice = totalPrice.toFixed(2);

		return newTransactionInfo;
	}
	
	async init(button_container, plan_id=0){
		this.stripe = await loadStripe(window.stripe_pub);
		this.plan_id = plan_id;
		
		try{
			this.paymentsClient = this.getGooglePaymentsClient();
			
			var response = await this.paymentsClient.isReadyToPay(this.getGoogleIsReadyToPayRequest());
			if(response.result){
				const button = this.paymentsClient.createButton({onClick: ()=>{ this.buttonClick() } });
				button_container.appendChild(button);
			}

		}catch(e){
			console.error("ERROR", e);
		}
	}



	percentageDiscount(promoParameters, paymentDataRequestUpdate) {
		
		
		let originalTransactionInfo = this.getGoogleTransactionInfo();
		let newTransactionInfo = paymentDataRequestUpdate.newTransactionInfo;
		let discount = 0;

		paymentDataRequestUpdate.newOfferInfo.offers.push({
			redemptionCode: promoParameters['code'],
			description: promoParameters['description']
		});

		originalTransactionInfo.displayItems.forEach(function(displayItem) {
			discount += parseFloat(displayItem.price) * promoParameters['value'] * 0.01;
		});
		

		newTransactionInfo.displayItems.push({
			label: "PROMO "+promoParameters['code'],
			price: discount.toFixed(2),
			type: 'LINE_ITEM'
		});

		return paymentDataRequestUpdate;
	}




	staticDiscount(promoParameters, paymentDataRequestUpdate) {
		let newTransactionInfo = paymentDataRequestUpdate.newTransactionInfo;

		paymentDataRequestUpdate.newOfferInfo.offers.push({
			redemptionCode: promoParameters['code'],
			description: promoParameters['description']
		});

		newTransactionInfo.displayItems.push({
			label: "PROMO "+promoParameters['code'],
			price: promoParameters['value'].toFixed(2),
			type: 'LINE_ITEM'
		});

		return paymentDataRequestUpdate;
	}


	getGoogleOfferInvalidError(redemptionCode, text="") {
	
		return {
			reason: 'OFFER_INVALID',
			message: text? text : redemptionCode + ' is not a valid promo code.',
			intent: 'OFFER'
		};
	}

	getGooglePaymentsClient(){

		if(!this.paymentsClient ) {
			this.paymentsClient = new google.payments.api.PaymentsClient({
				environment: this.environment,
				merchantInfo: {
					merchantName: 'Colorcinch',
					merchantId: this.merchantId
				},
				paymentDataCallbacks: {
					onPaymentDataChanged: (intermediatePaymentData)=> { return this.onPaymentDataChanged(intermediatePaymentData)}
				}
			});
		}
		return this.paymentsClient;
		
	}

	async sendAjax(url, data={}, method="POST"){
		data._token = ajax_urls.csrf;
		try{
			var resp = await $.ajax({
				method: method,
				url: url,
				data: data,
				dataType: "json",
			});
			return resp.data ? resp.data : resp;
		}catch(e){
			return false;
		}
	}

	async makeIntent(){
		var intent =  await this.sendAjax(ajax_urls.stripe.makeIntent, {});
		return intent.secret;
	}
	


	getGoogleIsReadyToPayRequest(){
		return Object.assign(
			{},
			this.baseRequest,
			{allowedPaymentMethods: [this.baseCardPaymentMethod]}
		);
	}

}
