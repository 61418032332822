<template>
  <div class="tool-item-panel with_popup text_panel" :class="sidebarPanelClass">
    <div class="panel-top without_hover">
      <div class="back">
        <h3>text</h3>
      </div>
    </div>
    <div class="panel-content wo_pad wo_pad_wo_overfl" v-bar>
      <div class="text_panel_scroll">
        <div class="panel_row">
          <a class="blue_btn full_w_btn open_popup_btn" v-on:click="addTextHandler">add text</a>
        </div>
        <div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
          <DropdownSelectFont
            textLabel="Font"
            :selectClass="selectDropdown"
            @toggleDropdown="openDropdown"
            @resetSelectClass="(selectDropdown = '')"
            @selectDropdown="selectDrop"
            :key="isUpdateTextParams"
            addedClass="fixedFontListHeight"
          />
        </div>
        <div class="panel_row spc_btwn" v-bind:class="{ disabled: isTextPanelDisabled }">
          <InputWidthHeight
            :inputValue="fontSize"
            @change="setFontSize($event, true)"
            @postBack="setFontSize($event)"
            @onStopDragInput="setFontSize($event, true)"
          />
          <ColorIput
            :defColor="fontColor"
            :changeColorValue="setFontColor"
            :key="isUpdateTextParams"
            @update="setFontColor($event, true)"
            :checkmarkDisabled="true"
          />
        </div>
        <div
          class="panel_row box_align p_left_right_0 bottom_divider"
          v-bind:class="{ disabled: isTextPanelDisabled }"
        >
          <a href="#" class="btn-action" v-on:click="setBoldText" v-tooltip.top="'Bold'">
            <span class="inch-icon inch-bold-icon"></span>
          </a>
          <a href="#" class="btn-action" v-on:click="setFontStyle" v-tooltip.top="'Italic'">
            <span class="inch-icon inch-italic-icon"></span>
          </a>
          <a href="#" class="btn-action" v-on:click="setUnderline" v-tooltip.top="'Underline'">
            <span class="inch-icon inch-underline-icon"></span>
          </a>
          <a href="#" class="btn-action" v-on:click="setTextAlign('left')" v-tooltip.top="'Align Left'">
            <span class="inch-icon inch-text-align-left-icon"></span>
          </a>
          <a
            href="#"
            class="btn-action"
            v-on:click="setTextAlign('center')"
            v-tooltip.top="'Align Center'"
          >
            <span class="inch-icon inch-text-align-center-icon"></span>
          </a>
          <a
            href="#"
            class="btn-action"
            v-on:click="setTextAlign('right')"
            v-tooltip.top="'Align Right'"
          >
            <span class="inch-icon inch-text-align-right-icon"></span>
          </a>
        </div>
        <div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
          <Range
            rangeSliderId="editOpacity"
            :changeValue="setSpacingLetter"
            @dragEnd="setSpacingLetter($event, true)"
            :rangeSliderValue="spacingLetter"
            rangeSliderLabel="Letter Spacing"
            rangeSliderClass="range-blue"
            :defaultValue="0"
            :minValue="0"
            :maxValue="100"
            :key="isUpdateTextParams"
          />
        </div>
        <div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="lineHeight"
            rangeSliderLabel="Line Height"
            :defaultValue="20"
            rangeSliderMeasure="%"
            rangeSliderClass="range-blue"
            :minValue="0"
            :changeValue="setLineHeight"
            @dragEnd="setLineHeight($event, true)"
            :maxValue="100"
            :key="isUpdateTextParams"
          />
        </div>
        <div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="configLayerProperties.opacity"
            rangeSliderLabel="Opacity"
            rangeSliderMeasure="%"
            rangeSliderClass="range-blue"
            :minValue="0"
            :defaultValue="100"
            :changeValue="setOpacity"
            @dragEnd="setOpacity($event, true)"
            :maxValue="100"
            :key="isUpdateTextParams"
          />
        </div>
        <div
          class="panel_row panel_row_border_top tog_w_pop_bg"
          v-bind:class="{ disabled: isTextPanelDisabled }"
        >
          <ToggleCheck
            toggleCheckLabel="Background"
            :thisToggleCheckChecked="isToggleBackground"
            :onChangeChecked="toggleBackgroundParams"
          >
            <template v-slot:popup_slot>
              <transition name="long-fade">
                <span
                  class="inch-icon inch-tune-icon togglecheck_popup_open"
                  v-tooltip.left="'Settings'"
                  v-on:click="toggleShowPopup('PopupTextBackgroundShow')"
                ></span>
              </transition>

              <PopupTextBackground
                :closePopups="closePopup"
                :updateParams="setBackgroundParams"
                @onUpdate="setBackgroundParams($event, true)"
                :backgroundTextParams="backgroundTextParams"
                :key="isUpdateTextParams"
                v-if="PopupTextBackgroundShow"
              />
              <div class="pop_up_closer" v-if="PopupTextBackgroundShow" v-on:click="closePopup"></div>
            </template>
          </ToggleCheck>
        </div>
        <div class="panel_row tog_w_pop_outline" v-bind:class="{ disabled: isTextPanelDisabled }">
          <ToggleCheck
            toggleCheckLabel="Outline"
            :thisToggleCheckChecked="isToggleOutline"
            :onChangeChecked="toggleOutlineParams"
          >
            <template v-slot:popup_slot>
              <transition name="long-fade">
                <span
                  class="inch-icon inch-tune-icon togglecheck_popup_open"
                  v-on:click="toggleShowPopup('PopupTextOutlineShow')"
                  v-tooltip.left="'Settings'"
                ></span>
              </transition>
              <PopupTextOutline
                :closePopups="closePopup"
                :changeTextOutlineParams="setOutlineParams"
                :textOutlineParams="textOutlineParams"
                :key="isUpdateTextParams"
                v-if="PopupTextOutlineShow"
                @changeParams="setOutlineParams($event, true)"
              />
              <div class="pop_up_closer" v-if="PopupTextOutlineShow" v-on:click="closePopup"></div>
            </template>
          </ToggleCheck>
        </div>
        <div
          class="panel_row panel_row_border_bottom tog_w_pop_shadow"
          v-bind:class="{ disabled: isTextPanelDisabled }"
        >
          <ToggleCheck
            toggleCheckLabel="Shadow"
            :onChangeChecked="toggleShadowParams"
            :thisToggleCheckChecked="isToggleShadow"
          >
            <template v-slot:popup_slot>
              <transition name="long-fade">
                <span
                  class="inch-icon inch-tune-icon togglecheck_popup_open"
                  v-on:click="toggleShowPopup('PopupTextShadowShow');"
                  v-tooltip.left="'Settings'"
                ></span>
              </transition>
              <PopupTextShadow
                :closePopups="closePopup"
                :shadowChanges="setShadowChanges"
                @changeShadow="setShadowChanges($event, true)"
                :shadow="shadowParams"
                :key="isUpdateTextParams"
                v-if="PopupTextShadowShow"
              />
              <div class="pop_up_closer" v-if="PopupTextShadowShow" v-on:click="closePopup"></div>
            </template>
          </ToggleCheck>
        </div>
        <div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
          <div class="actions-group double-btn popup_double_btns_wrpr">
            <a class="button btn-action-all btn-action duplicate" v-on:click="popupLayerPropShow">Layer Properties</a>
            <a class="btn-action-all btn-action trash" v-on:click="removeTextHandler" v-tooltip.top="'Delete'">
              <span class="inch-trash-icon"></span>
            </a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px"  />

      </div>
    </div>
    <PopupLayerProp v-if="popupLayerPropVisible && !isTextPanelDisabled" :popupLayerPropShow="popupLayerPropShow" />
    <div class="pop_up_closer" v-if="popupLayerPropVisible" v-on:click="popupLayerPropShow"></div>
  </div>
</template>

<script>
import ColorIput from "@/components/ColorInput";
import InputWidthHeight from "@/components/InputWidthHeight";
import Range from "@/components/Range.vue";
import ToggleCheck from "@/components/ToggleCheck";
import DropdownSelectFont from "@/components/DropdownSelectFont.vue";
import PopupTextBackground from "@/components/popupPanel/PopupTextBackground.vue";
import PopupTextOutline from "@/components/popupPanel/PopupTextOutline.vue";
import PopupTextShadow from "@/components/popupPanel/PopupTextShadow.vue";
import PopupLayerProp from "@/components/popupPanel/PopupLayerProp.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";
export default {
  name: "TextPanel",
  props: {
    sidebarPanelClass: String,
  },
  components: {
    ColorIput,
    InputWidthHeight,
    Range,
    ToggleCheck,
    DropdownSelectFont,
    PopupTextBackground,
    PopupTextOutline,
    PopupTextShadow,
    PopupLayerProp,
    AdLeftPanel,
  },
  data: function () {
    return {
      defaultFontSize: 34,
      fontSize: 34,
      spacingLetter: 0,
      lineHeight: 20,
      fontColor: "#000000",
      layerPopConfig: {
        blendMode: "None",
        color: null,
        intensity: 50,
      },
      selectDropdown: "",
      selectedFont: null,
      textPanelDisabled: true,
      PopupTextBackgroundShow: false,
      PopupTextOutlineShow: false,
      PopupTextShadowShow: false,
      popupLayerPropVisible: false,
      shadowParams: {
        color: "#000000",
        blur: 50,
        offsetY: 0,
        offsetX: 0,
        intensity: 100,
      },
      backgroundTextParams: {},
      textOutlineParams: {},
      configLayerProperties: {
        opacity: 100,
      },
      isUpdateTextParams: false,
      toggle: {
        outline: false,
        background: false,
        shadow: false,
      },
    };
  },
  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    textEvent() {
      return this.$store.state.textEvent;
    },

    isTextPanelDisabled() {
      return this.$store.state.global.isTextPanelDisabled;
    },

    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },

    textPanelUpdate() {
      return this.$store.state.textPanelUpdate;
    },

    getGlobalLayerParams() {
      return {
        ...this.$store.state.globalLayerPopUp.default,
        ...this.$store.state.globalLayerPopUp.params,
      };
    },

    isToggleOutline() {
      return this.toggle.outline;
    },

    isToggleBackground() {
      return this.toggle.background;
    },

    isToggleShadow() {
      return this.toggle.shadow;
    },

    getSelectedDropDown() {
      return this.selectDropdown;
    }
  },

  watch: {
    getSelectedDropDown(val) {
      $('.panel-content').children('.vb-dragger').css({ display: val ? 'none' : 'inline-block' });
    },
    
    getGlobalLayerParams(configLayerProperties) {
      this.setLayerProperties(configLayerProperties);
    },

    textPanelUpdate() {
      this.setDefaultParams();
    },

    textEvent(config) {
      this.setupTextActions(config);
    },
  },

  async created() {
    await this.loadFonts();
  },

  mounted() {
    const stageWidth = this.$lib.app.stage.width / this.$lib.app.stage.scale.x;
    const defFontSize = Math.ceil((stageWidth / 1024) * 34);
    this.defaultFontSize = defFontSize;
    this.fontSize = defFontSize;

    this.setLayerProperties({
      ...this.$store.state.globalLayerPopUp.default,
      ...this.$store.state.globalLayerPopUp.params,
    });
  },

  methods: {
    setupTextActions(config = {}) {
      if (config.action == "scaleY") {
        const currentText = this.$lib.fabric.getObjectByCacheKey(
          config.cacheKey
        );
        
        if (currentText) {
          const fontSize = parseInt(currentText.fontSize * currentText.scaleY);

          this.fontSize = fontSize;
        }
      }
    },

    async loadFonts() {
      await this.$store.dispatch("fontsList", {});
      this.selectedFont = this.$store.state.fonts[0];
      this.setDefaultParams();
      this.$emit("loadFontList");
    },

    setLayerProperties(configLayerProperties = {}) {
      for (const key in configLayerProperties) {
        if (this.configLayerProperties[key] != configLayerProperties[key]) {
          this.configLayerProperties = configLayerProperties;

          if (key == "opacity") {
            this.isUpdateTextParams = !this.isUpdateTextParams;
          }
          break;
        }
      }
    },

    setDefaultParams() {
      const params = this.$lib.Text.getConfigActiveText() || {};

      this.shadowParams = params.shadowParams || {
        color: "#000000",
        blur: 50,
        offsetY: 0,
        offsetX: 0,
        intensity: 100,
      };
      this.toggle.shadow = !!params.shadowParams;

      this.backgroundTextParams = params.backgroundTextParams || {
        color: "#FFFFFF",
        type: "line",
        padding: 0,
      };
      this.toggle.background = !!params.backgroundTextParams;

      this.textOutlineParams = params.textOutlineParams || {
        color: "#000000",
        width: 20,
      };

      this.toggle.outline = !!params.textOutlineParams;

      this.fontSize =
        parseInt(params.fontSize * params.scale) || this.defaultFontSize;
      this.fontColor = params.fontColor || "#000000";

      this.selectedFont =
      this.$store.state.fonts.find((f) => {
        return f?.title == params.font
      }) ||
      this.$store.state.fonts[0];
      if (this.selectedFont) {
        this.$store.state.currentFont = this.selectedFont.title.replace(/[0-9]/g, '').trim();
      }

      this.spacingLetter = params.spacingLetter || 0;
      this.lineHeight =
        params.lineHeight && params.lineHeight != 0 ? params.lineHeight : 20;

      this.layerPopConfig = params.layerPopConfig || {
        blendMode: "None",
        color: null,
        intensity: 50,
      };

      this.isUpdateTextParams = !this.isUpdateTextParams;
    },

    toggleCheckHandle(toggle) {
      console.log(toggle);
    },

    toggleBackgroundParams(isChecked) {
      this.toggle.background = isChecked;
      if (!isChecked) {
        this.PopupTextBackgroundShow = false;

        this.setBackgroundParams(
          {
            color: "transparent",
            isFull: true,
          },
          true
        );
      } else {
        this.PopupTextBackgroundShow = true;

        this.setBackgroundParams(this.backgroundTextParams, true);
      }
    },

    toggleOutlineParams(isChecked) {
      this.toggle.outline = isChecked;
      if (!isChecked) {
        this.setOutlineParams({ width: 0 }, true);
      } else {
        this.setOutlineParams(this.textOutlineParams, true);
        this.toggleShowPopup("PopupTextOutlineShow");
      }
    },

    toggleShadowParams(isChecked) {
      this.toggle.shadow = isChecked;
      if (!isChecked) {
        this.setShadowChanges({}, true);
      } else {
        this.setShadowChanges(this.shadowParams, true);
        this.toggleShowPopup("PopupTextShadowShow");
      }
    },

    openDropdown() {
      this.selectDropdown = "active";
    },

    async selectDrop(font) {
      if (typeof font !== "undefined") {
        if (font.isPremium) {
          const isHavePremium = await this.$store.dispatch("isPremiumCurrentUser");

          if (!isHavePremium) {
            // if (window.abVariant && window.abVariant == 2) {
						this.$store.dispatch("popupsToggle", {
							property: "stripeCheckoutPopup",
							value: true,
						});
					// }
					// else{
					// 	this.$store.dispatch("popupsToggle", {
					// 		property: "upgradePopup",
					// 		value: true,
					// 	});
					// }
            return;
          }
        }

        this.$lib.Text.setFontFamily(
          font.title.replace(/[0-9]/g, "").trim(),
          true
        );
        this.$store.dispatch("currentFont", font.title.replace(/[0-9]/g, "").trim());
      }

      this.$store.dispatch("isUpdateCache");
      this.selectedFont = font;
      this.selectDropdown = "";
    },

    setFontSize(obj, toCache) {
      obj.value = Number(obj.value);
      if (obj.value && obj.value >= 6) {
        this.fontSize = obj.value;
        this.$lib.Text.setFontSize(obj.value / this.defaultFontSize, toCache);

        if (toCache) {
          this.$store.dispatch("isUpdateCache");
        }
      }
    },

    setFontColor(color, isCache) {
      this.$lib.Text.setFillText(color.hex, !!isCache);
    },

    setBoldText() {
      const fontWeight = this.$lib.Text.getTextParam('fontWeight');
      this.$lib.Text.setFontWeight(
        fontWeight && fontWeight == 'bold' ? "normal" : "bold",
        true
      );

      this.$store.dispatch("isUpdateCache");
    },

    setFontStyle() {
      const fontStyle = this.$lib.Text.getTextParam('fontStyle');
      this.$lib.Text.setFontStyle(
        fontStyle && fontStyle == 'italic' ? "" : "italic",
        true
      );

      this.$store.dispatch("isUpdateCache");
    },

    setUnderline() {
      const underline = this.$lib.Text.getTextParam('underline');
      this.$lib.Text.setUnderline(!underline, true);

      this.$store.dispatch("isUpdateCache");
    },

    setTextAlign(textAlign) {
      this.$lib.Text.setTextAlign(textAlign, true);

      this.$store.dispatch("isUpdateCache");
    },

    setSpacingLetter(value, toCache) {
      value = Number(value);
      if ((!value && value != 0) || value < 0) return;

      this.$lib.Text.setCharSpacing(value * 10, toCache);

      this.spacingLetter = value;
      if (toCache) {
        this.$store.dispatch("isUpdateCache");
      }
    },

    setLineHeight(value, toCache) {
      value = Number(value);
      if ((!value && value !== 0) || value < 0) return;
      this.$lib.Text.setLineHeight(this.$lib.lib.conv(value, 0.1, 5), toCache);

      this.lineHeight = value;
      if (toCache) {
        this.$store.dispatch("isUpdateCache");
      }
    },

    setOpacity(value, toCache) {
      value = Number(value);
      if ((!value && value != 0) || value < 0) return;
      this.configLayerProperties.opacity = value;
      this.$store.dispatch("globalLayerPopUpParams", {
        ...this.$store.state.globalLayerPopUp.params,
        ...this.configLayerProperties,
      });

      this.$lib.Text.setOpacity(value / 100, toCache);

      if (toCache) {
        this.$store.dispatch("isUpdateCache");
      }
    },

    setOutlineParams(params, toCache) {
      this.$lib.Text.setOutlineParams(params, toCache);

      if (toCache) {
        this.$store.dispatch("isUpdateCache");
      }
    },

    setBackgroundColor(color) {
      this.$lib.Text.setBackgroundColor(color.hex);
    },

    setTextPadding(padding) {
      this.$lib.Text.setPaddingText(padding);
    },

    addTextHandler() {
      this.$lib.Text.addText("Double-click to edit text ", {
        fontSize: this.fontSize,
        fill: this.fontColor,
        fontFamily:
          this.selectedFont && this.selectedFont.value
            ? this.selectedFont.value
            : this.$store.state.fonts[0],
        charSpacing: this.spacingLetter * 10,
        lineHeight: this.$lib.lib.conv(this.lineHeight, 0.1, 5),
      });
      this.$lib.Text.setFontFamily(this.$store.state.currentFont, true);
      this.$store.state.global.isTextPanelDisabled = false;
      // this.$store.dispatch('textElementStateToggle', true);
    },

    setBackgroundParams(params, toCache) {
      //this.PopupTextBackgroundShow = !this.PopupTextBackgroundShow;
      this.$lib.Text.setBackground(params, toCache);

      if (toCache) {
        this.$store.dispatch("isUpdateCache");
      }
    },

    setShadowChanges(params, toCache) {
      this.$lib.Text.setShadow(params, toCache);

      if (toCache) {
        this.$store.dispatch("isUpdateCache");
      }
    },

    removeTextHandler() {
      this.$lib.Text.removeSelectedText();
      this.$store.state.global.isTextPanelDisabled = true;
      this.$store.dispatch("textElementStateToggle", false);
      this.closePopup();
    },

    toggleShowPopup(name) {
      (this.PopupTextBackgroundShow = false),
        (this.PopupTextOutlineShow = false),
        (this.PopupTextShadowShow = false);
      return (this[name] = !this[name]);
    },

    closePopup() {
      (this.PopupTextBackgroundShow = false),
        (this.PopupTextOutlineShow = false),
        (this.PopupTextShadowShow = false),
        (this.popupLayerPropVisible = false);
    },

    popupLayerPropShow() {
      return (this.popupLayerPropVisible = !this.popupLayerPropVisible);
    },

    /* setTextConfig(config) {
                      for (const key in layerPropConfig) {
                        if (this.layerPropConfig[key] != layerPropConfig[key]) {
                          this.layerPropConfig = layerPropConfig;

                          this.dispatchUpdateTrigger = !this.dispatchUpdateTrigger;
                          break;
                        }
                      }
                      shadowParams: { color: "#000000", blur: 50, offsetY: 0, offsetX: 0 },
                                backgroundTextParams: {},
                                textOutlineParams: {},
                                layerPopConfig: {
                                    intensity: 50
                                },
                                fontSize: 34
                    } */
  },
};
</script>
