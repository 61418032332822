<template>
  <div :class="[{'tool-item-panel': !isMobileView}, sidebarPanelClass]">
    <div v-if="!isMobileView" class="panel-top without_hover">
      <div class="back">
        <h3>frames</h3>
      </div>
    </div>
    <div :class="{'panel-content': !isMobileView, 'wo_pad': !isMobileView, 'wo_pad_wo_overfl': !isMobileView}">
      <div v-if="!isMobileView" v-bar>
        <div class="panel_row effects_items_wrpr effects_categories_wrpr">
          <Favorites
            v-if="favoritesList.length"
            :favoritesCount="favoritesList.length"
            :favoritesOpen="favoritesOpen"
          />
          <a class="category_card" @click="toolLinkClick('Custom Border')">
            <div class="img_wrpr">
              <img :src="girlImg" alt />
              <FrameCustomBorderIcon />
            </div>
            <div class="text_wrpr">
              <span>Custom Border</span>
            </div>
          </a>
          <template v-for="cardItem in panels">
            <CategoryCard
              v-bind:key="cardItem.id"
              :categoryId="cardItem.id"
              :toolLinkClick="toolLinkClick"
              :msg="cardItem.title"
              :imgPath="cardItem.url"
            />
          </template>
          <AdLeftPanel v-if="isLeftAd" margin="20px 0px" marginLeft="0" />
        </div>
      </div>
      <transition name="panel-slide">
        <div v-if="isMobileView && showPanel" class="panel_row_mobile">
          <template v-for="cardItem in panels">
            <CategoryCard
                :isMobileView="isMobileView"
                v-bind:key="cardItem.id"
                :categoryId="cardItem.id"
                :toolLinkClick="toolLinkClick"
                :msg="cardItem.title"
                :imgPath="cardItem.url_m || cardItem.url"
            />
          </template>
        </div>
      </transition>
    </div>

    <transition name="panel-slide">
      <template v-for="panel in panels">
        <ThumbsPanel
          :appliedPresetId="appliedPresetId"
          v-bind:key="panel.id"
          v-if="panelOpened === panel.title"
          :pannelInfo="pannelInfo"
          :panelName="panel.title"
          :panelItems="panel.panelItems"
          :panelPropChange="panelPropChange"
          @panelView="panelView"
          :popUpPropChange="popUpPropChange"
          :panelClass="panelClass"
          :panelLoading="panelLoading"
          :favoritesPropChange="favoritesPropChange"
          :panelId="panel.id"
          :popUpVisible="popUpVisible"
          :filterPreview="framePreview"
          :applyFilter="applyFrame"
          :sliderUpdate="changeAlpha"
          :sliderVal="uniformFrame.frame.alpha"
          :sliderMin="0"
          :sliderMax="100"
          :isFrame="true"
          @loadSecondPageIfBigScreen="loadSecondPageIfBigScreen"
          @contentUpload="contentUpload"
          :isLoading="pageControl.isLoading"
        >
          <template v-slot:popup>
            <PopupFrames
              :frameParams="uniformFrame"
              :updateFrameParams="updateFrameParams"
              :popUpVisible="popUpVisible"
              :popUpLabel="popUpLabel"
              :popUpPropChange="popUpPropChange"
              @applyFrame="applyFrame"
              :selectedFrameData="selectedFrameData"
            />
          </template>
          <!-- <template v-slot:with-mask>
            <div class="effect_category_item_mask">
              <FrameCelebratory />
            </div>
          </template>-->
        </ThumbsPanel>
      </template>
      <FramesCustomBorderPanel
        v-if="panelOpened === 'Custom Border'"
        :panelPropChange="panelPropChange"
        :panelClass="panelClass"
      />
    </transition>

    <transition name="panel-slide">
      <ThumbsPanel
        :appliedPresetId="appliedPresetId"
        v-if="panelOpened === 'Favorites'"
        panelName="Favorites"
        :pannelInfo="pannelInfo"
        :panelItems="favoritesList"
        :panelPropChange="panelPropChange"
        :popUpPropChange="popUpPropChange"
        :panelClass="panelClass"
        :panelLoading="panelLoading"
        :favoritesPropChange="favoritesPropChange"
        :filterPreview="framePreview"
        :applyFilter="applyFrame"
        :sliderUpdate="changeAlpha"
        :sliderVal="uniformFrame.frame.alpha"
        :sliderMin="0"
        :sliderMax="100"
        :isFrame="true"
      >
        <template v-slot:popup>
          <PopupFrames
            :frameParams="uniformFrame"
            :updateFrameParams="updateFrameParams"
            :popUpVisible="popUpVisible"
            :popUpLabel="popUpLabel"
            :popUpPropChange="popUpPropChange"
            @applyFrame="applyFrame"
            :selectedFrameData="selectedFrameData"
          />
        </template>
      </ThumbsPanel>
    </transition>
  </div>
</template>

<script>
import CategoryCard from "@/components/CategoryCard.vue";
import ThumbsPanel from "@/components/ThumbsPanel.vue";
import FramesCustomBorderPanel from "@/components/sidebarPanel/framesPanel/FramesCustomBorderPanel.vue";
import FrameCustomBorderIcon from "@/assets/img/frame_custom_border.svg?inline";
import AdLeftPanel from "@/components/AdLeftPanel.vue";
// import FrameCelebratory from "@/assets/img/frame_celebratory.svg?inline";
import Favorites from "@/components/Favorites.vue";
import PopupFrames from "@/components/popupPanel/PopupFrames.vue";
const analitics = require("../../../libs/analytics");
import LoaderMixin from '@/mixin/LoaderMixin';
import { EventBus } from '@/main.js';

export default {
  name: "FramesPanel",
  props: {
    sidebarPanelClass: String,
  },
  mixins: [LoaderMixin],
  components: {
    CategoryCard,
    FrameCustomBorderIcon,
    FramesCustomBorderPanel,
    // FrameCelebratory,
    Favorites,
    ThumbsPanel,
    PopupFrames,
    AdLeftPanel,
  },
  data: function() {
    const uniformFrameDef = {
      frame: {
        color: "#FFFFFF",
        alpha: 100
      },
      spr: {
        frame: 0,
      },
    };

    return {
      isMobileView: false,
      showPanel: true,
      appliedPresetId: false,
      pannelInfo: {
        pannelTitle: "",
        pannelList: [
          {
            icon: "slider_icon",
            text: "Adjust the frame opacity",
          },
          { icon: "icon_heart", text: "Add selected frame to Favorites" },
          {
            icon: "icon_tune",
            text:
              "Access advanced settings to adjust the look and feel of the frame",
          },
        ],
        pannelFooter: `<span class="inch-icon inch-plus-membership-icon"></span>Access advanced settings to adjust the look and feel of the frame <a href='#get_plus'>Get Colorcinch Plus</a>`,
      },

      panelNameFavorite: "frames",
      favoritesCount: 0,
      favoritesList: [],
      favoritesPanelVisible: false,
      popUpVisible: false,
      popUpLabel: "",
      panelLoading: true,
      panelOpened: "",
      panelClass: "",
      uniformFrameDef,
      uniformFrame: JSON.parse(JSON.stringify(uniformFrameDef)),
      girlImg: require("@/assets/img/girl_img.png"),
      panels: [],
      selectedFrameData: {},
      pageControl: {
        currentPage: 1,
        totalPages: 1,
        isLoading: false,
      },
      selectedPanelId: null,
    };
  },

  beforeDestroy() {
    this.$lib.app.addTransparentPNGMask();
    this.discardChanges();
    this.$lib.watermark.remove();
  },

  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyFrame();
        if (this.$store.state.saveOnMobile) {
          EventBus.$emit("save-on-mobile");
        }
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
  },

  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },
  },

  async mounted() {
      this.isMobileView = this.$store.state.isMobileView;
    this.favoritesList = this.$store.state.favorites.frames;
    /* this.favoritesList = await fetch("/api/frames/favorites").then(res =>
      res.json()
    ); */
    this.favoritesList = this.favoritesList.map((fav) => {
      fav.favoritesIconIsChecked = true;
      return fav;
    });

    this.panels = await fetch(
      `${this.$store.state.prefixURL}/api/frames`
    ).then((res) => res.json());

    if (window.location !== window.parent.location) {
      this.panels = this.panels.map(item => ({
        ...item,
        url: item.url.replace('edit.cartoonize.net', 'cdn.cartoonize.net'),
      }))
    }

  },

  methods: {
    async loadSecondPageIfBigScreen(){
      if(!this.pageControl.isLoading && this.pageControl.currentPage < this.pageControl.totalPages){
        this.contentUpload(false, true);

      }
    },

    async contentUpload(event,skip_scroll_check=false) {
      let scrolled = false;
      if(event){
        if (this.isMobileView) {
          scrolled = (event.target.scrollLeft*window.devicePixelRatio) >=
              ((event.target.scrollWidth - event.target.clientWidth));
        } else {
          scrolled = (event.target.scrollTop*window.devicePixelRatio) >=
              event.target.scrollHeight - event.target.clientHeight;
        }
      }

      if (
        (scrolled &&
        !this.pageControl.isLoading &&
        this.pageControl.currentPage < this.pageControl.totalPages) || skip_scroll_check
      ) {
        const panel = this.panels.find((p) => p.id == this.selectedPanelId);
        if (!panel) return;

        this.pageControl.isLoading = true;

        const items = await this.getFramesByParams(
          this.pageControl.currentPage + 1
        );
        await this.checkAfterLoad([...panel.panelItems, ...items]);

        this.pageControl.isLoading = false;
      }
    },

    async favoritesPropChange(itemId, panelId) {
      const { favoritesList, panels } = await this.$store.dispatch(
        "favoritesPropChange",
        {
          panelOpened: this.panelOpened,
          panels: this.panels,
          favoritesList: this.favoritesList,
          panelName: this.panelNameFavorite,
          itemId,
          panelId,
        }
      );

      this.favoritesList = favoritesList;
      this.panels = panels;
    },

    async popUpPropChange(label) {
      if (
        this.popUpVisible !== false &&
        this.popUpLabel !== label &&
        label !== ""
      ) {
        return (this.popUpLabel = label);
      } else {
        return (
          (this.popUpVisible = !this.popUpVisible), (this.popUpLabel = label)
        );
      }
    },

    async discardChanges() {
      try {
        //this.$lib.app.stage.filters = [];
        if (this.$store.state.premiumStatus) {
          this.$store.commit("changePremiumStatus", false);
        }

        this.$lib.watermark.remove(true);
        // await this.$lib._cache.renderSaveByTimestamp();
        this.$store.dispatch("isUpdateCache");

        this.$lib.Frames.discardChanges();
        this.$store.dispatch("fabricSlide", false);
      } catch (err) {
        console.log(err);
      }
    },

    async framePreview(data) {
      if (this.appliedPresetId == data.id) {
        this.appliedPresetId = null;
        await this.discardChanges();
        return;
      }

      let preset = this.getPresetBycategoryId(data.id);

      // if(preset.isPremium) {
      //   this.$lib.watermark.render();
      // }

      if (!/\.svg$/g.test(preset.url)) {
        this.$store.dispatch("preloader", {
          message: "Loading...",
          isShow: true,
        });
      }

      await this.discardChanges();
      //this.$lib.app.stage.filters = [new this.$lib.PIXI.filters.FXAAFilter()];
      this.$store.dispatch("fabricSlide", true);
      await this.$store.dispatch("checkPremiumStatus", {
        panels: this.panels,
        favorites: this.favoritesList,
        presetId: data.id,
      });

      this.selectedFrameData = data;
      this.uniformFrame = JSON.parse(JSON.stringify(this.uniformFrameDef));
      this.selectedFrameData = JSON.parse(JSON.stringify(data));

      this.uniformFrame.frame.color =
        data.params && data.params.color
          ? data.params.color
          : this.uniformFrame.frame.color;

      this.uniformFrame.frame.angle = this.$lib.app._canvasSize.w < this.$lib.app._canvasSize.h ? 90 : 0;
      // this.uniformFrame = {};
      await this.setupFrame();

      this.$store.dispatch("preloader", {
        message: "Loading...",
        isShow: false,
      });

      this.appliedPresetId = data.id;
    },

    async applyFrame(params, slider=false) {
      if (!this.appliedPresetId) return;
      if (!this.selectedFrameData) return;

      await this._preloader(true);
      const res = await this.setupFrame(this.uniformFrame, { isApply: true });
      if (!res) {
        await this._preloader(false);
        return;
      }

      if(slider) {
        let height =this.$lib.app.fabricCanvas.height;
        let nowHeight, zoom;

        nowHeight = height - 31;
        zoom = nowHeight/this.$lib.app.stage.height;
        var centeredContent = document.querySelector(".centered-content");
        var dragWrapper = document.querySelector(".drag-wrapper");
        centeredContent.style.transform = 'scale(' + zoom + ')';
        dragWrapper.style.height = 'calc(100vh - 280px)';
        this.$lib.app.setFabricZoom(zoom);
      }
      this.$store.dispatch("isUpdateCache");
      this.$store.dispatch("fabricSlide", false);
      await this._preloader(false);

      this.panelOpened = "";
      this.panelClass = "";
      this.popUpVisible = false;
      this.showPanel = true;
    },

    panelView(val) {
      this.showPanel = val;
    },

    async changeAlpha(alpha) {
      this.uniformFrame.frame.alpha = alpha;

      await this.setupFrame(this.uniformFrame);
    },

    async positionUpdate(value) {
      this.uniformFrame.spr.frame = value;

      await this.setupFrame(this.uniformFrame);
    },

    async updateFrameParams(uniforms) {
      this.uniformFrame = uniforms;

      if (!this.selectedFrameData) {
        return;
      }

      await this.setupFrame(this.uniformFrame);
    },

    getPresetBycategoryId(catId) {
      return this.panels
        .reduce((arr, panel) => {
          if (panel.panelItems) {
            arr = [...arr, ...panel.panelItems];
          }

          return arr;
        }, [])
        .find((p) => p.id == catId);
    },

    async setupFrame(options = this.uniformFrame, params = { isApply: false }) {
      if (!this.selectedFrameData.id && this.selectedFrameData.id !== 0) return;
      this.uniformFrame = options;

      if (!params.updateId) {
        params.updateId = this.$lib.CONST.MAP_CHANGES.BG_ADD_FRAME;
      }

      let preset = this.getPresetBycategoryId(this.selectedFrameData.id);

      if (!preset) {
        preset = this.favoritesList.find(
          (f) => f.id == this.selectedFrameData.id
        );

        if (!preset) return;
      }

      if (preset.isPremium && params.isApply) {
        const isHavePremium = await this.$store.dispatch(
          "isPremiumCurrentUser"
        );
        if (!isHavePremium) {
          this.$store.dispatch("popupsToggle", {
            property: "upgradePopup",
            value: true,
          });

          return;
        }
      }

      const STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(
        preset.url,
        { isServer: true }
      );

      this.$lib.watermark.renderPremium(
        preset.isPremium && !this.$store.state.isPremiumAccount,
        undefined,
        [false, true]
      );
      await this.$lib.Frames.setup(
        {
          originalSprite: this.$lib.app.stage.children[0],
          STexture,
          frame: this.uniformFrame.spr.frame,
        },
        {
          frame: this.uniformFrame.frame,
        },
        { updateId: params.updateId, isApply: params.isApply }
      );
      this.$lib.app.setFabricZoom();

      if (params.isApply) {
        this.$store.dispatch("changesApplied");
      }

      return true;

      // delete this.uniformFrame.frame.angle;
      // delete this.uniformFrame.frame.flip;
    },

    async getFramesByParams(page = 1) {
      this.pageControl.currentPage = page;
      const frams =
        (await fetch(
          `${this.$store.state.prefixURL}/api/frames/${this.selectedPanelId}?page=${page}`
        ).then((res) => res.json())) || [];
      this.pageControl.totalPages = frams.total_pages;

//      setTimeout(() => {
//        frams.data.forEach((frame) => fetch(frame.url));
//      }, 1000);

      if (window.location !== window.parent.location) {
        frams.data = frams.data.map(item => ({
          ...item,
          url: item.url.replace('edit.cartoonize.net', 'cdn.cartoonize.net'),
        }))
      }

      return frams.data;
    },

    async toolLinkClick(panelName, panelId) {
      this.selectedPanelId = panelId;
      this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
      const panelIndex = this.panels.findIndex((panel) => panel.id === panelId);
      if (panelIndex == -1 && panelName !== "Custom Border") return;

      this.panelLoading = true;
      if (this.panelOpened === panelName) {
        this.panelOpened = "";
        this.panelClass = "";
        this.isLoading = true;
      } else {
        analitics.sendEvent(panelName, analitics.categ.frame);

        this.panelOpened = panelName;
        this.panelClass = "active";

        if (panelName == "Custom Border") {
          this.panelLoading = false;
          return;
        }

        let items = await this.getFramesByParams();

        items = items.map((item) => {
          if (this.favoritesList.find((fi) => fi.id == item.id)) {
            item.favoritesIconIsChecked = true;
          }

          return item;
        });

        this.checkAfterLoad(items);
        // Это закоментить если надо всё таки обновлять превью
      }
      this.showPanel = false;
    },

    async checkAfterLoad(items = []) {
      const panelIndex = this.panels.findIndex(
        (panel) => panel.id === this.selectedPanelId
      );
      if (panelIndex == -1) return;

      // let lockLoadPreview = false;
      // if (
      //   this.panels[panelIndex].panelItems &&
      //   this.panels[panelIndex].panelItems.length
      // ) {
      //   items = items.map(it => {
      //     const oldGeneration = this.panels[panelIndex].panelItems.find(
      //       ite => ite.id == it.id
      //     );
      //     if (oldGeneration) {
      //       it.imgPath = oldGeneration.imgPath;
      //     }

      //     return it;
      //   });
      //   this.panels[panelIndex].panelItems = items;
      //   lockLoadPreview = true;
      // }

      this.panels[panelIndex].panelItems = items;

      // if (lockLoadPreview) {
      //   this.panelLoading = false;
      //   return;
      // }

      await new Promise((resolve) => {
        this.$lib.TexturesHelper.loadingTextures(
          undefined,
          { paths: items.map((i) => i.thumb), isServer: true },
          async (err, res) => {
            if (err) {
              return this.toolLinkClick(panelName, this.selectedPanelId);
            }

            await this.generatePreviewFrames(res, this.selectedPanelId, items);
            this.panelLoading = false;
          }
        );
      });
    },

    async generatePreviewFrames(res, panelId, items = []) {
      const panelIndex = this.panels.findIndex((panel) => panel.id === panelId);
      const cachedResource = await this.getStageTexture();

      for (let i = 0; i < items.length; i++) {
        if (!items[i].genImage) {
          const item = items[i];

          const STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(
            items[i].thumb,
            { isServer: true }
          );

          const defaultFrameConfig = JSON.parse(
            JSON.stringify(this.uniformFrameDef)
          );
          const prevImage = await this.$lib.Frames.previewByParams(
            cachedResource.texture,
            STexture,
            defaultFrameConfig.frame,
            {
              frame: {
                ...defaultFrameConfig.frame /*
              color:
                item && item.params && item.params.color
                  ? item.params.color
                  : defaultFrameConfig.frame.color */,
              },
            }
          );

          if (prevImage) {
            items[i].imgPath = prevImage;
            items[i].genImage = true;
          }
        }
      }

      this.panels[panelIndex].panelItems = items;
      this.panels = [...this.panels];

      this.pageControl.isLoading = false;
    },

    async panelPropChange() {
      this.appliedPresetId = false;
      await this.discardChanges();

      this.panelOpened = "";
      this.panelClass = "";
      this.panelLoading = true;
      this.popUpVisible = false;
    },

    async getStageTexture() {
      const stageBase64 = await this.$lib._cache.getLastThumbBase64(); //await this.$lib.app.extractStage();
      const cachedResource = await this.$lib.app.addToLoader(
        `${Date.now()}-thumb`,
        stageBase64
      );

      return cachedResource;
    },

    async favoritesOpen() {
      this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
      this.panelOpened = "Favorites";
      this.panelClass = "active";
      this.panelLoading = true;

      this.$lib.TexturesHelper.loadingTextures(
        undefined,
        { paths: this.favoritesList.map((i) => i.thumb), isServer: true },
        async (err, res) => {
          if (err) {
            return this.toolLinkClick(this.panelOpened);
          }

          const cachedResource = await this.getStageTexture();
          for (let i = 0; i < this.favoritesList.length; i++) {
            const STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(
              this.favoritesList[i].thumb
            );

            /* this.favoritesList[i].params = {
              ...this.uniformsOverlay,
              ...(this.favoritesList[i].params
                ? this.favoritesList[i].params
                : {})
            }; */

            const prevImage = await this.$lib.Frames.previewByParams(
              cachedResource.texture,
              STexture,
              this.uniformFrame.spr.frame,
              { frame: this.uniformFrame.frame }
            );

            if (prevImage) {
              this.favoritesList[i].imgPath = prevImage;
            }
          }

          this.panelLoading = false;
        }
      );

      /* this.panelOpened = "Favorites";
      console.log(this.favoritesList); */
    },
  },
};
</script>

<style>
.effect_category_item > img {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}
.hidden {
  display: none;
}
</style>
