<template>
	<div class="popup graphics_popup popup_unsplash" :style="{ 'max-width': '1468px' }">
		<div class="popup_header">
			<h3>STOCK IMAGE SEARCH</h3>
			<a
				class="close_popup_btn"
				v-on:click="changePopup('popupUnsplash', false)"
			>
				<span class="inch-icon inch-close-icon"></span>
			</a>
		</div>
		<!-- <div class="hr_line"></div> -->

		<div class="popup_body" v-if="isOffline">
			<div class="is_offline" v-if="isOffline">
				<Offline></Offline>
			</div>
		</div>
		
		
		<div class="popup_body" v-if="isOnline">
			<div class="head-panel">
				<div class="panel_row">
					<SearchInputUnsplash
						:searchSendRequest="searchByKeywords"
						SearchInputPlaceholder="Search over 2.5 million high-resolution photos"
						:searchValue="searchKeywords"
						@searchInput="inputSearchKeywords"
					/>
				</div>
				<div class="categories" :key="categories.options.length">
					<DropdownSelect
						textLabel=""
						:current="categories.current"
						:selectClass="categories.selectDropdown"
						@resetSelectClass="categories.selectDropdown = ''"
						:selectOptions="categories.options"
						@toggleDropdown="openDropdown('categories')"
						@selectDropdown="selectDrop('categories', $event)"
					/>
				</div>

				<div class="unsplash">
					Photos courtesy of
					<a href="https://unsplash.com/" target="_blank">Unsplash</a>
				</div>
			</div>
			<!-- <h3 class="graphics_popup_right_title" v-if="searchKeywords">
					Search results for "
					<b>{{ searchKeywords }}</b>"

					.filter(graphicItem => !graphicItem.hidden)
				</h3>-->

			<!-- <div class="hr_line"></div> -->
			<div
				v-bar
				:style="{ height: 'calc(100% - 90px);', 'padding-bottom': '38px', 'padding-top': '20px' }"
			>
				<div
					class="graphics_popup_right_items_wrap"
					@scroll="contentUpload($event)"
				>
					<stack
						:column-min-width="280"
						:gutter-width="8"
						:gutter-height="4"
						monitor-images-loaded
					>
						<stack-item v-for="photo in getLocalPhotos" :key="photo.id">
							<div
								class="stack_block_images"
								@click="selectItem($event, photo)"
							>
								<div class="credition-image">
									<span>{{ photo.user.name }} | Unsplash</span>
								</div>

								<img
									:src="photo.urls.thumb"
									:alt="photo.alt_description"
									:class="{ active: photo.isActive }"
								/>
							</div>
						</stack-item>
					</stack>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SearchInputUnsplash from "@/components/SearchInputUnsplash.vue";
import UnsplashAPI from "../../libs/unsplash";
import DropdownSelect from "@/components/DropdownSelect.vue";
import { Stack, StackItem } from "vue-stack-grid";
import Offline from "@/components/Offline.vue";

export default {
	name: "PopupUnsplash",
	components: {
		SearchInputUnsplash,
		DropdownSelect,
		Stack,
		StackItem,
		Offline,
	},
	props: {},
	data: function () {
		return {
			config: {
				per_page: 20,
				order_by: "popular",
			},
			photos: [],
			categories: {
				collections: [],
				options: [],
				current: "",
				selectDropdown: "",
				getCurrent() {
					return this.collections.find((col) => col.title == this.current);
				},
			},
			lockLoadingPages: false,
			searchKeywords: "",
			pageControl: {
				currentPage: 1,
				isLoading: false,
			},
			userFiles: [],
			localFiles: [],
		};
	},
	async mounted() {
		this.localFiles = (await this.$store.dispatch("getUserLibrary")).files;
		await this.loadCollections();
	},

	beforeDestroy() {},

	methods: {
		getImageStyles(photo) {
			const scale = 280 / photo.width;

			return { width: `${photo.width * scale}px`, height: `${photo.height * scale}` };
		},

		openDropdown(key) {
			this[key].selectDropdown =
				this[key].selectDropdown == "active" ? "" : "active";
		},

		selectDrop(key, value) {
			this[key].selectDropdown = "";
			this[key].current = value;

			if (value == "categories") {
				this.categories.current = value;
			}
		},

		async contentUpload(event) {
			if (this.lockLoadingPages) return;
			const element = $(".graphics_popup_right_items_wrap")[0];
			const scrolled =
				element.scrollTop >= element.scrollHeight - element.clientHeight - 400;

			if (this.querySearch) return;
			if (scrolled && !this.pageControl.isLoading) {
				this.pageControl.currentPage += 1;

				await this.loadingPhotos();
			}
		},
		inputSearchKeywords(event) {
			this.searchKeywords = event.target.value;
		},

		async searchByKeywords() {
			this.lockLoadingPages = false;
			this.pageControl.currentPage = 1;
			await this.loadingPhotos(undefined, { isClear: true });
		},

		changePopup(property, value) {
			this.$store.dispatch("popupsToggle", { property, value });
		},

		setActivePhotoById(id, isActive) {
			this.photos = this.photos.map((ph) => {
				if (ph.id == id) {
					ph.isActive = isActive;
				}

				return ph;
			});
		},

		async selectItem(event, photo) {
			const target = $(event.target);
			let localPhoto = this.photos.find((ph) => ph.id == photo.id);

			if (!localPhoto) return;
			const storeImage = this.localFiles.find((si) => si.unsplash == photo.id);

			const loading = $(`<div class="hover-loading">
				<div class="lds-ring">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
				</div>
				</div>`);
			target.parent().prepend(loading);
			target.css({ "pointer-events": "none" });

			if (storeImage) {
				await this.$store.dispatch("deleteUserImage", { id: storeImage.id });
				this.setActivePhotoById(localPhoto.id, false);
				this.localFiles = this.localFiles.filter(
					(lf) => lf.unsplash != localPhoto.id
				);
				this.$store.state.userUploadFiles = this.$store.state.userUploadFiles.filter(
					(lf) => lf.unsplash != localPhoto.id
				);
			} else {
				try {
					UnsplashAPI.statisticFetch(photo.links.download_location);

					const blob = await fetch(photo.urls.regular).then((res) =>
						res.blob()
					);

					const file = new File([blob], `${photo.id}.jpeg`);

					// console.log(photo.urls.thumb);
					// const thumbBlob = await fetch(photo.urls.thumb).then((res) =>
					//	 res.blob()
					// );
					// const thumb = new File([blob], `thumb_${photo.id}.jpeg`);
					const result = await this.$store.dispatch("uploadUserPhoto", {
						file,
						//thumb,
						type: 1,
						unsplash: photo.id,
					});

					if (result.success) {
						this.setActivePhotoById(localPhoto.id, true);
						this.$store.state.userUploadFiles.push(result.image);
						this.localFiles.push(result.image);
					}
				} catch (err) {
					console.error(err);
				}
			}

			loading.remove();
			target.css({ "pointer-events": "all" });
		},

		async loadCollections() {
			let storeCollections = this.$store.state.unsplash.collections;
			if (!storeCollections || !storeCollections.length) {
				storeCollections = await UnsplashAPI.getCollections();
			}

			this.categories.collections = storeCollections;
			this.$store.state.unsplash.collections = storeCollections;
		},

		async loadingPhotos(page, options = {}) {
			this.pageControl.isLoading = true;
			if (!page && page != 0) {
				page = this.pageControl.currentPage;
			}

			const currentSelected = this.categories.getCurrent();
			if (currentSelected) {
				let content = [];
				let config = { ...this.config, page };

				if (this.searchKeywords) {
					config = {
						...config,
						query: this.searchKeywords,
						collections: currentSelected.id,
					};

					const searchResult = await UnsplashAPI.findPhotos(config);
					this.pageControl.totalPages = searchResult.total_pages;
					content = searchResult.results;
				} else {
					content = await UnsplashAPI.getPhotos(currentSelected.id, config);
				}

				if (!content.length) {
					this.lockLoadingPages = true;
					// if search send error
					return;
				}

				if (options.isClear) {
					this.photos = content;
					this.parseImages(content);
				} else {
					this.photos.push(...content);
				}

				setTimeout(() => {
					this.pageControl.isLoading = false;
					this.photos = this.parseImages(this.photos);
					this.contentUpload();
				}, 500);
			}
		},

		parseImages(images = []) {
			return images.map((image) => {
				const storeImage = this.localFiles.find(
					(si) => si.unsplash == image.id
				);

				if (storeImage) {
					image.isActive = true;
				}

				return image;
			});
		},
	},

	watch: {
		updateCollections(collections) {
			this.categories.options = collections.map(
				(collection) => collection.title
			);

			if (!this.categories.current && this.categories.options.length) {
				this.categories.current = this.categories.options[0];
			}
		},

		async getCurrentCategorie() {
			// this.pageControl.currentPage = 1;
			this.lockLoadingPages = false;
			// this.searchKeywords = "";
			await this.loadingPhotos(undefined, { isClear: true });
		},
	},

	computed: {
		getLocalPhotos() {
			return this.photos;
		},

		getCurrentCategorie() {
			return this.categories.current;
		},

		updateCollections() {
			return this.categories.collections;
		},
	},
};
</script>

<style scoped>
.graphics_popup {
	height: calc(100vh - 80px) !important;
	width: calc(100vw - 100px) !important;
}

.popup_body {
	height: 100% !important;
}

.graphics_popup .popup_body {
	flex-direction: column;
	background: #1d1d1d 0% 0% no-repeat padding-box;
}

.head-panel {
	display: flex;
	flex-direction: row;
}

.categories {
	width: 260px;
	font: normal normal normal 16px/20px Proxima Nova;
	letter-spacing: -0.77px;
	color: #8c8c8c;
	margin-left: 14px;
}

.unsplash {
	width: 88px;
	text-align: left;
	font: normal normal normal 12px/15px Proxima Nova;
	letter-spacing: 0px;
	padding: 0 !important;
	margin: 9px 18px;
}

.panel_row {
	width: 960px;
	min-width: 200px;
	padding: 0;
}

.head-panel {
	padding: 24px 18px;
	color: #8c8c8c;
	font: normal normal normal 12px/15px Proxima Nova;
}

.head-panel > div {
	padding: 0;
}

.unsplash > a {
	font: normal normal normal 12px/15px Proxima Nova;
	letter-spacing: 0px;
	color: #249acf;
}

.graphics_popup_right_items_wrap > ul {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
}

.graphics_popup_right_items_wrap > ul > li {
	flex: 0 0 20%;
	max-width: 20%;
	padding: 5px;
	position: relative;
}

.graphics_popup_right_items_wrap > ul > li > img {
	margin-top: 8px;
	vertical-align: middle;
	width: 100%;
}

.stack_block_images:hover > img {
	border: 1px solid #666666;
}

.graphics_popup_right_items_wrap img {
	width: 100%;
}

.stack_block_images > img {
	border: 1px solid rgba(0, 0, 0, 0);
	width: 100%;
}

.graphics_popup_right_items_wrap img.active,
.graphics_popup_right_items_wrap img.active:hover {
	border: 1px solid #249acf;
}

img {
	cursor: pointer;
}

.graphics_popup_right_items_wrap {
	text-align: center;
}

.graphics_popup_right_items_wrap {
	padding: 0 18px;
}

.credition-image {
	position: absolute;
	color: #fff;
	background: linear-gradient(rgb(255 255 255 / 0) 50%, rgb(0 0 0 / 80%));
	width: calc(100% - 2px);
	height: calc(100% - 6px);
	left: 1px;
	top: 1px;
	cursor: pointer;
	display: none;
}

.stack_block_images:hover > .credition-image {
	display: block;
}

.credition-image > span {
	position: absolute;
	bottom: 5px;
	left: 10px;
	display: inline;
	width: calc(100% - 20px);
	text-align: left;
	pointer-events: none;
}

.hr_line {
	height: 2px;
	background: #111;
}

</style>
<style>
.popup_unsplash .text_input.search_input {
	border-radius: 4px;
}
.popup_unsplash .head-panel{
	border-top: 2px solid #000;
	border-bottom: 2px solid #000;
}
.popup_unsplash .text_input.search_input ~ svg {
	top: 50%;
	transform: translateY(-50%);
}
.popup_unsplash .text_input.search_input ~ svg:hover {
	opacity: 0.9;
}
.popup_unsplash .text_input.search_input:hover,
.popup_unsplash .categories .dropdown_select:hover {
	box-shadow: 0px 0px 8px #ffffff14;
}
.popup_unsplash .stack_block_images:hover > img {
	border: 2px solid #666666;
	/* border: 0; */
	/* width: calc(100% - 2px); */
	display: block;
}
.popup_unsplash .credition-image {
	height: calc(100% - 2px);
	background: linear-gradient(0deg, rgb(0 0 0) -32%, rgba(255,255,255,0) 44%);
}
.popup_unsplash .stack_block_images:hover .credition-image {
	outline: 1px solid #666666;
}
.popup_unsplash .credition-image > span {
	bottom: 15px;
	left: 15px;
}
.popup_unsplash .graphics_popup_right_items_wrap img {
	border: 2px solid transparent;
}
.popup_unsplash .graphics_popup_right_items_wrap img.active {
	border: 2px solid #249acf;
	/* width: calc(100% - 2px); */
	display: block;
}
.popup_unsplash .head-panel .panel_row {
	flex: 1;
}
</style>
