<template>
    <div :class="addedClass">
        <label v-if="textLabel" class="text_label has_mb">{{ textLabel }}</label>
        <div class="dropdown_select" :class="selectClass">
            <div class="dropdown_select_current" v-on:click="$emit('toggleDropdown')">
            <span class="select_current_text"
                  :style="{ 'font-family': currentFontComputed }">{{ currentFontComputed }}</span>
                <ArrowDownIcon/>
            </div>
            <div class="dropdown_select_wrap">
                <div v-bar>
                    <ul class="dropdown_select_list dropdown_select_font_list">
                        <li v-for="(font, index) in loadFontListComputed"
                                :key="index"
                                :style="{ 'font-family': font.value.replace(/[0-9]/g, '').trim() }"
                                :value="font.value"
                                :class="[isCurrent(font.title), isDivider(font.title)]"
                                v-on:click="setCurrentFont(font)">{{ font.title }}
                            <span v-if="font.isPremium && !premiumCurrentUser" class="inch-icon inch-plus-membership-icon"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ArrowDownIcon from "@/assets/img/arrow_down.svg?inline";

    export default {
        name: "DropdownSelectFont",
        components: {
            ArrowDownIcon
        },
        props: {
            textLabel: String,
            selectClass: String,
            addedClass: {
                type: String,
                default: () => ''
            }
        },
        data: function () {
            return {
                options: this.fonts,
                toggle: false,
                premiumCurrentUser: false,
                fonts: [],
            };
        },
        created: function () {
            this.$parent.$on('loadFontList', this.loadFontList);
            this.$parent.$on('loadCurrentFont', this.loadCurrentFont);
            this.isPremiumCurrentUser();
        },
        computed: {
            loadFontListComputed() {
                return this.$store.state.fonts;
            },

            currentFontComputed() {
                return this.$store.state.currentFont;
            },
            
            triggerDropdown() {
                return this.$store.state.triggerDropdown;
            }
        },
        watch: {
            triggerDropdown() {
                this.$emit('resetSelectClass');
            }
        },
        methods: {
            async isPremiumCurrentUser() {
                this.premiumCurrentUser = await this.$store.dispatch(
                    "isPremiumCurrentUser"
                );
            },
            setCurrentFont(font) {
                if(font.isPremium && !this.premiumCurrentUser) {
                     
                    // if (window.abVariant && window.abVariant == 2) {
                        this.$store.dispatch("popupsToggle", {
                            property: "stripeCheckoutPopup",
                            value: true
                        });
                    // }
                    // else{
                    //     this.$store.dispatch("popupsToggle", {
                    //         property: "upgradePopup",
                    //         value: true
                    //     });
                    // }
                    return;
                }

                this.$emit('selectDropdown', font);
                this.$store.state.currentFont = font.title.replace(/[0-9]/g, '').trim();
            },
            loadFontList() {
                let fonts = '';
                let currentFont = false;

                this.$store.state.fonts.forEach(font => {
                    if (font.title === 'Open Sans') {
                        currentFont = font.title;
                    }

                    fonts += `@font-face {
                        font-family: "${font.title.replace(/[0-9]/g, '').trim()}";
                        src: url("${font.url}") format("woff2")
                    }\n`;
                });

                if (!currentFont) {
                    this.$store.state.currentFont = this.$store.state.fonts[0].title;
                } else {
                    this.$store.state.currentFont = currentFont;
                }

                const styleElement = document.createElement('style');
                styleElement.innerHTML = fonts;
                document.head.appendChild(styleElement);
            },
            isCurrent(value) {
                return value === this.current ? "active" : "";
            },
            isDivider(value) {
                return value === "_divider" ? "divider" : "";
            },
            addClick() {
                this.$nextTick(() => {
                    /*if (this.selectClass === 'active' && this.toggle) {
                        this.$emit('selectDropdown', this.current);
                        document.removeEventListener('click', this.addClick);
                        this.toggle = false;
                    } else {*/
                        this.toggle = true
                    /*}*/
                });
            },
        },
        updated() {
            if (this.selectClass === 'active') {
                document.addEventListener('click', this.addClick);
            }
        },
        destroyed() {
            if (this.selectClass === 'active') {
                this.$emit('selectDropdown', this.current);
                document.removeEventListener('click', this.addClick)
            }
        }
    };
</script>

<style scoped>
.vb {
    height: 550px !important;
}

.inch-plus-membership-icon {
    width: 12px;
    height: 14px;
    position: absolute;
    right: 38px;
}
</style>
