export default {
    methods: {
        lzLock() {
            //this.$store.state.lockZoomScroll = true;
            this.changeZoomCssToGlobal();
            // this.$store.state.fitToScreenComp += 1;
        },

        changeZoomCssToGlobal() {
            // const css = _LIB.lib.getStylesObject($(".centered-content"));
            // let zoom = parseFloat((css.transform || '').replace(/[a-zA-Z]|\(|\)/g, ''));
        
            // if(zoom) {
            //     this.$store.state.global.zoomDashboard = zoom;
            //     this.$store.state.global.zoomDashboardCounter += 1;
            //     _LIB.app.scaleStage(zoom);
            //     _LIB.app.setZoomProcentage(zoom, true);
            //     $(".centered-content").css({ 'transform': `` });
            // }
        },

        lzDestroy() {
            //this.$store.state.lockZoomScroll = false;
        },

        updateCanvasSizeByZoom() {
            if(_LIB.Drawing && _LIB.Drawing.canvas
                && _LIB.Drawing.canvas.freeDrawingBrush
                && _LIB.Drawing.canvas.freeDrawingBrush.resizeTo) {
                _LIB.Drawing.canvas.freeDrawingBrush.resizeTo(
                    _LIB.app.stage.width,
                    _LIB.app.stage.height
                );
            }
        },
    }
}