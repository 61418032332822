<template>
  <li
    class="layer_item list-item"
    :class="[isHidden, isEditing, isActive]"
    @click="$emit('toggleActive')"
  >
    <div class="layer_item_left">
      <div class="img_wrpr" v-if="imgIcon">
        <span v-for="(icon, index) in imgIcon" v-bind:key="index" :class="icon"></span>
      </div>
      <div v-else-if="isText" class="img_wrpr">
        <span class="inch-icon inch-text-icon" style="margin: 0 auto;"></span>
      </div>
      <div v-else class="img_wrpr">
        <img :src="imgPath" />
      </div>

      <span ref="editField" :title="textTitle" class="layer_title" @dblclick.prevent="editStart()">
        {{ title }}
        <input
          type="text"
          class="layer-input"
          ref="editInput"
          v-model="layerTitle"
          @keyup.enter="editComplete"
          @blur="editComplete"
          autofocus
        />
      </span>
    </div>
    <span class="icon_right_wrpr">
      <div class="layer_item_btn hide_layer_btn inch-hide-icon" v-on:click="$emit('toggleHidden')" v-tooltip="'Hide'"></div>
      <div class="layer_item_btn inch-drag-icon" v-tooltip="'Drag'" v-handle></div>
    </span>
    <div v-if="edit" class="layer-focusout" @click="editComplete"></div>
  </li>
</template>

<script>
import { ElementMixin, HandleDirective } from "vue-slicksort";

export default {
  name: "LayerItem",
  mixins: [ElementMixin],
  directives: { handle: HandleDirective },
  props: {
    title: String,
    imgPath: String,
    layerId: String,
    isText: Boolean,
    imgIcon: Array,
    hidden: Boolean,
    active: Boolean,
    textTitle: String
  },
  data: function() {
    return {
      edit: false,
      layerTitle: ""
    };
  },
  // mounted: function() {
  //     this.layerTitle = this.title
  // },
  computed: {
    isHidden() {
      return this.hidden ? "hidden" : "";
    },
    isEditing() {
      return this.edit ? "editing" : "";
    },
    isActive() {
      return this.active ? "active" : "";
    }
  },
  methods: {
    editStart: function() {
      this.edit = true;
      this.$nextTick(() => this.$refs.editInput.focus());
    },
    editComplete: function() {
      this.edit = false;
      if (this.layerTitle != "") {
        this.$emit("titleChange", {
          _id: this.layerId,
          name: this.layerTitle
        });
      }
    }
  }
};
</script>

<style>
.layer-focusout {
  position: fixed;
  width: calc(100vw - 570px);
  top: 0;
  bottom: 0;
  right: 100%;
  opacity: 0.5;
}
.text-icon-layer {
  font-size: 18px;
  padding: 0 5px;
}
</style>