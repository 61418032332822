<template>
  <div class="color_input_wrpr radius-4" v-bind:class="{ without_color : inputColorClass }">
    <label v-if="textLabel" class="text_label">{{textLabel}}</label>
    <div class>
      <div class="color_input_square_wrpr" v-if="color == ''">
        <input type="text" name class="color_input" v-on:click="handeleColorPicker"/>
      </div>
      <div class="color_input_square_wrpr" v-if="!color == ''">
        <input type="text" name class="color_input" :style="{background: color}" v-on:click="handeleColorPicker"/>
      </div>


      <div
        class="color_picker_component"
        :style="getColorPickerStyle()"
        v-custom-drag
        v-if="showColorPicker"
      >
        <div class="color_picker_header">
          <Checkmark msg="Color" :disabled="checkmarkDisabled" :checkboxCheck="checkColor" :key="checkColor" @update="updateCheckColor" />
          <a class="close_popup_btn" v-on:click="handeleColorPicker">
            <span class="inch-icon inch-close-icon"></span>
          </a>
        </div>
        
        <color-picker
          ref="color_picker"
          theme="dark"
          :color="color"
          :sucker-hide="suckerHide"
          :sucker-canvas="suckerCanvas"
          :sucker-area="suckerArea"
          @changeColor="changeColor"
          @openSucker="openSucker"
          :key="updColorPicker"
          :suckerPixels="suckerPixels"
        />
      </div>
    </div>    
  </div>
</template>


<script>
// import colorPicker from "@caohenghu/vue-colorpicker";

import colorPicker from "../libs/vue-colorpicker/src";
import Checkmark from "@/components/Checkmark.vue";

export default {
  name: "ColorInput",
  components: {
    colorPicker,
    Checkmark,
  },
  props: {
    suckerPixels: {
        type: [Uint8Array, Boolean],
        default: () => false
    },

    checkmarkDisabled: {
      type: Boolean,
      default: () => false
    },
    classs: String,
    textLabel: String,
    defColor: {
      type: String,
      default: ''
    },
    changeColorValue: Function,
    suckerHide: {
      type: Boolean,
      default: false,
    },
    hideColorPicker: {
      type: Boolean,
      default: false,
    },
    checkColor: {
      type: Boolean,
      default: true,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showColorPicker: false,
      color: this.defColor,
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      inputColorClass: this.classs || false,
      check: true,
      updColorPicker: true,
      selectedColor: null,
      isActiveColorPicker: undefined,
    };
  },

  mounted() {
    this.inputColorClass = !this.checkColor;
    window.colorPicker = this;
  },

  methods: {
    getColorPickerStyle() {
      return this.isFixed
        ? {
            position: "fixed",
            left: "325px", top: "10px",
          }
        : {};
    },
    getDefaultColorConfig() {
      if (this.selectedColor && typeof this.selectedColor == "object") {
        return JSON.parse(JSON.stringify(this.selectedColor));
      }

      return {
        rgba: { r: 0, g: 0, b: 0, a: 1 },
        hsv: { h: 0, s: 0, v: 0 },
        hex: "#000000",
      };
    },

    updateCheckColor(e) {
      let value = e.target.checked;

      this.$emit("updateCheckedColor", value);
      this.isActiveColorPicker = value;
      this.inputColorClass = !value;
      let color = this.getDefaultColorConfig();

      if (!value) {
        color.hex = `${color.hex}00`;
        color.rgba.a = 0;
      }

      //this.updColorPicker = !this.updColorPicker;
      this.changeColor(color, true);
    },

    changeColor(color, isChecked = false) {
      if (!isChecked) {
        this.selectedColor = color;
      }

      if (!this.isActiveColorPicker && !isChecked) {
        return this.updateCheckColor({ target: { checked: true } });
      }

      if (
        (typeof this.isActiveColorPicker !== "undefined" &&
          this.isActiveColorPicker) ||
        (typeof this.isActiveColorPicker == "undefined" && this.checkColor) ||
        isChecked
      ) {
        const { r, g, b, a } = color.rgba;
        this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
        
        this.$emit("input", color.hex);
        this.$emit("inputRGBA", this.color);
        if(color.isUpdated) {
            this.$emit("updateRGBA", this.color);
            this.$emit("update", color);
        }
        
        this.$store.dispatch("selectedColor", color);

        if (this.changeColorValue) {
          this.changeColorValue(color);
        }
      }
    },

    async cloneCanvas() {
      //eturn this.$lib.app.renderer.extract.canvas(this.$lib.app.stage);
      return new Promise(async (reoslve) => {
        var newCanvas = document.createElement("canvas");
        var context = newCanvas.getContext("2d");
        newCanvas.width = this.$lib.app.stage.width;
        newCanvas.height = this.$lib.app.stage.height;
        let image = new Image();
        image.onload = function () {
          context.drawImage(image, 0, 0, newCanvas.width, newCanvas.height);

          reoslve(newCanvas);
        };

        image.src = await this.$lib.app.extractStage();
      });
    },

    async openSucker(isOpen) {
      console.log({ isOpen });

      this.$lib.app.colorPicker.isOpen = isOpen;
      var overlay = document.querySelector(".pop_up_closer") || false;
      var canvas = document.getElementById("fabricDashboard");
      if ($(canvas).closest(".absolute-position").css("display") == "none") {
        //     let te = `<div class="absolute-position"><div class="centered-content" id="qwe"></div></div>`;

        //     const ebola = document.getElementById("ebola");
        //     if (ebola) {
        //       $(ebola).closest(".absolute-position").remove();
        //     }

        //     let cv = await this.cloneCanvas();
        //     // cv.width *= this.$lib.app.zoomProcentage;
        //     // cv.height *= this.$lib.app.zoomProcentage;

        //     cv.setAttribute("id", "colorPickerCanvas");
        //     console.log('cv', cv);

        //     $(canvas).closest(".image_element").append(te);
        //     document.getElementById('qwe').appendChild(cv);
        //     canvas = cv;

        if (isOpen) {
          this.suckerArea = [0, 0, 1, 1];
          const canvas = document.getElementById("canvasDashboard");
          this.suckerCanvas = canvas;
          overlay ? (overlay.style.display = "none") : "";
          $(canvas).closest(".centered-content").css({
            cursor: "default"
          });
        } else {
          $(document.getElementById("canvasDashboard")).css({
            cursor: "default",
            "z-index": 'auto',
          });

          this.showColorPicker = false;
          this.suckerArea = [];
          this.isSucking = false;
          overlay ? (overlay.style.display = "") : "";
          var preview = document.querySelector(".sucker-preview") || false;
          preview ? preview.remove() : "";
        }

        return;
      }

      if (isOpen) {
        var coverRect = canvas.getBoundingClientRect();
        this.suckerCanvas = canvas;
        this.suckerArea = [
          coverRect.left,
          coverRect.top,
          coverRect.left + coverRect.width,
          coverRect.top + coverRect.height,
        ];
        canvas.style.zIndex = 1;
        overlay ? (overlay.style.display = "none") : "";
      } else {
        this.showColorPicker = false;
        this.suckerCanvas = null;
        this.suckerArea = [];
        this.isSucking = false;
        canvas.style.cursor = "default";
        canvas.style.zIndex = 0;
        var preview = document.querySelector(".sucker-preview") || false;
        preview ? preview.remove() : "";
        overlay ? (overlay.style.display = "") : "";
      }
    },
    handeleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
      if(!this.checkColor) this.color = "";

      // if(this.showColorPicker) {
      //   if(/^rgba/g.test(this.color)) {
      //     const cl = this.$lib.lib.rgbaToHexAlpha(this.color);
      //     if(cl.alpha < )
      //   }
      // }
    },
  },
  computed: {
    compPicker() {
      return this.showColorPicker;
    },
  },
  watch: {
    compPicker(val) {
      if (!val) {
        this.openSucker(false);
      }
    },
  },
  beforeDestroy() {
    if (this.suckerCanvas) {
      this.openSucker(false);
    }
  },
};
</script>