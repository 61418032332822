import Vue from 'vue'
import VueRouter from 'vue-router'
window.prefixURL = '';
// window.effectsURL = window.is_integration ? "https://integration.cartoonize.net" : "https://api.cartoonize.net";
window.effectsURL = window.is_integration ? "https://integration.cartoonize.net" : window.EFFECTS_SERVER;
window.routerPrefixURL = '';
window.locationOrigin = 'https://www.cartoonize.net';
window.iframe_api_key = "";

var search_param = new URLSearchParams(window.location.search);
window.integration_key = search_param.get("api_key");

Vue.use(VueRouter)

const routes = [
  {
    path: `${window.routerPrefixURL}/`,
    name: 'home',
    meta: {
      title: 'Home',
    },
    component: () => import('../views/Home.vue')
  },

  {
    path: `${window.routerPrefixURL}/image-manager`,
    name: 'image manager',
    meta: {
      title: 'Image manager',
    },
    component: () => import('../views/ImageManagerView.vue')
  },
  {
    path: `${window.routerPrefixURL}/my-account`,
    name: 'my account',
    meta: {
      layout: "empty",
      title: 'My Account',
    },
    component: () => import('../views/MyAccount.vue')
  },
  {
    path: `${window.routerPrefixURL}/pay/success`,
    name: 'paysuccess',
    meta: {
      title: 'Payment success',
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: `${window.routerPrefixURL}/pay/failed`,
    name: 'payfailed',
    meta: {
      title: 'Payment failure',
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: `${window.routerPrefixURL}/home`,
    name: 'home-router',
    redirect: `${window.routerPrefixURL}/`
  },
  {
    path: `${window.routerPrefixURL}/*`,
    redirect: `${window.routerPrefixURL}/`
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' - Colorcinch – The #1 Photo Editor & Cartoonizer – Simple, Beautiful & Free' || 'Colorcinch – The #1 Photo Editor & Cartoonizer – Simple, Beautiful & Free';
  next();
});

export default router
