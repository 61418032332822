<template>
  <div class="popup" v-if="popUpVisible" v-custom-drag>
    <div class="popup_header">
      <h3>{{popUpLabel}}</h3>
      <a class="close_popup_btn" v-on:click="popUpPropChange(popUpLabel)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div id="tabs">
          <ul class="button-tabs-instrumets tabs-nav">
            <li class="btn-action margin_bottom_15 full">
              <a href="#" :class="{ active: tab == 'adjust' }" @click="changeTab('adjust')" draggable="false">Adjust</a>
            </li>

            <li class="btn-action margin_bottom_15 full">
              <a href="#" :class="{ active: tab == 'erase' }" @click="changeTab('erase')" draggable="false">Erase</a>
            </li>
          </ul>
      </div>
      <div
          class="popup_body_row"
          :style="{ 'max-height': '75vh', 'overflow-y': 'auto' }"
      >
        <div v-show="tab == 'adjust'">
          <div class="popup_body_row_item">
            <DropdownSelect
                textLabel="Blend Mode"
                :current="selectDropdownValue"
                :selectClass="selectDropdown"
                @resetSelectClass="(selectDropdown = '')"
                :selectOptions="selectOptions"
                @toggleDropdown="openDropdown"
                @selectDropdown="selectDrop"
            />
          </div>
          <!-- <div class="popup_body_row_item">
                      <Range
                          rangeSliderId="editOpacity"
                          :rangeSliderValue = 0
                          rangeSliderLabel="Intensity"
                          rangeSliderMeasure = "%"
                          rangeSliderClass="range-blue"
                          :minValue = 0
                          :maxValue = 100 />
          </div>-->
          <div class="double_btns_block popup_body_row_item">
            <div class="double_btns_left">
              <label class="text_label has_mb">Rotate</label>
              <div class="btns_wrpr">
                <a @click="rotate(-90)" class="btn-action-left btn-action btn-action-w-74">
                  <span class="inch-icon inch-rotate-left-icon"></span>
                </a>
                <a @click="rotate(90)" class="btn-action-right btn-action btn-action-w-74">
                  <span class="inch-icon inch-rotate-right-icon"></span>
                </a>
              </div>
            </div>
            <div class="double_btns_right">
              <label class="text_label has_mb">Flip</label>
              <div class="btns_wrpr">
                <a @click="flip(1)" class="btn-action-left btn-action btn-action-w-74">
                  <span class="inch-icon inch-flip-horizontal-icon"></span>
                </a>
                <a @click="flip(2)" class="btn-action-right btn-action btn-action-w-74">
                  <span class="inch-icon inch-flip-verical-icon"></span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <EraseTool v-show="tab == 'erase'"
                   :tool="tool" :eraseConfig="eraseConfig" @resetDrawing="$emit('resetDrawing')"
                   @changeConfig="changeConfig" @changeToolName="changeToolName"
        />

        <div class="popup_body_row_item">
          <div class="btns-group mt0">
            <a tabIndex="0" class="grey_btn" v-on:click="resetParams">Cancel</a>
            <a tabIndex="0" href="#" class="blue_btn" @click="$emit('applyOverlay')">Apply</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
//import Range from '@/components/Range.vue';
import DropdownSelect from "@/components/DropdownSelect.vue";
import MixinPopupBrush from '../../mixin/MixinPopupBrush';
import EraseTool from '../EraseTool.vue';

export default {
  name: "PopupOverlays",
  mixins: [MixinPopupBrush],
  props: {
    popUpVisible: Boolean,
    popUpPropChange: Function,
    popUpLabel: String,
    overlayParams: Object
  },
  components: {
    //Range,
    EraseTool,
    DropdownSelect
  },

  data: function() {
    const PIXI = this.$lib.PIXI;
    // const blendModes = {
    //   Normal: PIXI.BLEND_MODES.NORMAL,
    //   Add: PIXI.BLEND_MODES.ADD,
    //   Multiply: PIXI.BLEND_MODES.MULTIPLY,
    //   Screen: PIXI.BLEND_MODES.SCREEN,
    //   Darken: PIXI.BLEND_MODES.DARKEN,
    //   "Color Burn": PIXI.BLEND_MODES.COLOR_BURN,
    //   Lighten: PIXI.BLEND_MODES.LIGHTEN,
    //   "Color Dodge": PIXI.BLEND_MODES.COLOR_DODGE,
    //   Overlay: PIXI.BLEND_MODES.OVERLAY,
    //   "Soft Light": PIXI.BLEND_MODES.SOFT_LIGHT,
    //   "Hard Light": PIXI.BLEND_MODES.HARD_LIGHT,
    //   Difference: PIXI.BLEND_MODES.DIFFERENCE,
    //   Exclusion: PIXI.BLEND_MODES.EXCLUSION,
    //   Hue: PIXI.BLEND_MODES.HUE,
    //   Saturation: PIXI.BLEND_MODES.SATURATION,
    //   Color: PIXI.BLEND_MODES.COLOR,
    //   Luminosity: PIXI.BLEND_MODES.LUMINOSITY
    // };

    const blendModes = this.$lib.lib.BLEND_MODES;

    return {
      tab: 'adjust',
      blendModes,
      selectDropdown: "",
      selectDropdownValue: "Normal",
      selectOptions: blendModes.map(mode => this.parseBlendModeName(mode))
    };
  },
  
  beforeDestroy() {
    this.lzDestroy();
  },

  computed: {
    checkBlendModeUpdate() {
      return this.overlayParams.blendMode;
    },

    checkPopUpVisible() {
      return this.popUpVisible;
    }
  },

  watch: {
    popUpVisible() {
      this.tab = 'adjust';
    },

    checkPopUpVisible() {
      this.updateSelectBlendMode(this.overlayParams.blendMode);
    },

    checkBlendModeUpdate(blendMode) {
      this.updateSelectBlendMode(blendMode);
    }
  },

  mounted() {
    this.updateSelectBlendMode(this.overlayParams.blendMode);
  },

  methods: {
    changeTab(tab) {
      this.tab = tab;
      if(tab == 'erase') {
        this.lzLock();
        this.updateCanvasSizeByZoom();

        this.changeConfig({ key: 'brushSize', value: this.eraseConfig.brushSize });
      }
    },

    parseBlendModeName(mode = '', isOut = false) {
      return this.$lib.Overlays.parseBlendModeName(mode, isOut);
    },

    updateSelectBlendMode(blendMode) {
      const PIXI = this.$lib.PIXI;
      let isUpdated = false;
      blendMode = this.parseBlendModeName(blendMode, true);

      for (const mode of this.blendModes) {
        try {
          if (mode == blendMode) {
            this.selectDropdownValue = this.parseBlendModeName(blendMode);
            isUpdated = true;
            break;
          }
        } catch (err) {
          console.error(err);
        }
      }

      if (!isUpdated) {
        this.selectDropdownValue = "Normal";
      }
    },

    resetParams() {
      this.popUpPropChange(this.popUpLabel);
      this.overlayParams.mode = this.overlayParams.blendMode;
      this.overlayParams.flipX = false;
      this.overlayParams.flipY = false;
      this.overlayParams.rotate = 0;

      this.updateSelectBlendMode(this.overlayParams.blendMode);

      this.setOverlayOptions();
      //this.$emit("resetOverlayParams", this.currentFilter);
    },

    setOverlayOptions() {
      this.$emit("setOverlayOptions", this.overlayParams);
    },

    openDropdown() {
      this.selectDropdown = "active";
    },

    rotate(angle) {
      this.overlayParams.rotate =
        this.overlayParams.rotate || this.overlayParams.rotate == 0
          ? this.overlayParams.rotate + angle
          : angle;
      this.setOverlayOptions();
    },

    flip(mode) {
      this.overlayParams[mode == 2 ? "flipX" : "flipY"] = !this.overlayParams[
        mode == 2 ? "flipX" : "flipY"
      ];

      this.setOverlayOptions();
    },

    selectDrop(value) {
      const parsedBlendMode = this.parseBlendModeName(value, true);
      this.overlayParams.mode = parsedBlendMode
      this.setOverlayOptions();

      // this.$lib.app.stage.children[1].filters = [new this.$lib.filters.BlendModeFilterUpd({ mode: parsedBlendMode })];
      // this.$lib.app.reRenderCanvas();
      /* var PIXI = this.$lib.PIXI;
                var def = {
                    'Normal': PIXI.BLEND_MODES.NORMAL,
                    'Add': PIXI.BLEND_MODES.ADD,
                    'Multiply': PIXI.BLEND_MODES.MULTIPLY,
                    'Screen': PIXI.BLEND_MODES.SCREEN,
                    'Darken': PIXI.BLEND_MODES.DARKEN,
                    'Color Burn': PIXI.BLEND_MODES.COLOR_BURN,
                    'Lighten': PIXI.BLEND_MODES.LIGHTEN,
                    'Color Dodge': PIXI.BLEND_MODES.COLOR_DODGE,
                    'Overlay': PIXI.BLEND_MODES.OVERLAY,
                    'Soft Light': PIXI.BLEND_MODES.SOFT_LIGHT,
                    'Hard Light': PIXI.BLEND_MODES.HARD_LIGHT,
                    'Difference': PIXI.BLEND_MODES.DIFFERENCE,
                    'Exclusion': PIXI.BLEND_MODES.EXCLUSION,
                    'Hue': PIXI.BLEND_MODES.HUE,
                    'Saturation': PIXI.BLEND_MODES.SATURATION,
                    'Color': PIXI.BLEND_MODES.COLOR,
                    'Luminosity': PIXI.BLEND_MODES.LUMINOSITY
                };

                this.$lib.app.stage.children[1].blendMode = def[value];
                this.$lib.app.reRenderCanvas();


                 */
      this.selectDropdownValue = value;
      this.selectDropdown = "";
    }
  }
};
</script>

<style scoped src="@/assets/css/popupStyles.css"></style>
