import { render, staticRenderFns } from "./RemoveBackgroundPanel.vue?vue&type=template&id=0e46c4c8&scoped=true&"
import script from "./RemoveBackgroundPanel.vue?vue&type=script&lang=js&"
export * from "./RemoveBackgroundPanel.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/popupStyles.css?vue&type=style&index=0&id=0e46c4c8&prod&scoped=true&lang=css&"
import style1 from "./RemoveBackgroundPanel.vue?vue&type=style&index=1&id=0e46c4c8&prod&scoped=true&lang=css&"
import style2 from "./RemoveBackgroundPanel.vue?vue&type=style&index=2&id=0e46c4c8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e46c4c8",
  null
  
)

export default component.exports