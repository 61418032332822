<template>
  <div
    class="range_slider_box"
    :class="sliderBoxClass"
    :key="updRange"
    @dblclick.stop="resetSlider()"
    ref="rangeBox"
  >
    <div class="range-top-info">
      <span>{{ rangeSliderLabel }}</span>
      <div class="range-output">
        <template v-if="rangeSliderMeasure">
          <div class="output-value">{{ rangeSliderValue }}</div>
          <span class="range_measure">{{ rangeSliderMeasure }}</span>
        </template>
        <template v-else>
          <div class="output-value">{{ rangeSliderValue }}</div>
        </template>
      </div>
    </div>
    <VueSlider
      :value="rangeSliderValue"
      tooltip="none"
      :class="'vue_slider ' + rangeSliderClass ? rangeSliderClass : 'range-blue'"
      @drag-start="dragStart(thisRangeValue)"
      @change="changeSliderValue"
      @drag-end="dragEnd(thisRangeValue)"
      :min="minValue || minValue == 0 ? minValue : 0"
      :max="maxValue || maxValue == 0 ? maxValue : 100"
      :interval="stepSlider || 1"
      :disabled="disabled"
      ref="rangeSlider"
    >
      <template v-slot:process="{ start, end }">
        <div class="vue-slider-process line-progress">
          <div class="line-left" :style="{width: 'calc('+end +'% - 2px)'}"></div>
          <div class="line-right" :style="{width: 'calc('+(100 - end) +'% - 2px)'}"></div>
        </div>
      </template>
    </VueSlider>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
export default {
  name: "RangeSlider",
  components: {
    VueSlider,
  },
  beforeMount() {
    if (this.sliderRangeState) {
      this.thisRangeValue = this.sliderRangeState.value;
    } else {
      this.addSliderRangeState([this.rangeSliderId, this.rangeSliderValue]);
      this.thisRangeValue = this.rangeSliderValue;
    }
  },
  computed: {
    sliderRangeState() {
      return this.$store.state.ranges[this.rangeSliderId];
    },
    dotLeft() {
      return this.leftWidth + "%";
    },
    dotRight() {
      return this.rightWidth + "%";
    },
  },

  methods: {
    changeSliderValue(e) {
      this.thisRangeValue = e;
      this.sliderRangeStateChange(this.thisRangeValue);
      if (!this.dragstarted) {
        this.$emit("dragEnd", e);
      }
      else{
        if (this.changeValue) {
          this.changeValue(e);
        }
        this.$emit("changeValue", e);
      }
    },
    dragStart(value){
      this.dragstarted = true
    },

    dragEnd(value) {
      this.dragstarted = false
      this.$emit("dragEnd", value);
    },

    sliderRangeStateChange(numb) {
      this.$store.dispatch("sliderRangeStateChange", numb);
    },

    addSliderRangeState(newState) {
      this.$store.dispatch("addSliderRangeState", newState);
    },
    
    resetSlider() {
      this.updRange = !this.updRange;

      const value = this.defaultValue || 0;
      this.$emit("changeValue", value);
      if (this.changeValue) {
        this.changeValue(value);
      }

      this.$emit("dragEnd", value);
      //this.$emit('update', this.defaultValue || 0);

      // if (this.thisRangeValue != this.rangeSliderValue) {
      //   this.sliderRangeStateChange(this.rangeSliderValue);
      //   this.thisRangeValue = this.rangeSliderValue;
      //   this.$refs.rangeSlider.setValue(this.thisRangeValue);
      // }
    },
  },
  props: {
    rangeSliderId: String,
    rangeSliderValue: Number,
    stepSlider: Number,
    rangeSliderLabel: String,
    rangeSliderMeasure: String,
    rangeSliderClass: String,
    minValue: Number,
    maxValue: Number,
    defaultValue: Number,
    sliderBoxClass: String,
    changeValue: Function,
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data: function () {
    return {
      thisRangeValue: 50,
      updRange: false,
      dragstarted: false,
    };
  },
};
</script>

<style>
/* .range-hue
.range-saturation
.range-temperature
.range-tint
.range-vibrance */
.vue-slider {
  border-radius: 15px;
  overflow: hidden;
}
.line-left {
  position: absolute;
  height: 2px;
  width: 222px;
  border-radius: 100px;
  left: -8px;
  top: 0;
  pointer-events: none;
}
.line-right {
  position: absolute;
  height: 2px;
  width: 222px;
  border-radius: 100px;
  right: -8px;
  top: 0;
  pointer-events: none;
}
.range-blue .line-left {
  background-color: #249acf;
}
.range-blue .line-right {
  background-color: #8c8c8c;
}

body .main_content_wrpr .vue-slider-rail {
  width: calc(100% - 16px);
  margin: 0 auto;
  background: transparent;
}

body .vue-slider-process {
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.vue-slider-dot {
  width: 16px !important;
  height: 16px !important;
  background-color: #fff;
  transition: none !important;
  border-radius: 100%;
  z-index: 99999 !important;
  cursor: pointer;
}

.vue-slider-dot:hover {
  background-color: #d7dcdd;
}

.vue-slider-dot:active {
  background-color: #d7dcdd;
}

.range-blue .vue-slider-dot {
  background-color: #249acf;
}

.range-blue .vue-slider-dot:hover {
  background-color: #1d7ea8;
}

.range-blue .vue-slider-dot:active {
  background-color: #1d7ea8;
}

.line-left,
.line-right {
  background-size: 222px;
  background-position: right;
}
.line-left {
  background-position: left;
}
.range-hue .line-left,
.range-hue .line-right {
  background-image: linear-gradient(
    to right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
}
.range-saturation .line-left,
.range-saturation .line-right {
  background-image: linear-gradient(to right, #727272 0%, #ff0000 100%);
}
.range-temperature .line-left,
.range-temperature .line-right {
  background-image: linear-gradient(to right, #1f8ded 0%, #f88d00 100%);
}
.range-tint .line-left,
.range-tint .line-right {
  background-image: linear-gradient(to right, #24933d 0%, #871d99 100%);
}
.range-vibrance .line-left,
.range-vibrance .line-right {
  background-image: linear-gradient(to right, #ffffff 0%, #fe0206 100%);
}
.quality_range .line-left,
.quality_range .line-right {
  background-image: linear-gradient(
    to right,
    #ff5353 35%,
    #fa9d1c 60%,
    #4adaba 100%
  );
  background-size: 356px;
}
.vue-slider-dot-handle {
  box-shadow: none;
  pointer-events: none;
  display: none;
}
</style>