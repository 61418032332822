import {loadStripe} from '@stripe/stripe-js/pure';
export default class {
	constructor(components={}){
		this.components = components;
		this.elements = false;
		this.initStripe();
	}
	
	
	async pay(cardName=""){
		
		var intent_secret = await this.makeIntent();
	
		var stripe_data = await this.stripe.confirmCardSetup(intent_secret, {
			payment_method: {
				card: this.cardNumber,
				billing_details: {
					name: cardName,
				},
			},
		});
		
		if(stripe_data.error){
			return {error: stripe_data.error.message};
		}
		var intent_data = {error: false, payment_method: stripe_data.setupIntent.payment_method, id: stripe_data.setupIntent.id, other: stripe_data.setupIntent};
		
		return intent_data;
	}
	
	async confirmCardPayment(){
		
	}
	
	async makePaymentMethod(){
		
	}
	
	async makeIntent(){
		var intent =  await this.sendAjax(ajax_urls.stripe.makeIntent, {});
		return intent.secret;
	}
	
	getStripe(){
		return this.stripe;
	}
	
	async initStripe(){
		this.stripe = await loadStripe(window.stripe_pub);
		this.elements = this.stripe.elements();


		//width: 100%;
		//padding: 15px;
		//display: block;
		//border: 1px solid #ECECEC;
		//border-radius: 5px;
		//color: #2a2a2a;
		//font-size: 16px;
		//font-family: inherit;
		
		var options = {
			style: {
				base: {
					padding: "15px",
					border: "1px solid #ECECEC",
					"border-radius": "5px",
					color: "#2a2a2a",
					"font-size": "16px"
					
				}
			}
		};
		
		
		
		this.cardNumber = this.elements.create('cardNumber', options);
		this.cardCvc = this.elements.create('cardCvc', options);
		this.cardExpiry = this.elements.create('cardExpiry', options);
		
		this.cardNumber.mount(this.components.cardNumber);
		this.cardExpiry.mount(this.components.cardExpiry);
		this.cardCvc.mount(this.components.cardCvc);
	}
	
	async sendAjax(url, data={}){
		try{
			var data = await $.ajax({
				url: url,
				data: {
					_token: window.ajax_urls.csrf,
					...data
				},
				method: "POST",
				dataType: "json",
			});
			return data;
		}catch(e){
			console.error("makeIntent", e);
			return false;
		}
		
	}
}
