<template>
    <div class="tool-item-panel" :class="sidebarPanelClass">
        <div class="panel-top mb-10 without_hover">
            <div class="back">
                <h3>Edit</h3>
            </div>
        </div>
        <div v-bar>
            <div class="panel-content wo_pad">
                <PanelAccordion
                        accordionTitle="Basics"
                        :accordActiveClass="true"
                        accordAdditionalClass="with-border"
                        accordBodyClass="pb-8"
                >
                    <template v-slot:panel_accordion_body>
                        <ul class="menu-edit">
                            <li v-for="item in editBasicsItem" v-bind:key="item.id">
                                <a href="#" v-on:click="toolLinkClick(item.linkText, item.premium)">
                                    <span class="inch-icon" :class="item.icon"></span>
                                    {{item.linkText}}
                                    <span v-if="item.premium && !isPremium"
                                          class="inch-icon inch-plus-membership-icon"></span>
                                </a>
                            </li>
                        </ul>
                    </template>
                </PanelAccordion>
                <PanelAccordion
                        accordionTitle="Enhance"
                        :accordActiveClass="true"
                        accordAdditionalClass="with-border"
                        accordBodyClass="pb-8"
                >
                    <template v-slot:panel_accordion_body>
                        <ul class="menu-edit">
                            <li v-for="item in editEnhanceItem" v-bind:key="item.id">
                                <a href="#" v-on:click="toolLinkClick(item.linkText, item.premium)"
                                   :style="item.iconSrc ? { 'padding-top': '12px', 'padding-bottom': '12px' } : {}">
                                    <span class="inch-icon" :class="item.icon" v-if="!item.iconSrc"></span>
                                    <span class="inch-icon" :class="item.icon" v-if="item.iconSrc">
	                  <SimpleSVG :src="require('@/assets/' + item.iconSrc)"/>
                  </span>
                                    {{item.linkText}}
                                    <span v-if="item.premium && !isPremium"
                                          class="inch-icon inch-plus-membership-icon"></span>
                                </a>
                            </li>
                        </ul>
                    </template>
                </PanelAccordion>
                <PanelAccordion accordionTitle="Creative" :accordActiveClass="true" accordBodyClass="pb-8">
                    <template v-slot:panel_accordion_body>
                        <ul class="menu-edit">
                            <li v-for="item in editOtherItem" v-bind:key="item.id">
                                <a href="#" v-on:click="toolLinkClick(item.linkText, item.premium)" :class="[(item.linkText == 'Remove Background' || item.linkText == 'Replace Color') ? 'custom-padding' : '']">
                                    <span class="inch-icon" :class="item.icon"></span>
                                    {{item.linkText}}
                                    <span v-if="item.linkText == 'Remove Background'" class="ai-content"><sup>AI</sup></span>
                                    <span v-if="item.premium && !isPremium"
                                          class="inch-icon inch-plus-membership-icon"></span>
                                </a>
                            </li>
                        </ul>
                    </template>
                </PanelAccordion>
                <AdLeftPanel v-if="isLeftAd" margin="0 14px"/>
            </div>
        </div>

        <transition name="panel-slide" v-on:after-enter="afterEnter">
            <EditCropPanel
                    v-if="editPanelOpened === 'Crop'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
            <EditResizePanel
                    v-if="editPanelOpened === 'Resize'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
            <EditRotatePanel
                    v-if="editPanelOpened === 'Rotate'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
            <EditExposurePanel
                    v-if="editPanelOpened === 'Exposure'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
            <EditColorPanel
                    v-if="editPanelOpened === 'Color'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
            <EditVibrancePanel
                    v-if="editPanelOpened === 'Vibrance'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
            <EditTintPanel
                    v-if="editPanelOpened === 'Tint'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
            <EditDetailsPanel
                    v-if="editPanelOpened === 'Details'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
            <RemoveBackgroundPanel
                    v-if="editPanelOpened === 'Remove Background'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
                    :editPanelOpened="editPanelOpened"
            />
            <ReplaceColorPanel
                    v-if="editPanelOpened === 'Replace Color'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />

            <EditVignettePanel
                    v-if="editPanelOpened === 'Vignette'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />

            <EditRoundPanel
                    v-if="editPanelOpened === 'Round'"
                    :editPanelPropChange="editPanelPropChange"
                    :editPanelClass="editPanelClass"
                    :applyChanges="applyChanges"
                    :closeCurrentEditor="closeCurrentEditor"
            />
        </transition>
    </div>
</template>

<script>
    import {SimpleSVG} from "vue-simple-svg";
    import PanelAccordion from "@/components/PanelAccordion.vue";
    import EditCropPanel from "@/components/sidebarPanel/editPanel/EditCropPanel.vue";
    import EditResizePanel from "@/components/sidebarPanel/editPanel/EditResizePanel.vue";
    import EditRotatePanel from "@/components/sidebarPanel/editPanel/EditRotatePanel.vue";
    import EditExposurePanel from "@/components/sidebarPanel/editPanel/EditExposurePanel.vue";
    import EditColorPanel from "@/components/sidebarPanel/editPanel/EditColorPanel.vue";
    import EditVibrancePanel from "@/components/sidebarPanel/editPanel/EditVibrancePanel.vue";
    import EditDetailsPanel from "@/components/sidebarPanel/editPanel/EditDetailsPanel.vue";
    import EditTintPanel from "@/components/sidebarPanel/editPanel/TintPanel.vue";
    import EditVignettePanel from "@/components/sidebarPanel/editPanel/EditVignettePanel.vue";
    import EditRoundPanel from "@/components/sidebarPanel/editPanel/EditRoundPanel.vue";
    import ReplaceColorPanel from "@/components/sidebarPanel/editPanel/ReplaceColorPanel.vue";
    import RemoveBackgroundPanel from "@/components/sidebarPanel/editPanel/RemoveBackgroundPanel.vue";
    import AdLeftPanel from "@/components/AdLeftPanel.vue";

    const analitics = require('../../../libs/analytics');

    export default {
        name: "EditPanel",
        props: {
            sidebarPanelClass: String
        },
        components: {
            SimpleSVG,
            PanelAccordion,
            EditCropPanel,
            EditResizePanel,
            EditRotatePanel,
            EditExposurePanel,
            EditColorPanel,
            EditVibrancePanel,
            EditTintPanel,
            EditDetailsPanel,
            EditVignettePanel,
            EditRoundPanel,
            ReplaceColorPanel,
            RemoveBackgroundPanel,
            AdLeftPanel
        },
        //<img :src='require("")'/>
        data() {
            return {
                editPanelOpened: "",
                editPanelClass: "",
                editBasicsItem: [
                    {
                        id: 1,
                        linkText: "Crop",
                        icon: "inch-crop-icon"
                    },
                    {
                        id: 2,
                        linkText: "Resize",
                        icon: "inch-resize-icon"
                        //premium: true
                    },
                    {
                        id: 3,
                        linkText: "Rotate",
                        icon: "inch-rotate-icon"
                    }
                ],
                editEnhanceItem: [
                    {
                        id: 1,
                        linkText: "Exposure",
                        icon: "inch-exposure-icon"
                    },
                    {
                        id: 2,
                        linkText: "Color",
                        icon: "inch-color-icon"
                    },
                    {
                        id: 3,
                        linkText: "Vibrance",
                        icon: "inch-vibrance-icon"
                    },
                    {
                        id: 4,
                        linkText: "Tint",
                        icon: 'svg_icon tint-icon-new'
                    },
                    {
                        id: 5,
                        linkText: "Details",
                        icon: "inch-details-icon"
                    }
                ],
                editOtherItem: [
                    {
                        id: 0,
                        linkText: "Remove Background",
                        icon: "svg_icon_remove_background remove-background-icon",
                        premium: true
                    },
                    {
                        id: 1,
                        linkText: "Replace Color",
                        icon: "svg_icon_replace_color replace-color-icon",
                        premium: true
                    },
                    {
                        id: 2,
                        linkText: "Vignette",
                        icon: "inch-vignette-icon"
                    },
                    {
                        id: 3,
                        linkText: "Round",
                        icon: "inch-round-icon"
                    }
                ]
            };
        },
        computed: {
            panelOpened() {
                return this.editPanelOpened;
            },
            isPremium() {
                return this.$store.state.isPremiumAccount;
            },
            hash() {
                const hash = this.$route.hash.split('#')[2]
                if(hash) return `${hash[0].toUpperCase()}${hash.substring(1)}`.replace('-',' ');
                return null;
            },
            isLeftAd() {
                return (!this.isPremium && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
            },
        },

        watch: {
            panelOpened(panel) {
                this.$store.state.selectedEditPanel = panel;
            },
        },
        mounted: function(){
            // if(window.location !== window.parent.location){
            //     console.log('removed BG for API')
            //     this.editOtherItem.splice(0,1);
            // }
            this.openPanelHash();
        },

        async beforeDestroy() {
            this.$store.state.selectedEditPanel = '';
            try {
                if (this.$lib.app.stage.children.length > 1) {
                    this.$lib.app.stage.removeChild(this.$lib.app.stage.children[1]);
                }
            } catch (err) {
            }

            await this.closeCurrentEditor();
            if(this.hash){
                this.$router.push({
                    hash: "",
                });
            }
        },

        methods: {
            openPanelHash(){
                if(!this.hash) return
                const selectedEditPanel = [...this.editBasicsItem,...this.editEnhanceItem,...this.editOtherItem].find(item => item.linkText === this.hash)
                if(!selectedEditPanel) return
                this.toolLinkClick(selectedEditPanel.linkText,selectedEditPanel.premium)
            },
            toolLinkClick(panelName, premiumStatus) {

                if (premiumStatus && !this.isPremium) {
                    this.$store.commit("changePremiumStatus", true);
                }
                if (this.editPanelOpened === panelName) {
                    this.editPanelOpened = "";
                    this.editPanelClass = "";
                } else {
                    this.editPanelOpened = panelName;
                    this.editPanelClass = "active";
                    analitics.sendEvent(panelName, analitics.categ.edit);
                }
            },

            applyChanges(/* lib */) {
                this.$store.dispatch("isUpdateCache");
                this.$store.dispatch("fabricSlide", false);
                this.editPanelOpened = "";
                this.editPanelClass = "";
            },

            async closeCurrentEditor(lib) {
                this.$store.dispatch("fabricSlide", false);
                this.editPanelOpened = "";
                this.editPanelClass = "";

                // lib.effects._setFilters(lib.app.stage.children[0].children[0]);
                // lib._cache.renderFromCacheAt(lib._cache.getCacheList().length - 1);

                await this.$lib._cache.renderSaveByTimestamp();
                this.$store.dispatch("isUpdateCache");

                // lib._cache.prev();
            },

            editPanelPropChange() {
                this.editPanelOpened = "";
                this.editPanelClass = "";
                this.$store.commit("changePremiumStatus", false);
            },

            afterEnter() {
                let temp = this.editPanelClass;
                this.editPanelClass = "";
                this.$nextTick(function () {
                    this.editPanelClass = temp;
                    console.log("UPD");
                });
            }
        }
    };
</script>
<style scoped>
.custom-padding{
  padding: 12px 24px !important;
}
</style>
<style>
.svg_icon_remove_background{
  width: 14px;
  height: 13px;
  display: inline-block;
}
.svg_icon_replace_color{
  width: 15px;
  height: 13px;
  display: inline-block;
}
    .inch-tint-icon {

    }

    .menu-edit a:hover svg path {
        stroke: white !important;

    }

    .ai-content {
      top: 583px;
      left: 251px;
      width: 8px;
      height: 9px;
      text-align: center;
      font-style: normal;
      font-variant: normal;
      font-weight: bold;
      font-size: 8px;
      font-family: Proxima Nova;
      letter-spacing: 0.32px;
      color: #4ADABA;
      opacity: 1;
    }


</style>