<template>
	<div class="tool-item-panel frms_cust_brdr">
	 <div class="panel-top" @click="panelPropChange">
		<div class="back">
			<a href="#" class="back-icon">
			 <span class="inch-icon inch-back-icon"></span>
			</a>

			<h3>Custom Text</h3>
		</div>
	 </div>

	 <div class="panel-content wo_pad wo_pad_wo_overfl" v-bar>
		<div class="text_panel_scroll">
			<div class="panel_row">
			 <DropdownSelectFont v-bind:class="{ disabled: isTextPanelDisabled }"
				textLabel="Font"
				@toggleDropdown="openDropdown"
				:selectClass="selectDropdown"
				@resetSelectClass="(selectDropdown = '')"
				@selectDropdown="selectDrop"		 
			 />
			</div>

			<div class="panel_row spc_btwn" v-bind:class="{ disabled: isTextPanelDisabled }">
			 <InputWidthHeight :inputValue="textOptions.fontSize"
				@postBack="updateTextStyle('fontSize', $event.value)"
				@change="updateTextStyle('fontSize', Number($event.value))"
			 />

			 <ColorInput
				:defColor="maskOptions.fillColor"
				@input="updateMaskStyle('color', $event)"
				@update="updateMaskStyle(`color`, $event.hex)"
				:checkmarkDisabled="true"
			 />
			</div>

			<div class="panel_row box_align p_left_right_0" v-bind:class="{ disabled: isTextPanelDisabled }">
			 <a href="#" class="btn-action" v-tooltip.top="'Bold'" @click="updateTextStyle('fontWeight', 'bold')">
				<span class="inch-icon inch-bold-icon"></span>
			 </a>

			 <a href="#" class="btn-action" v-tooltip.top="'Italic'" @click="updateTextStyle('fontStyle', 'italic')">
				<span class="inch-icon inch-italic-icon"></span>
			 </a>

			 <a href="#" class="btn-action" v-tooltip.top="'Underline'" @click="updateTextStyle('underline', !textOptions.underline)">
				<span class="inch-icon inch-underline-icon"></span>
			 </a>

			 <a href="#" class="btn-action" v-tooltip.top="'Align Left'" @click="updateTextStyle('textAlign', 'left')">
				<span class="inch-icon inch-text-align-left-icon"></span>
			 </a>

			 <a href="#" class="btn-action" v-tooltip.top="'Align Center'"	@click="updateTextStyle('textAlign', 'center')">
				<span class="inch-icon inch-text-align-center-icon"></span>
			 </a>

			 <a href="#" class="btn-action" v-tooltip.top="'Align Right'"	@click="updateTextStyle('textAlign', 'right')">
				<span class="inch-icon inch-text-align-right-icon"></span>
			 </a>
			</div>

			<div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
			 <Range
				rangeSliderId="LetterSpacing"
				rangeSliderLabel="Letter Spacing"
				rangeSliderClass="range-blue"
				:defaultValue="0"
				:minValue="0"
				:maxValue="100"
				@changeValue="updateTextStyle('charSpacing', Number($event * 10))"
				:rangeSliderValue="textOptions.charSpacing / 10"
			 />
			</div>

			<div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
			 <Range
				rangeSliderId="LineHeight"
				rangeSliderLabel="Line Height"
				rangeSliderClass="range-blue"
				:defaultValue="0"
				:minValue="0"
				:maxValue="100"
				@changeValue="setLineHeight(Number($event))"
				:rangeSliderValue="textOptions.someLineHeight"
			 />
			</div>

			<div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
			 <Range
				rangeSliderId="Rotation"
				rangeSliderLabel="Rotation"
				rangeSliderClass="range-blue"
				:defaultValue="0"
				:minValue="0"
				:maxValue="360"
				@changeValue="updateTextStyle('angle', Number($event))"
				:rangeSliderValue="textOptions.angle"
			 />
			</div>

			<div class="panel_row" v-bind:class="{ disabled: isTextPanelDisabled }">
			 <Range
				rangeSliderId="BackgroundOpacity"
				rangeSliderLabel="Background Opacity"
				rangeSliderClass="range-blue"
				:defaultValue="0"
				:rangeSliderValue="maskOptions.opacity"
				:minValue="0"
				:maxValue="100"
				@changeValue="updateMaskStyle('opacity', Number($event))"
			 />
			</div>
			
			<div class="panel_row margin_bottom_15" v-bind:class="{ disabled: isTextPanelDisabled }">
			 <Range
				rangeSliderId="BackgroundBlur"
				rangeSliderLabel="Background Blur"
				rangeSliderClass="range-blue"
				:defaultValue="0"
				:minValue="0"
				:rangeSliderValue="maskOptions.blur"
				:maxValue="100"
				@changeValue="updateMaskStyle('blur', Number($event))"
			 />
			</div>

			<div class="d-flex between two-column panel_row">
			 <div class="half">
				<a tabIndex="0" class="button btn-action-all btn-action cancel" @click="panelPropChange">cancel</a>
			 </div>

			 <div class="half">
				<a tabIndex="0" class="button btn-action-all btn-action upgrade" @click="applyChanges">apply</a>
			 </div>
			</div>
		</div>
	 </div>
	</div>
</template>

<script>
import Range from "@/components/Range.vue";
import ColorInput from "@/components/ColorInput.vue";
import Tips from "@/components/Tips.vue";
import DropdownSelectFont from "@/components/DropdownSelectFont.vue";
import InputWidthHeight from "@/components/InputWidthHeight";
import _ from 'lodash';
import LoaderMixin from '../../../mixin/LoaderMixin';

export default {
	name: "MaskTextPanel",
	components: {
	 Range, DropdownSelectFont,
	 ColorInput, Tips,
	 InputWidthHeight
	},

	mixins: [LoaderMixin],

	data() {
	 return {
		inter: false,
		textMask: {},
		selectedFont: null,
		selectDropdown: '',
		textOptions: {
			"fontFamily": "Open Sans",
			"fill": "#00000000",
			"cornerStyle": "textbox",
			"cornerColor": "#249acf",
			"cornerStrokeColor": "#ffffff",
			"borderColor": "#ffffff",
			"cornerSize": 13,
			fontStyle: 'normal',
			fontWeight: "bold",
			"borderDashArray": [2,4],
			textAlign: 'center',
			"rotatingPointOffset": 23,
			underline: false,
			"lockSkewingX": true,
			"lockSkewingY": true,
			"centeredScaling": true,
			"transparentCorners": false,
			"fontSize": parseInt(50 / _LIB.app.zoomProcentage),
			"charSpacing": 0,
			"lineHeight": 1.08,
			someLineHeight: 20,
			"objectCaching": false,
			originX: 'center',
			originY: 'center',
			angle: 0
		},

		maskOptions: {
			 "opacity": 80,
			 "color": "#ffffff",
			 "fillColor": "#ffffff",
			 "alpha": 0.8,
			 "blur": 0,
			 "x":0, "y":0,
			 "size": "100",
			 "mode": "default",
			 "rotation": 0,
			 lockAdaptive: true,
			 "position": "center-center",
			 "isFit": false,
			 "applyAs": "maskText",
			 isMask: true
		}
	 };
	},

	props: {
	 panelPropChange: {
		type: Function,
		default: () => {}
	 },
	},
	
	async mounted() {
	 window.tt = this;
	 this.$store.dispatch("preloader", { isShow: true, message: 'Loading...' });
	 await this.$store.dispatch("fontsList", {});
	 this.selectedFont = this.$store.state.fonts[0];
	 this.$emit("loadFontList");

	 fabric.util.clearFabricFontCache();
	 await this.initMaskText();

	 this.inter = setTimeout(() => {
		fabric.util.clearFabricFontCache();

		this.renderMask();
		_LIB.app.fabricCanvas.renderAll();
	 }, 1000);

	 this.$store.dispatch("preloader", { isShow: false });
	},

	async created() {
	 // await this.$store.dispatch("fontsList", {});
	 // this.selectedFont = this.$store.state.fonts[0];
	 // this.$emit("loadFontList");

	 // fabric.util.clearFabricFontCache();
	},

	beforeDestroy() {
		clearTimeout(this.inter);
		this.discardChanges();
		this.$lib.watermark.remove();

		this.$lib.app.fabricCanvas.getObjects().forEach(obj => {
			if(obj._maskText) this.$lib.app.fabricCanvas.remove(obj);
		});
	},

	watch: {
		textEvent(data) {
			this.setupTextActions(data);
		},

		zoom(val) {
			this.$nextTick(() => {
				if(val <= 100) {
					this.renderMask();
				}
			});
		}
	},

	methods: {
	 setupTextActions(config = {}) {
		if (config.action == "scaleY") {
			const fontSize = parseInt(this.textMask.fontSize * this.textMask.scaleY);

			this.textOptions.fontSize = fontSize;
		}
		else if (config.action == "rotate") {
      this.textOptions.angle = Math.round(config.target.angle);
    }
	 },

	 async selectDrop(font) {
		if (typeof font !== "undefined") {
			if (font.isPremium) {
			 const isHavePremium = await this.$store.dispatch("isPremiumCurrentUser");

			 if (!isHavePremium) {
				// if (window.abVariant && window.abVariant == 2) {
						this.$store.dispatch("popupsToggle", {
							property: "stripeCheckoutPopup",
							value: true,
						});
					// }
					// else{
					// 	this.$store.dispatch("popupsToggle", {
					// 		property: "upgradePopup",
					// 		value: true,
					// 	});
					// }
				return;
			 }
			}

			this.textOptions.fontFamily = font.title.replace(/[0-9]/g, "").trim();
			this.$store.dispatch("currentFont", this.textOptions.fontFamily);
			
			this.$lib.Text.setupMaskTextOptions(this.textOptions);
			this.renderMask();
		}

		this.selectDropdown = "";
	 },

	 openDropdown() {
		this.selectDropdown = "active";
	 },

	 setLineHeight(value) {
		value = Number(value);

		this.textOptions.lineHeight = this.$lib.lib.conv(value, 0.1, 5);
		this.textOptions.someLineHeight = value;
		let textOptions = JSON.parse(JSON.stringify(this.textOptions));
		textOptions.fontSize = parseInt(50 / _LIB.app.zoomProcentage);
		this.$lib.Text.setupMaskTextOptions(textOptions);

		this.renderMask();
	 },

	 updateMaskStyle(key, value) {
		this.maskOptions[key] = value;
		if(key == 'color') {
			this.maskOptions.fillColor = value;
		}

		this.renderMask();
	 },

	 updateTextStyle(key, value) {
		const checkBoxes = ['fontWeight', 'fontStyle'];
		if(checkBoxes.find(cb => cb == key)) {
			this.textOptions[key] = value == this.textOptions[key] ? 'normal' : value;
		} else {
			this.textOptions[key] = value;
		}

		let textOptions = JSON.parse(JSON.stringify(this.textOptions));
		textOptions.fontSize = parseInt(50 / _LIB.app.zoomProcentage);
		if (key == 'fontSize') {
      const fontSize = Number(value) / textOptions.fontSize;
      textOptions.scaleX = fontSize;
      textOptions.scaleY = fontSize;
    }

		this.$lib.Text.setupMaskTextOptions(textOptions);
		if(key === 'angle') {
      this.renderMaskR();
    } else {
      this.renderMask();
    }
	 },

	 async initMaskText() {
		try {
			//const width = (_LIB.app.fabricCanvas.width / _LIB.app.fabricCanvas.getZoom()) * .9;

			this.textMask = this.$lib.Text.addText("Double-click to edit text ", {
				...this.textOptions, fill: 'rgba(0,0,0,0)', _maskText: true,
				strokeWidth: 0,
				_isTextMask: true
			}, undefined, true);
			
			//this.toCenterText(this.textMask);
			fabric.util.clearFabricFontCache();
			// this.textMask.set({
			//	left: this.$lib.app.fabricCanvas.width / 2,
			//	top: this.$lib.app.fabricCanvas.height / 2
			// });
		
			this.textMask.on('moving', e => this.renderMask());
			this.textMask.on('scaling', e => this.renderMask());
			this.textMask.on('rotating', e => this.renderMaskR());
			this.textMask.on('skewing', e => this.renderMask());
			this.textMask.on('text:editing:entered_local', e => {
			 e.target.set('fill', '#000');
			 this.renderMask(true);
			});
			
			this.textMask.on('text:editing:exited_local', e => {
			 e.target.set('fill', 'rgba(0,0,0,0)');
			 this.renderMask();
			});

			this.$lib.app.fabricCanvas.backgroundImage = false;
			this.renderMask();

			//this.$lib.app.reRenderCanvas()
			this.renderMask();
		} catch (err) {
			console.error('ERROR: ', err);
		}
	 },

	 async applyChanges() {
		await this._preloader(true);

		await this.renderMaskApply(false, true);
		await this.$lib._cache.addToBgCacheUpdating(1332, { textMask: true }, true, { isThumb: true });
		
		await this.discardChanges();
		this.$store.dispatch("changesApplied");
		await this._preloader(false);
		this.panelPropChange();
	 },

	 async discardChanges() {
		if (this.$store.state.premiumStatus) {
			this.$store.commit("changePremiumStatus", false);
		}

		this.$lib.watermark.remove(true);

		this.selectedPreview = false;
		this.$lib.app.stage.filters = [];
		await this.$lib._cache.renderSaveByTimestamp();
		this.$store.dispatch("fabricSlide", false);
		this.$store.dispatch("isUpdateCache");
	 },

	 async renderMaskApply(clear = false, isApply = false) {
		const bt = await this.exportBufferFabric(this.textMask, this.$lib.app.fabricCanvas, clear, isApply);
		const sprite = new PIXI.Sprite(new PIXI.Texture(bt));

		///_LIB.TextureManager.addT(sprite.texture, 'renderMask-exportBufferFabric', true);
		 let bound = this.textMask.getBoundingRect(true, true);
		await this.applyMask(sprite, bound);
	 },

	 async applyMask(sprite, bound) {
		bound.right = bound.left+(bound.width);
		bound.bottom = bound.top+(bound.height);

		let diffH = (_LIB.app.stage.height/_LIB.app.zoomProcentage);
		let diffW = (_LIB.app.stage.width/_LIB.app.zoomProcentage);

		await this.$lib.Mask.setup({
			STexture: sprite, originalSprite: this.$lib.app.stage.children[0]
		}, {
			...this.maskOptions, color: this.maskOptions.fillColor,
			_textPosition: {
				left: bound.left/diffW,
				top: bound.top/diffH,
				width: bound.right/diffW,
				height: bound.bottom/diffH,
			},
			stage_w: _LIB.app.stage.width,
			stage_h: _LIB.app.stage.height,
		}, { updateId: 32 });
	 },

	 renderMask: _.debounce(function(clear = false, isApply = false){
	   this.renderMaskR(clear, isApply);
   }, 5),

    renderMaskR: async function(clear = false, isApply = false) {
      const bt = await this.exportBufferFabric(this.textMask, this.$lib.app.fabricCanvas, clear, isApply);
      const sprite = new PIXI.Sprite(new PIXI.Texture(bt));

      ///_LIB.TextureManager.addT(sprite.texture, 'renderMask-exportBufferFabric', true);
      window.zsprite = sprite;

      let bound = this.textMask.getBoundingRect(true, true);
      await this.applyMask(sprite, bound);
      setTimeout(() => this.$lib.app.reRenderCanvas(), 10);
    },

	 async exportCloneTextbox(object, isClear, isApply = false) {
		const clone = await new Promise(resolve => object.clone(data => resolve(data), ['left', 'top', 'width', 'height', 'scaleX', 'scaleY']));

		return this.positit(clone, isClear, isApply);
	 },

	 positit(clone, isClear, isApply) {
		const zoom = 1; // _LIB.app.zoomProcentage
		const padding = 0 * Math.max(clone.scaleX, clone.scaleY);
		const localZoom = isApply ? 1 : _LIB.app.zoomProcentage;

		const realWidth = (clone.width * clone.scaleX) * zoom;
		const realHeight = (clone.height * clone.scaleY) * zoom;

		const realLeft = (clone.left - realWidth / 2) * zoom;
		const realTop = (clone.top - realHeight / 2) * zoom;
		
		if(isClear) {
			const clearRect = new fabric.Rect({
			 left: padding / 2,
			 top: padding / 2,
			 width: 1, height: 1,
			 stroke: 0, strokeWidth: 0,
			 stroke: 'rgba(0,0,0,0)',
			 fill: 'rgba(0,0,0,0)',
			});

			const base64 = clearRect.toDataURL();
			return base64;
		}
		//debugger;
		// const rect = new fabric.Rect({
		// 	left: padding / 2,
		// 	top: padding / 2,
		// 	width: clone.width * zoom + padding,
		// 	height: clone.height * zoom + padding,
		// 	stroke: 'rgba(0,0,0,0)',
		// 	fill: 'rgba(0,0,0,0)',
		// 	strokeWidth: 0
		// });

		clone.set({
			 fill: isClear ? 'rgba(0, 0, 0, 0)' : 'red',
			 left: realWidth / 2 + (realLeft > 0 ? 0 : 0),
			 top: realHeight / 2 + (realTop > 0 ?	0: 0)
		});

		let bound = clone.getBoundingRect();

		// let width = (bound.left < 0 ? (bound.width + bound.left) : bound.width) * zoom;
		// let height = (bound.top < 0 ? (bound.height + bound.top) : bound.height) * zoom;


		const group = new fabric.Group([clone]);
		window.group1 = group;
	
		// var left = bound.left<0?bound.left*-1:0;
		// var top = bound.top<0?bound.top*-1:0;

		let zoom2 = _LIB.app.zoomProcentage;

		var stw = _LIB.app.stage.width/zoom2, sth = _LIB.app.stage.height/zoom2;
		bound.right = bound.left+(bound.width);
		bound.bottom = bound.top+(bound.height);

		var left_crop = (bound.left<0?Math.abs(bound.left):0),
			right_crop = (bound.right>stw?(bound.right-stw):0),
			top_crop = (bound.top<0?Math.abs(bound.top):0),
			bottom_crop = (bound.bottom>sth?(bound.bottom-sth):0);

		var exp_w = bound.width-(left_crop)-(right_crop),
			exp_h = bound.height-(top_crop)-(bottom_crop);

		let exp_params = {
			multiplier: localZoom,
			left: left_crop,
			top: top_crop,
			width: exp_w,
			height: exp_h,
		};
		const base64 = group.toDataURL(exp_params);
		

		return base64;
	 },

	 async exportBufferFabric(object, canvas, isClear = false, isApply = false) {
			// const left = object.left * -1;// < 0 ? 0 : object.left * -1;
			// const top = object.top * -1;// < 0 ? 0 : object.top * -1;	 
			// const clone = await new Promise(resolve => object.clone(data => resolve(data), ['left', 'top', 'width', 'height', 'scaleX', 'scaleY']));
			
			// const padding = (clone.fontSize * (Math.max(clone.scaleX, clone.scaleY)));
			// const width = clone.width * clone.scaleX + padding;
			// const height = clone.height * clone.scaleY + padding;

			// clone.set({
			//	fill: '#000000',
			//	left: (width / 2),
			//	top: (height / 2),
			// });

			// window.clone = clone;

			// const rect = new fabric.Rect({
			//		left: 0, top: 0,
			//		width, height, stroke: 0,
			//		fill: 'rgba(0, 0, 0, 0)',
			//		strokeWidth: 0
			// });

			// const bounds = clone.getBoundingRect();

			// const w = (bounds.width) + padding;
			// const h = bounds.height + padding;
			// const objects = [rect, ...(isClear ? [] : [clone])];
			// const group = new fabric.Group(objects, {
			//	left: (bounds.left) - padding / 2,
			//	top: (bounds.top) - padding / 2,
			//	width: w, height: h
			// });
			// //console.log('WTF: ', width, height);

			// // const scale = Math.min(600 / group.width, 600 / group.height);
			// // group.set({ scaleX: scale, scaleY: scale });
			// // console.log('SCALE: ', scale);

			// window.group = group;
			//const scaleTo = Math.min(400 / width, 400 / height);
			//group.set({ scaleX: scaleTo, scaleY: scaleTo });
			const base64 = await this.exportCloneTextbox(object, isClear, isApply);

			window.lastb64 = base64;


			let bt;
			await new Promise(resolve => {
			 bt = PIXI.BaseTexture.from(base64);
			 
			 if(bt.hasLoaded) return resolve();
			 bt.on('loaded', () => {
				resolve();
			 });
			});
			
			//bt.setRealSize(clone.width, clone.height);
			return bt;
			
			// const cv = group.toCanvasElement();
			// const ctx = cv.getContext('2d');
			
			// // const cv = clone.toCanvasElement();
			// // const ctx = cv.getContext('2d');

			// const data = ctx.getImageData(0, 0, w, h);
			// return PIXI.BaseTexture.fromBuffer(data.data, w, h);

			// //return bt;
			// if(window.qq) return;
			// //const scaleTo = Math.min(400 / width, 400 / height);
			
			// //group.set({ scaleX: scaleTo, scaleY: scaleTo });
			// const base64 = group.toDataURL();
			// window.base64 = base64;
			
			// //let bt;
			// await new Promise(resolve => {
			//	bt = PIXI.BaseTexture.from(base64);
			 
			//	if(bt.hasLoaded) return resolve();
			//	bt.on('loaded', () => {
			//		resolve();
			//	});
			// });

			// const bt = (await this.$lib.app.addToLoader('wee' + Date.now(), base64)).texture.baseTexture;
			// console.log('QQ: ', bt);
			
			//console.log('GENERATE TIME: ', `${(Date.now() - generateDate) / 1000}s.`);
			// return bt;
			// if(isDebug) {
			//		const blob = await fetch(base64).then(r => r.blob());
			//		window.open(URL.createObjectURL(blob));
			// }
			
			// const byteString = atob(base64.split(',')[1]);
			// const ab = new ArrayBuffer(byteString.length);
			// const ia = new Uint8Array(ab);
			
			// for (var i = 0; i < byteString.length; i++) {
			//		ia[i] = byteString.charCodeAt(i);
			// }

			// return ia;
	 }
	},

	computed: {
		zoom() {
			return this.$store.state.global.zoom;
		},

		textEvent() {
			return this.$store.state.textEvent;
		},

		fonts() {
			return this.$store.state.fonts;
		},

		updateTextOptions() {
			return { ...this.textOptions };
		},

		currentFont() {
			return this.$store.state.currentFont;
		},

		selectedLayer() {
			return this.$store.state.selectedLayer;
		},

		isTextPanelDisabled() {
			if(!this.selectedLayer) return true;

			return !this.selectedLayer._maskText;
		}
	}
};
</script>

<style scoped>
	.disabled {
	 opacity: .5;
	}
</style>
