var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.uploadImgShow)?_c('div',{staticClass:"upload_imgs_item"},[(_vm.uploadImgMenu)?_c('div',{staticClass:"hover_dropdown_list_close_block",on:{"click":_vm.uploadImgMenuHandle}}):_vm._e(),_c('ul',{staticClass:"hover_dropdown_list",class:{ active: _vm.uploadImgMenu },style:(_vm.popupStyle)},[_c('li',[_c('a',{on:{"cl1ick":function($event){_vm.popUpImgPropChange(), _vm.uploadImgMenuHandle()},"click":function($event){return _vm.addImageToProject(_vm.imgPath)}}},[_vm._v("Add to Project")])]),_c('li',{staticClass:"bordered-li"},[_c('a',{on:{"click":function($event){return _vm.newProject(_vm.imgPath, true)}}},[_vm._v("Start as New Project")])]),_c('li',[_c('a',{staticClass:"redlink",attrs:{"eid":_vm.eid},on:{"click":_vm.uploadImgDelete}},[_vm._v("Remove")])])]),_c('div',{staticClass:"thumb-wrap image radius-4 hover-overlay",class:{ active: _vm.uploadImgMenu },style:({
      background: `url('${_vm.imgThumb || _vm.imgPath}') rgba(0,0,0,0) center center / cover no-repeat`,
      width: '109px',
      height: '109px',
      'background-size': 'cover',
    }),attrs:{"data-image":_vm.imgPath,"draggable":"true"},on:{"dragstart":_vm.dragLayerStart,"click":_vm.uploadImgMenuHandle}},[_c('div',{staticClass:"thumb-icons hidden top-right thumb-z"},[_c('span',{staticClass:"inch-icon active inch-close-icon",attrs:{"eid":_vm.eid},on:{"click":_vm.uploadImgDelete}})]),_vm._m(0)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumb-icons hidden thumb-z drag-or-click"},[_c('span',{staticClass:"tooltip-block"},[_vm._v("Drag or Click")])])
}]

export { render, staticRenderFns }