<template>
  <div class="input_width_height_wrpr">
    <input
      type="text"
      :name="inputName"
      class="text_input input_width_height"
      :disabled="isDisabled"
      v-model="updValue"
      :data-test="testValue"
      :max="maxValue"
      @change="changeInputValue($event.target.value)"
      @input="postBack($event.target.value)"
      @blur="onBlur"
    />
    <div class="up-down-wrap" @mousedown="startDragInput">
      <button class="input_wh_up" @click="inputUp"></button>
      <button class="input_wh_down" @click="inputDown"></button>
    </div>
  </div>
</template>


<script>
export default {
  name: "InputWidthHeight",
  props: {
    type: String,
    inputValue: Number,
    maxValue: Number,
    isDisabled: Boolean,
    testValue: Number,
    inputName: String
  },
  data: function() {
    return {
      clientY: 0
    };
  },
  computed: {
    updValue: {
      get() {
        return this.inputValue;
      },
      set(upd) {
        return upd;
      }
    }
  },
  methods: {
    changeInputValue(value) {
      this.$emit("change", { name: this.inputName, value });
    },

    postBack: function(newValue) {
      //console.log(newValue);
      this.$emit("update", { name: this.inputName, value: newValue });
    },

    inputUp: function() {
      this.$emit("postBack", { name: this.inputName, value: this.updValue + 1});
    },

    inputDown: function() {
      this.$emit("postBack", { name: this.inputName, value: this.updValue - 1 });
    },

    startDragInput: function(e) {
      e.preventDefault();
      document.onmouseup = this.stopDragInput;
      document.onmousemove = this.dragInput;
    },

    dragInput: function(e) {
      e.preventDefault();
      if (this.clientY < e.clientY) {
        this.inputDown();
      } else {
        this.inputUp();
      }
      this.clientY = e.clientY;
    },

    onBlur() {
      this.$emit('onBlur', { name: this.inputName, value: this.inputValue });
    },

    stopDragInput: function() {
      document.onmouseup = null;
      document.onmousemove = null;

      this.$emit('onStopDragInput', { name: this.inputName, value: this.inputValue });
    }
  }
};
</script>

<style>
.up-down-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 25px;
  cursor: row-resize;
}
</style>