<template>
    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="closeIt">
          <div class="back">
            <a href="#" class="back-icon">
              <span class="inch-icon inch-back-icon"></span>
            </a>
            <h3>Remove Background <span class="ai-content"><sup>AI</sup></span></h3>
          </div>
          <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)">
            <img :src="helpIcon" alt="" />
          </div>
        </div>

        <div v-bar>
            <div class="panel-content wo_pad">
                <div class="panel_row">
                    <a class="blue_btn full_w_btn open_popup_btn" @click="removeBackgroundHandler">Remove Background</a>
                </div>

                <!-- Color Picker -->
                <div class="panel_row" v-bind:class="{ disabled: settingsPanel }">

<!--                    <ColorInput-->
<!--                            textLabel="Color"-->
<!--                            :defColor="uniforms.from || '#ffffff'"-->
<!--                            :suckerHide="false" :checkmarkDisabled="true"-->
<!--                            :isFixed="true" :class="{ hiidddde: !uniforms.from }"-->
<!--                            @input="changeUniformValue('from', $event)"-->
<!--                            :suckerPixels="suckerPixels"-->
<!--                    />-->
<!--                    <ColorInput-->
<!--                            textLabel="Color"-->
<!--                            :defColor="'#000000'"-->
<!--                            :changeColorValue="changeUniformValue"-->
<!--                            :checkmarkDisabled="true"-->
<!--                    />-->
                    <ColorInput
                            :key="updateColor"
                            :defColor="color"
                            textLabel="Background Color"
                            @input="setColor"
                            @updateCheckedColor="updateCheckedColor"
                            :suckerHide="true"
                            :checkmarkDisabled="false"
                            :checkColor="checkmark"
                            :isFixed="true"
                    />

                </div>



                <div class="panel_row" v-bind:class="{ disabled: settingsPanel }">
                    <RangeLib
                            rangeSliderId="editOpacity"
                            :rangeSliderValue="alpha"
                            rangeSliderMeasure="%"
                            rangeSliderLabel="Opacity"
                            rangeSliderClass="range-blue"
                            :minValue="0"
                            :defaultValue="1"
                            :maxValue="100"
                            @update="setAlpha"
                    />
                </div>

                <div class="d-flex between two-column panel_row" style="padding-top: 36px !important;">
                    <div class="half">
                        <a tabIndex="0" class="button btn-action cancel" v-on:click="closeIt">cancel</a>
                    </div>

                    <div class="half">
                        <a tabIndex="0" class="button btn-action upgrade" v-on:click="applyIt">{{ !isPremium ? 'upgrade' : 'apply'}}</a>
                    </div>
                </div>
                <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
            </div>
        </div>

        <div class="pop_up_closer" v-if="getPopupStatus" @click="popUpPropChange('popupsToggleStatus', false)"></div>
        <Tips v-if="getPopupStatus" :text="tipsText" :subText="tipsSubText">
            <template v-slot:body>

                <div :class="{'bottom-line': !isPremium}">
                    <div class="tooltip_row_item">
                        <p class="simple_light_p with_icon">
                            <span class="inch-icon"><img :src="allColorIcon" alt="" /></span>
                            Preserve transparency or fill background with a solid color
                        </p>
                    </div>
                    <div class="tooltip_row_item">
                        <p class="simple_light_p with_icon">
                            <span class="inch-icon"><img :src="sliderIcon" alt="" /></span>
                          <i>Opacity</i> adjusts the transparency of the solid background color (if applied)
                        </p>
                    </div>
                </div>

                <div v-if="!isPremium" class="tooltip_row_item top-padding">
                    <p class="simple_light_p with_icon">
                        <span class="inch-icon"><img :src="membershipIcon" alt="" /></span>
                        Indicates the tool is a Colorcinch Plus feature. Get <a class="blue-color-text" v-on:click="upgradePlan()">Colorcinch Plus</a>
                    </p>
                </div>
            </template>
        </Tips>

    </div>
</template>

<script>
    import RangeLib from '@/components/RangeLib.vue';
    import Tips from '@/components/Tips.vue';
    import ColorInput from '@/components/ColorInput.vue';

    import MixinPopupBrush from '@/mixin/MixinPopupBrush';
    import EarseToolMixin from "@/mixin/EarseToolMixin";
    import LoaderMixin from '@/mixin/LoaderMixin';
    import AdLeftPanel from "@/components/AdLeftPanel.vue";
    // import axios from 'axios';
    import {fs} from 'fs';


    export default {
        name: 'RemoveBackgroundPanel',
        mixins: [MixinPopupBrush, EarseToolMixin, LoaderMixin],
        components: { RangeLib, Tips, ColorInput, AdLeftPanel},

        props: {
            editPanelClass: String,
            editPanelPropChange: Function,
            closeCurrentEditor: Function,
            applyChanges: Function,
            editPanelOpened: String
        },

        data() {
            return {
                tipsText: 'Powered by AI, the Background Removal tool will auto-detect and remove the background from your image in one click.',
                tipsSubText: 'Be sure to export your image as a <i>PNG</i> file to preserve its transparent background.',
                helpIcon: require("@/assets/img/help-icon.svg"),
                sliderIcon: require("@/assets/images/slider.svg"),
                allColorIcon: require("@/assets/images/all_colors.png"),
                membershipIcon: require("@/assets/images/Plus-Membership-Icon.svg"),
                suckerHide: true,
                colorPickers: {
                    from: false,
                    to: false
                },

                uniforms: {
                    from: false,
                    to: false,
                    range: 100
                },

                _isCurrentMixin: true,
                pickerKey: 1,
                suckerPixels: false,
                settingsPanel: true,
                color: '',
                updateColor: 0,
                alpha: 100,
                base64: '',
                checkmark: false,

            };
        },

        created() {
          console.log("Created");
            window._isCurrentMixin = true;
        },

        mounted() {
            // _LIB.app.createSmallImage();


            this.updatePixels();
            this.updateGlobalEarseConfig();
            this.selectPointerEvents(true);
            this.changeConfig({ key: 'brushSize', value: this.eraseData.brushSize });

            this.$store.dispatch("fabricSlide", false);

            //this.$store.commit("changePremiumStatus", false);
            window.tt = this;
        },

        async beforeDestroy() {
            this.closeIt();
            // await this.closeCurrentEditor();
            // window._isCurrentMixin = false;
        },

        methods: {
            upgradePlan() {
                // if (window.abVariant && window.abVariant == 2) {
                    this.$store.dispatch("popupsToggle", {
                        property: "stripeCheckoutPopup",
                        value: true,
                    });
                // }
                // else{
                //     this.$store.dispatch("popupsToggle", {
                //         property: "upgradePopup",
                //         value: true,
                //     });
                // }
            },
            updatePixels() {
                const region = new PIXI.Rectangle(0, 0, _LIB.app.stage.width, _LIB.app.stage.height);
                const texture = _LIB.app.renderer.generateTexture(_LIB.app.stage, 1, 1, region);

                this.suckerPixels = _LIB.app.renderer.extract.pixels(new PIXI.Sprite(texture));
                texture.destroy(true);
            },


            async removeBackgroundHandler(){
                if(!this.isPremium) {
                    return this.$store.dispatch("popupsToggle", {
                        property: "backgroundRemoverPopup", value: true,
                    });
                }
                if(this.$lib.app.stage.children.length >= 2) return
                this.$store.dispatch("preloader", { message: "Removing background...", isShow: true });
                this.$store.dispatch("fabricSlide", true);
                if(this.api === 'pixcleaner.com'){
                    this.pixcleanerAPI()
                } else {
                    this.removebgAPI()
                }
            },
            async removebgAPI(){
                const base64Image = await this.$lib.app.extractStage('base64', { format: 'image/jpeg' });
                const formData = new FormData();
                formData.append('size', 'auto');
                formData.append('image_file_b64', base64Image);


                await fetch('https://api.remove.bg/v1.0/removebg', {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'X-Api-Key': 'G7vEUw7LgdUojQL8yxifULmr',
                    },
                }).then((response) => {
                    return response.arrayBuffer();
                }).then((data) => {
                    let arrayBufferView = new Uint8Array(data);
                    let blob = new Blob([arrayBufferView], {type: "image/png"});
                    let reader = new FileReader();
                    reader.onloadend = async (e) => {
                        let base64String = e.target.result;

                        await this.$lib.effects.removeBackground(base64String);
                        this.base64 = base64String

                        this.settingsPanel = !this.settingsPanel
                        this.$store.dispatch("isUpdateCache");
                        this.$store.dispatch("preloader", { message: "Removing background...", isShow: false });
                        // console.log(base64String.substr(base64String.indexOf(', ') + 1));
                    }
                    reader.readAsDataURL(blob);
                }).catch(err => {
                        console.error(err)
                        this.$store.dispatch("preloader", { message: "Removing background...", isShow: false });
                    });
            },
            async pixcleanerAPI(){
                const base64Image = await this.$lib.app.extractStage('base64', { format: 'image/jpeg' });

                const data = {
                    "imageBase64": base64Image.replace('data:image/jpeg;base64,','')
                }

                await fetch('https://api.pixcleaner.com/v2/autoremove', {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {"Content-Type": "application/json", 'X-API-KEY': 'YJEKS5K-ET0MMKB-KYKRN1X-GP3QHQH'}
                })
                    .then(response => response.json())
                    .then(async json => {
                        const resultImageUrl = json.resultImage.url
                        const base64String = await this.$lib.app.getBase64Image(resultImageUrl)
                        await this.$lib.effects.removeBackground(base64String);
                        this.base64 = base64String

                        this.settingsPanel = !this.settingsPanel
                        this.$store.dispatch("isUpdateCache");
                        this.$store.dispatch("preloader", { message: "Removing background...", isShow: false });

                    }).catch(err => {
                        console.error(err)
                        this.$store.dispatch("preloader", { message: "Removing background...", isShow: false });
                    });
            },
            updateCheckedColor(value) {
                this.checkmark = value
                if(!value) {
                    this.color = ''
                    if(this.$lib.app.stage.children[1].children[1]._name == 'coloredSpace'){
                        this.$lib.app.stage.children[1].removeChildAt(1)
                        this.$lib.app.reRenderCanvas();
                    }
                }
            },
            setColor(val) {
                if(this.color === val || !this.checkmark) return
                this.color = val;

                if(this.$lib.app.stage.children[1].children[1]._name == 'coloredSpace'){
                    this.$lib.app.stage.children[1].children[1].tint = val.replace('#','0x');
                } else {
                    let whiteSprite = new PIXI.Sprite(PIXI.Texture.WHITE);
                    whiteSprite.tint = val.replace('#','0x');
                    whiteSprite._name = 'coloredSpace';
                    whiteSprite.alpha = this.alpha/100;
                    whiteSprite.x = 0;
                    whiteSprite.y = 0;
                    whiteSprite.width = this.$lib.app.stage.width;
                    whiteSprite.height = this.$lib.app.stage.height;
                    // this.$lib.app.stage.children[1].removeChildAt(0)
                    this.$lib.app.stage.children[1].addChildAt(whiteSprite,1)
                }

                this.$lib.app.reRenderCanvas();
            },
            setAlpha(value) {
                this.alpha = value;
                if(this.$lib.app.stage.children[1].children[1]._name == 'coloredSpace'){
                    this.$lib.app.stage.children[1].children[1].alpha = value/100
                    this.$lib.app.reRenderCanvas();
                }
            },


            closeIt() {
                this.lzDestroy();
                this.$lib.Drawing.initDraw(false);

                this.closeCurrentEditor();
                window._isCurrentMixin = false;
                this.$store.commit("changePremiumStatus", false);
                this.$store.dispatch("fabricSlide", false);
            },

            async applyIt() {
                if(!this.isPremium){
                    return this.$store.dispatch("popupsToggle", {
                        property: "backgroundRemoverPopup", value: true,
                    });
                }

                if(this.$lib.app.stage.children.length === 1){
                    return this.closeIt();
                }

                this.$store.dispatch("preloader", {
                    message: "Wait...",
                    isShow: true,
                });
                await this.setBG({ isApply: true },this.base64);

                this.$store.dispatch("preloader", {});
                this.$store.dispatch("fabricSlide", false);
                this.$store.dispatch("changesApplied");
                this.closeIt();
            },
            popUpPropChange(property, value) {
                 this.$store.dispatch('popupsToggleStatus', { property, value });
            },
            async setBG(params){
                await this.$lib.effects.setBG(params, this.base64);
            }
        },

        watch: {
            currentZoomPosition() {
                this.updatePixels();
            },
            async _applySelectedEffect() {
                try {
                    if(this.editPanelOpened === "Remove Background"){
                        await this.applyIt();
                    }
                } catch (err) {}
                this.$store.state._callbackSave += 1;
            },
        },

        computed: {
            _applySelectedEffect() {
                return this.$store.state._applySelectedEffect;
            },
            isLeftAd() {
                return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
            },
            getPopupStatus() {
                return this.$store.state.global.popupStatus.value;
            },
            isPremium() {
                return this.$store.state.isPremiumAccount;
            },

            suckerCanvas() {
                return document.getElementById('canvasDashboard');
            },

            currentZoomPosition() {
                return this.$store.state.currentZoomPosition;
            },
            api() {
                return this.$store.state.INFO?.remove_bg_API ?? 'pixcleaner.com';
            },
        }
    }

</script>
<style scoped src="@/assets/css/popupStyles.css"></style>

<style scoped>
    .bottom-line{
        padding-bottom: 18px;
        border-bottom: 1px solid #303030;
    }
    .top-padding{
        padding-top: 18px;
    }
    .blue-color-text{
        color: #249ACF;
    }
    .color-range {
        display: flex;
        justify-content: space-between;
    }

    .color-range > div {
        /* flex: 1; */
        text-align: left;
        font: normal normal normal 14px/17px Proxima Nova;
        letter-spacing: 0px;
        color: #8C8C8C;
        position: relative;
    }

    .color-replace-input {
        border: 1px solid #3F3F3F;
        border-radius: 4px;
        height: 36px;
        width: 36px;
        margin-top: 7px;
        display: flex;
        justify-content: space-between;
    }

    .color-picker-replace {
        width: 36px;
        height: 20px;
        background: #303030 0% 0% no-repeat padding-box;
        border-radius: 4px;
        margin: 8px 0 0 8px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color-picker-replace > div {
        width: 100%;
        height: 100%;
    //background: #ff0000;
        overflow: hidden;
        justify-content: center;
    }

    .color-replace-input img {
        width: 16px;
        height: 16px;
        margin: 8px;
    }

    .color-replace-input > * {
        cursor: pointer;
    }

    .color-replace-input.t::after {
        position: absolute;
        content: "\e90d";
        color: #8c8c8c;
        right: -7px;
        top: 42px;
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 3.5px;
        height: 6px;
        transform: rotate(270deg) scale(.30);
    }

</style>

<style>
    .hide_picker .color_picker_component {
        display: none;
    }

    .color-ln {
        pointer-events: none;
    }
</style>
