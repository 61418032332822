<template>
	<div class="popup save_popup upgrade-popup sign-up">

		<a class="close_popup_btn" v-on:click="changePopup('signUp', false)">
			<span class="inch-icon inch-close-icon"></span>
		</a>
		<h2 class="title sm sign-up">Sign up - it's free</h2>

		<div class="signup-description">
			To download your image, create a free account.
		</div>
		<form id="login_form" v-on:submit.prevent="onSubmit" v-if="isOnline">
			<div class="form_loading" v-if="isLoading"></div>

			<div class="form-group sign-up">
				<div class="label sign-up">Email</div>
				<input type="email" ref="xemail" placeholder="Email address" name="email" class="form-control xemail sign-up" :value="email" />
			</div>
			<div class="form-group sign-up">
				<div class="label sign-up">Password</div>
				<input type="password" ref="xpassword" placeholder="Password" name="password" class="form-control sign-up" :value="password" />
			</div>
			<div class="form-group sign-up">
				<div class="label sign-up">Full Name</div>
				<input type="text" ref="xname" placeholder="Full name" name="full_name" class="form-control sign-up" :value="name" />
			</div>
			<div class="form_errors" v-if="formErrors">
				<ul id="example-1">
					<li v-for="item in formErrors">{{ item[0] }}</li>
				</ul>
			</div>

			<div class="btn-wrap sign-up" style="">
				<input type="submit" value="Sign Up" class="btn-orange" style="padding: 15px 40px; width: 100%"/>
			</div>

			<div class="bottom-info sign-up">
				<span >Already a member? </span>
				<a @click="showLoginPopUp"  style="margin: 0;text-transform:none;opacity:1">Sign in</a>
			</div>
		</form>
	</div>
</template>


<script>
import Acc from "@/libs/acc.js";
import Offline from "@/components/Offline.vue";
const analitics = require("../libs/analytics");

//client@mail.com
//123123123

export default {
	name: "SignUp",
	data() {
		return {
			isLoading: false,
			acc: new Acc(),
      formErrors: null,
      email: "",
      password: "",
      name: ""
		};
	},
	components:{
		Offline
	},
	methods: {
		async onSubmit(ev) {
			this.isLoading = true;
			this.formErrors = null;
			var resp = await this.acc.register({
				email: this.$refs.xemail.value,
				password: this.$refs.xpassword.value,
				name: this.$refs.xname.value,
			});
			console.log("after register");

			this.formErrors = resp.errors;
			this.isLoading = false;

			if (resp.success) {
				console.log("resp.success", resp.success);
				analitics.sendCustomEvent("sign_up");
				this.$store.state.USER = resp.data.user;
				if(resp.data.user) {
					window.sendinblue.track('signup',{ singupType:'free'});
					window.sendinblue.identify(resp.data.user.email, {'FIRSTNAME': resp.data.user.name, 'STATUS': resp.data.user.status});
					$('meta[name=uniq_id]').attr('content', resp.data.user.id);
				}

				this.changePopup("signUp", false);
			}
		},
		changePopup(property, value) {
			this.$store.dispatch("popupsToggle", { property, value });
		},
		showLoginPopUp() {
			this.closeAllPopups();
			this.$store.dispatch("popupsToggle", {
				property: "signIn",
				value: true,
			});
		},
		closeAllPopups() {
			this.$store.dispatch("popupsToggle", {
				property: "signUp",
				value: false,
			});
		},
	},
};
</script>

<style scoped>
.form_loading {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #8080809c;
	z-index: 10000;
}

.btn-orange {
	border: none;
}
.form_errors {
	/*color: red;*/
	/*font-weight: bold;*/
	text-align: left;
	font: normal normal normal 14px/34px Soleil;
	color: #FF5353;
}
.popup.save_popup.upgrade-popup.sign-up .title{
	font-size: 26px;
}

.form-group .label {
	font-size: 13px !important;
}

.popup.save_popup.upgrade-popup .btn-wrap a {
    margin-bottom: 12px;
    display: inline-block;
    text-transform: uppercase;
    /*color: #1d1d1d;*/
	font: normal normal normal 14px/18px Soleil;
	letter-spacing: -0.28px;
	color: #FFFFFF;
}

.popup.save_popup.upgrade-popup .bottom-info.sign-up {
	display: inline-block !important;
	text-align: left;
	font: normal normal normal 14px/18px Soleil;
	letter-spacing: -0.28px;
	color: #000000;

}
.popup.save_popup.upgrade-popup .bottom-info.sign-up {
	margin-top: 16px;

}
.popup.save_popup.upgrade-popup .bottom-info.sign-up span {
	opacity: 0.4;
}
.popup.save_popup.upgrade-popup .bottom-info.sign-up a {
	/*margin-bottom: 12px;*/
	display: inline-block;
	text-transform: none;
	color: #FA6400 !important;
	text-align: left;
	text-decoration: underline;
	letter-spacing: -0.28px;
	margin: 0;
}
.signup-description{
	text-align: center;
	font: normal normal normal 16px/24px Soleil;
	letter-spacing: -0.32px;
	color: #000000;
	opacity: 0.4;
	padding: 16px 5px 20px;
}

</style>
