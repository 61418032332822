<template>
    <div class="popup save_popup saf_popup" v-if="savePopupShow">
        <div class="popup_header">
            <h3>suggest a feature</h3>
            <a class="close_popup_btn" v-on:click="changePopup('suggestFeature', false)">
                <span class="inch-icon inch-close-icon"></span>
            </a>
        </div>
        <div class="popup_body" v-if="isOffline" style="padding: 20px;">
			<div class="is_offline">
				<Offline></Offline>
			</div>        
        </div>
        <div class="popup_body" v-if="isOnline">
            <div class="popup_body_row_item saf_textarea_wrpr">
                <div class="input_text_wrpr">
                    <textarea :placeholder="textareaPlaceholder" :maxlength="max" v-model="text" autofocus
                              v-on:input="checkInputChange"></textarea>
                    <span class="input_empty_error" v-bind:class="{ active: inputEmptyError }">No comment?</span>
                </div>
                <span class="textarea_max_charts">
                    <span class="max_chart" v-text="(max - text.length)"></span> / 160
                </span>
            </div>
            <div class="popup_body_row_item" v-if="!this.$store.state.USER">
                <input type="email" name="email" required class="text_input" autocomplete="off" placeholder="Enter your email please">
            </div>
            <div class="popup_body_row_item">
                <ToggleCheck
                        @toggleCheckHandle="toggleChecked()"
                        toggleCheckLabel="Include Screenshot"
                />
            </div>
            <div class="popup_body_row_item save_popup_text_row">
                <p class="simple_light_p">
                    We love hearing from our users and always look for ways to improve our product to best suit our users' needs.
                    <!-- {{ suggestFeatureText }} -->
                </p>
            </div>
            <div class="popup_body_row_item">
                <div class="btns-group mt0">
                    <a class="grey_btn" v-on:click="changePopup('suggestFeature', false)">Cancel</a>
                    <a class="blue_btn" v-on:click="checkInputEnter(text)">Send</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ToggleCheck from "@/components/ToggleCheck.vue";
    import Offline from "@/components/Offline.vue";

    export default {
        components: {
            ToggleCheck,
            Offline
        },
        name: "SuggestFeature",
        props: {
            suggestFeatureText: String,
            sendingFeedback: Function
        },
        methods: {
            changePopup(property, value) {
                this.$store.dispatch("popupsToggle", {property, value});
            },
            toggleChecked() {
                return (this.toggleCheckChecked = !this.toggleCheckChecked);
            },
            async checkInputEnter(value) {
                if (value.length) {
                   	var email = "";
                	if(document.getElementsByName('email').length>0){
		                email = document.getElementsByName('email')[0].value
		                if (!email) {
		                    document.getElementsByName('email')[0].classList.add('error')
		                    return;
		                }
                    }else{
                    }

                    this.sendingFeedback('Sending feedback...', 'Feedback sent', 1500, 'without_bg');
                    this.savePopupShow = false;

                    var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                    var fd = new FormData();
                    fd.append('message', value);
                    fd.append('email', email || "");
                    fd.append("user_id", document.head.querySelector("[name=uniq_id][content]").content);

                    let response = await fetch(`${this.$store.state.prefixURL}/suggest-feature`, {
                        body: fd,
                        method: "POST",
                        headers: {'X-CSRF-TOKEN': token}
                    })

                    let result = await response.json();

                    if (result.success) {
                        this.changePopup('suggestFeature', false);
                        this.savePopupShow = true;
                    }
                } else {
                    this.textareaPlaceholder = '';
                    this.inputEmptyError = true;

                    let input = document.querySelector('[autofocus]');
                    input.focus();
                }
            },
            checkInputChange() {
                if (this.text.length) {
                    this.textareaPlaceholder = 'Describe your request or share your ideas...';
                    this.inputEmptyError = false;
                }
            }
        },
        data: function () {
            return {
                toggleCheckChecked: true,
                max: 160,
                text: '',
                inputEmptyError: false,
                textareaPlaceholder: "Describe your request or share your ideas...",
                savePopupShow: true
            };
        },
        mounted() {
            let input = document.querySelector('[autofocus]');
            if (input) {
                input.focus();
            }
        }
    };
</script>

<style>
    .error::-webkit-input-placeholder {
        color: #ff5353 !important;
    }
</style>
