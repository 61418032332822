export default class{
	constructor(store, that){
		this.parent = that;
		this.button = false;
		this.inited = false;
		this.subscribe_exist = false;
	}
	async buyPlan(plan_id="monthly_subscription", return_value=false){
		let purchaseToken = false;
		let payerData = {
			name: null,
		};
		
		if(this.subscribe_exist){
			purchaseToken =  this.subscribe_exist.purchaseToken;
		}else{
			const paymentMethodData = [{
				supportedMethods: 'https://play.google.com/billing',
				data: {
					sku: this.plan_id,
				},
				requestPayerName: true,
				requestPayerEmail: true,
			}];

			let paymentComplete = false;
			const request = new PaymentRequest(paymentMethodData);
			const paymentResponse = await request.show();
			this.paymentResponse = paymentResponse;
			purchaseToken = paymentResponse.details.purchaseToken;

			payerData = {
				name: paymentResponse.payerName,
			};
			
		}
		if(return_value){
			return {detail: {token: purchaseToken, promocode: false, payerData: payerData }};
		}else{
			const event = new CustomEvent('android_pay_success', {detail: {token: purchaseToken, promocode: false, 
				payerData: payerData }});
			document.dispatchEvent(event);
			console.log("after dispatch");
		}
	}
	
	async checkProducts(){
		let twa_products = []; 
		if('getDigitalGoodsService' in window){
			const service = await window.getDigitalGoodsService('https://play.google.com/billing');
			if(service){
				let itemDetails = await service.getDetails(['yearly_subscription', 'monthly_subscription']);
				
				return;
				console.log("itemDetails", itemDetails);
				itemDetails.catch((e)=>{ alert("Payment error #4") });
				itemDetails = await itemDetails;
				for(const item of itemDetails){
					let localePrice = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: item.price.currency }).format(item.price.value);
				}
				
			}else{
				console.log('Google Play Billing is not available');
				alert('Google Play Billing is not available');
			}
		}else{
			
		}
		return true;
	}
	
	async getService(){
		try{
			if('getDigitalGoodsService' in window){
				const service = await window.getDigitalGoodsService('https://play.google.com/billing');
				if(service===null){
					return false;
				}
				return service;
			}
			return false;
		}catch(e){
			return false;
		}
	}
	
	async checkExistSubscribtion(){
		let service = await this.getService();
		console.log("service", service);
		if(service){
			let active_subs = await service.listPurchases();
			console.log("active_subs", active_subs);
			if(active_subs.length>0){
				return active_subs[0];
			}
			return false;
		}
		
		return false;
	}
	
	
	setPlanID(plan_id="monthly_subscription"){
		this.plan_id = plan_id;
	}

	async init(button, plan_id=0){
		if(this.inited){
			return;
		}
		
		this.inited = true;
		
		this.plan_id = plan_id;
		this.button = button;
		console.log("button is ", this.button);

		$(document).on("click", "#androidPayButton", (e)=>{
			e.preventDefault();
			this.inited = true;
			
			if(!this.parent.agree_terms){
				this.parent.formErrors.push("You must agree to the Colorcinch Privacy Policy and Terms");
				return false;
			}
			if('getDigitalGoodsService' in window){
				this.plan_id = this.parent.selectedPlanAndroid;
				try{
					this.buyPlan(this.plan_id);
				}catch(e){
					console.error(e);
				}
			}else{
				alert('Google Play Billing is not available');
				return false;
			}
			

		});
	}

	async sendAjax(url, data={}, method="POST"){
		data._token = ajax_urls.csrf;
		try{
			var resp = await $.ajax({
				method: method,
				url: url,
				data: data,
				dataType: "json",
			});
			return resp.data ? resp.data : resp;
		}catch(e){
			return false;
		}
	}

}
