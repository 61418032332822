<template>
            <div class="popup_wrpr active" v-if="show">
                <div class="plus_watermark notifiers box_style">
                    <div class="wrap_title">
                        <div class="icon_wrpr">
                            <transition name="fade">
                                <CircleLoading/>
                            </transition>
                        </div>
                        <transition name="fade" mode="out-in">
                            <div class="title">{{ message }}</div>
                        </transition>
                    </div>
                </div>
            </div>
</template>

<script>
	import CircleLoading from "@/assets/img/sending_feedback.svg?inline";
    export default {
		name: 'Preloader',
		components: {
			CircleLoading	
		},
        props: {
            show: Boolean,
            message: String
        }
    }
</script>

<style>
.preloader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(29, 29, 29, 0.97);
}
</style>