<template>
  <div class="popup save_popup" v-if="googleDrive">
    <div class="popup_header">
      <h3>Select folder</h3>
      <a class="close_popup_btn" @click="close">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    
    <div class="popup_body">
      <div class="links">
        <template v-for="(folder, index) in parentFolders">
          <a href="#" :key="index" @click="loadFolders(folder)" :class="{ disabled: folder.id == 'root' && parentFolders.length == 1 }"
            >{{ folder.name }}</a>/
        </template>
      </div>

      <ul class="dropdown_select_list vb-content dropdown_overflow">        
        <template v-if="folders && folders.length">
          <li v-for="(folder, index) in folders" :key="index" @click="loadFolders(folder)">
            {{ folder.name}}
          </li>
        </template>

        <h2 class="empty" v-else>
          <template v-if="loading">Loading</template>
          <template v-else>Empty folder</template>
        </h2>
      </ul>

      <div class="popup_body_row">
        <div class="popup_body_row_item">
          <div class="btns-group mt0">
            <a v-on:click="close" class="btns-grey_btn">
              Cancel
            </a>

            <a class="blue_btn" v-on:click="startSaveFile(textInputValue)">Select</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import googleDrive from '../libs/googleDrive';

export default {
  components: {},
  name: "SaveGoogleDrive",
  props: {},

  data: function () {
    const rootFolder = {
      name: 'root',
      id: 'root'
    };

    return {
      rootFolder,
      parentFolders: [rootFolder],
      loading: true,
      folders: []
    };
  },

  async mounted() {
    if(!googleDrive.inited) await googleDrive.init();
    
    await this.loadFolders();

    this.loading = false;
  },

  methods: {
    async loadFolders(folder) {
      if(folder == 'back') folder = this.parentFolders[this.parentFolders.length - 2];
      if(!folder) folder = this.rootFolder;

      const index = this.parentFolders.findIndex(q => q.id == folder.id);
      if(index != -1) {
        this.parentFolders.splice(index + 1);
      } else {
        this.parentFolders.push(folder);
      }

      const last = this.parentFolders[this.parentFolders.length - 1];
      this.folders = await googleDrive.getFolders(last.id);
    },

    close() {
      this.$store.dispatch("popupsToggle", {
          property: "googleDrive",
          value: false
      });
    }
  },

  computed: {
    googleDrive() {
      return this.$store.state.popups.googleDrive;
    }
  }
}
</script>

<style scoped>
  .links {
    padding: 5px;
    color: #fff;
    font-size: 16px;
  }

  .links a {
    color: #3f51b5;
  }

  .dropdown_overflow {
      width: 100%;
      overflow: auto;
      max-height: 300px;
      z-index: 2;
      display: block;
  }

  .empty {
    padding: 30px 10px 10px 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }
</style>