<template>
  <div v-if="!confirmPopUpConfig.isHide" class="popup save_popup" :class="{'mobileView_popup_confirm': isMobileView}">
    <div v-if="!isMobileView" class="popup_header">
      <h3>{{ confirmPopUpConfig.popupTitle || 'Title' }}</h3>
      <a class="close_popup_btn" @click="emitAnswer(false)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div v-if="isMobileView" class="mobileView_popup_body_head">
        <h3>{{confirmPopUpConfig.popupTitle === 'SIGN OUT?' ? 'Sign out?' :  confirmPopUpConfig.popupTitle || 'Title'}}</h3>
      </div>
      <div class="popup_body_row_item reset_popup_content">
        <p>{{ confirmPopUpConfig.popupBody || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, eius alias impedit iste voluptatibus necessitatibus? Laudantium qui nisi maiores nam dolor nulla odit repellat mollitia earum, necessitatibus dolorum nostrum sapiente.' }}</p>
      </div>
      <div class="popup_body_row_item">
        <div class="btns-group mt0">
          <a class="grey_btn" @click="emitAnswer(false)">{{ confirmPopUpConfig.btnNo || 'No' }}</a>
          <a class="blue_btn" @click="emitAnswer(true)">{{ confirmPopUpConfig.btnYes || 'Yes' }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPopUp",
  props: {
    confirmPopUpConfig: Object
  },
  mounted() {
    this.isMobileView = this.$store.state.isMobileView;
  },
  methods: {
    async emitAnswer(result) {
      await this.$store.dispatch("confirmPopUpAnswer", {
        name: this.confirmPopUpConfig.name,
        result, payload: this.confirmPopUpConfig.payload
      });

      await this.$store.dispatch("confirmPopUpConfig", {
        isHide: true
      });
    }
  },
  data: function() {
    return {
      isMobileView: false,
    };
  }
};
</script>
<style scoped>
  .mobileView_popup_confirm {
    margin: 0px 36px;
    color: #8C8C8C;
    background: #1D1D1D;
  }
  .mobileView_popup_body_head {
    color: #FFFFFF;
    margin-bottom: 12px;
  }
  .mobileView_popup_confirm .popup_body {
    background: #1D1D1D;
    padding: 24px;
  }
  .mobileView_popup_body_head h3 {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.72px;
    font-weight: 600;
  }
  .mobileView_popup_confirm .save_popup .popup_body_row_item {
    padding-left: 0;
    padding-right: 0;
  }
  .mobileView_popup_confirm .popup_body_row_item {
    padding: 0;
  }
  .mobileView_popup_confirm .btns-group {
    margin-top: 36px;
  }
  .mobileView_popup_confirm .reset_popup_content p {
    font-size: 15px;
    line-height: 20px;
  }
  .mobileView_popup_confirm .btns-group a {
    text-transform: capitalize;
    font-size: 13px;
    line-height: 18px;
  }
</style>
