<template>
  <div class="tool-item-panel frms_cust_brdr" :class="panelClass">
    <div class="panel-top" v-on:click="panelPropChange">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>custom border</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" alt />
      </div>
    </div>
    <div v-bar>
      <div class="panel-content wo_pad overflow-visible">
        <div class="panel_row outr_brdr">
          <ColorInput
            :defColor="parseColor(customBorderConfig.outerBorder)"
            @inputRGBA="changeOuterBorderColor"
            :key="updateColors"
            textLabel="Outer Border"
            :isFixed="true"
            @updateCheckedColor="updateCheckedColor($event, 'outer')"
            :checkColor="checkColorComputed.outer"
            :checkmarkDisabled="false"
          />
        </div>
        <div class="panel_row">
          <Range
            :defaultValue="10"
            rangeSliderId="editOpacity"
            :rangeSliderValue="customBorderConfig.outerThickness"
            rangeSliderLabel="Outer Thickness"
            rangeSliderMeasure="%"
            rangeSliderClass="range-blue"
            :changeValue="changeOuterThickness"
            :minValue="0"
            :maxValue="100"
          />
        </div>
        <div class="panel_row">
          <ColorInput
            :defColor="parseColor(customBorderConfig.innerBorder)"
            @inputRGBA="changeInnerBorderColor"
            textLabel="Inner Border"
            @updateCheckedColor="updateCheckedColor($event, 'inner')"
            :checkColor="checkColorComputed.inner"
            :key="updateColors"
            :isFixed="true"
            :checkmarkDisabled="false"
          />
        </div>
        <div class="panel_row pb_clr">
          <Range
            :defaultValue="15"
            rangeSliderId="editOpacity"
            :rangeSliderValue="customBorderConfig.innerThickness"
            rangeSliderLabel="Inner Thickness"
            rangeSliderMeasure="%"
            :changeValue="changeInnerThickness"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
          />
        </div>
        <div class="panel_row panel_row_border_top pt-12">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="customBorderConfig.cornerRadius"
            rangeSliderLabel="Corner Radius"
            rangeSliderMeasure="%"
            :changeValue="changeCornerRadius"
            rangeSliderClass="range-blue"
            :minValue="0"
            :defaultValue="0"
            :maxValue="100"
          />
        </div>
        <div class="panel_row pt-12">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="customBorderConfig.captionSpace"
            rangeSliderLabel="Caption Space"
            rangeSliderMeasure="%"
            :changeValue="captionSpace"
            rangeSliderClass="range-blue"
            :minValue="0"
            :defaultValue="0"
            :maxValue="100"
          />
        </div>
        <div class="panel_row">
          <div class="btns-group mt_17">
            <a class="grey_btn" @click="resetParams()">Cancel</a>
            <a class="blue_btn" @click="applyFrame()">Apply</a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon slider_icon"></span> Reposition your image
            behind the frame
          </p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon icon_heart"></span>Add selected frame to
            Favorites
          </p>
        </div>

        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon icon_tune"></span>Access advanced settings to
            adjust the look and feel of the frame
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-plus-membership-icon"></span>Access
            advanced settings to adjust the look and feel of the frame
            <a
              @click="upgradePlan()"
              class="colorcinchplus"
              >Colorcinch Plus</a
            >
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import Range from "@/components/Range.vue";
import ColorInput from "@/components/ColorInput.vue";
import Tips from "@/components/Tips.vue";

export default {
  name: "FramesCustomBorderPanel",
  components: {
    Range,
    ColorInput,
    Tips,
  },
  props: {
    panelClass: String,
    panelPropChange: Function,
  },

  async mounted() {


    await _LIB._cache.renderSaveByTimestamp();
    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.$store.dispatch("fabricSlide", true);
    this.setCustomBorders();
  },

  data: function () {
    const customBorderConfig = {
      outerBorder: "#000000",
      outerThickness: 10,
      innerBorder: "#FFFFFF",
      innerThickness: 15,
      cornerRadius: 0,
      captionSpace: 0,
    };

    return {
      updateColors: false,
      defaultCustomBorderConfig: customBorderConfig,
      tipsText: "",
      helpIcon: require("@/assets/img/help-icon.svg"),
      customBorderConfig: JSON.parse(JSON.stringify(customBorderConfig)),
      checkColor: {
        outer: true,
        inner: true,
      },
    };
  },

  beforeDestroy() {
    this.resetParams();
  },

  methods: {
    upgradePlan() {
        // if (window.abVariant && window.abVariant == 2) {
            this.$store.dispatch("popupsToggle", {
                property: "stripeCheckoutPopup",
                value: true,
            });
        // }
        // else{
        //     this.$store.dispatch("popupsToggle", {
        //         property: "upgradePopup",
        //         value: true,
        //     });
        // }
    },
    parseColor(color) {
      //if(/^#/g.test(color)) return color;
      if(/^rgba/g.test(color)) return color;

      return color;
    },

    updateCheckedColor(value, key) {
      console.log(value, key);
      this.checkColor[key] = value;
    },

    async applyFrame() {
      this.$store.dispatch("preloader", {
        message: "Wait...",
        isShow: true,
      });
      await this.setCustomBorders({ isApply: true });
      this.$store.dispatch("preloader", {});
      this.panelPropChange();
      this.$store.dispatch("changesApplied");
    },

    async setCustomBorders(params) {
      await this.$lib.Frames.customBorders(this.customBorderConfig, params);
    },

    resetParams() {
      this.customBorderConfig = JSON.parse(
        JSON.stringify(this.defaultCustomBorderConfig)
      );

      this.updateColors = !this.updateColors;
      this.setCustomBorders();

      this.panelPropChange();
    },

    changeOuterBorderColor(color) {
      this.customBorderConfig.outerBorder = color;

      this.setCustomBorders();
    },

    changeInnerBorderColor(color) {
      this.customBorderConfig.innerBorder = color;

      this.setCustomBorders();
    },

    changeOuterThickness(val) {
      this.customBorderConfig.outerThickness = val;

      this.setCustomBorders();
    },

    changeInnerThickness(val) {
      this.customBorderConfig.innerThickness = val;

      this.setCustomBorders();
    },

    changeCornerRadius(radius) {
      this.customBorderConfig.cornerRadius = radius;

      this.setCustomBorders();
    },

    captionSpace(space) {
      this.customBorderConfig.captionSpace = space;

      this.setCustomBorders();
    },

    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },
  },
  computed: {
    checkColorComputed() {
      return this.checkColor;
    },

    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },
  },
};
</script>
