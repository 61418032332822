<template>
    <div>
        <div class="form_loading" v-if="isLoading">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="popup save_popup upgrade-popup editorpage subs_popup" v-if="isOffline" style="background: #2A2A2A;">
            <a	class="close_popup_btn" v-on:click="changePopup('upgradePopup', false)">
                <span class="inch-icon inch-close-icon"></span>
            </a>
            <div class="is_offline">
                <Offline></Offline>
            </div>
        </div>

        <div
                class="popup save_popup upgrade-popup editorpage subs_popup new_twa_popup"
                :class="{'is_pwa_popup':is_pwa}"
                v-if="confirmSubscriptionPopup && isOnline"
        >
            <a
                    class="close_popup_btn"
                    v-on:click="changePopup('upgradePopup', false)"
            >
                <span class="inch-icon inch-close-icon"></span>
            </a>
            <div class="top_icon icon">
                <img src="../assets/mobile/plus.svg" alt />
            </div>
            <div class="wrap_promo_plan">
                <div class="wrapper_box">
                    <h2 class="title new_update_popup_1">Confirm Subscription</h2>
                    <p class="all-plus-future">
                        {{allowOnlyAnnual?'All Plus features – one low price. Pay by the year. Cancel anytime.':'All Plus features – one low price. Pay by month or the year. Cancel anytime.'}}
                    </p>
                </div>

                <div class="link-wrap"></div>

                <div v-if="!allowOnlyAnnual" class="plan-type-wrap">
                    <template 	v-for="(plan, index) in plans">
                        <label
                                class="plan-type editorpage"
                                :key="index + promo_code.updater"
                                v-if="(!plan.is_debug) || (plan.is_debug && is_debug_mode)"	>
							<span
                                    class="promo_applied" v-if=" promo_code.data && selectedPlanId == plan.id && plan.stripe_id == promo_code.data.plan_id " >Code applied</span
                            >
                            <input
                                    type="radio"
                                    name="plan"
                                    :disabled="exist_subs"
                                    :data-android_plan_id="plan.google_id"
                                    :value="plan.id"
                                    @input="selectPlan"
                                    :checked="(exist_subs)?(exist_subs.itemId==plan.google_id?true:false):(selectedPlanId == plan.id)"
                                    :key=qq
                            />
                            <div class="checkmark editorpage">
                                <strong>{{ plan.title }}</strong>
                                <span style="white-space: normal" :class="{ decoration: parsePlanPrice(plan) == 'FREE' }" v-html=renderPlanDescription(plan)></span>
                                <div class="margin-top-price"><strong>{{ (exist_subs && exist_subs.itemId==plan.google_id)?"PROMO":parsePlanPrice(plan) }}</strong><strong class="margin-left-price">/ month</strong></div>
                            </div>
                        </label>
                    </template>


                </div>
                <div v-else class="plan-type-wrap">
                    <template v-for="(plan, index) in plans">
                        <label
                                class="plan-type editorpage"
                                style="width: calc(100%)"
                                :key="index + promo_code.updater"
                                v-if="plan.title == 'Annual Plan' && ((!plan.is_debug) || (plan.is_debug && is_debug_mode))"
                        >
							<span
                                    class="promo_applied" v-if=" promo_code.data && selectedPlanId == plan.id && plan.stripe_id == promo_code.data.plan_id " >Code applied</span
                            >
                            <input
                                    type="radio"
                                    name="plan"
                                    :disabled="exist_subs"
                                    :data-android_plan_id="plan.google_id"
                                    :value="plan.id"
                                    @input="selectPlan"
                                    :checked="(exist_subs)?(exist_subs.itemId==plan.google_id?true:false):(selectedPlanId == plan.id)"
                                    :key=qq
                            />
                            <div class="checkmark editorpage">
                                <strong>{{ plan.title }} </strong>
                                <span :class="{ decoration: parsePlanPrice(plan) == 'FREE' }" v-html=renderPlanDescription(plan)>
								</span>
                                <strong> {{ (exist_subs && exist_subs.itemId==plan.google_id)?"PROMO":parsePlanPrice(plan) }}  / month</strong>
                            </div>
                        </label>
                    </template>


                </div>

                <div v-if="!is_pwa"
                     :style="{
						position: 'relative',
						'min-height': promo_code.data ? '70px' : 0,
					}"
                >
                    <div
                            class="promo_code_info"
                            :style="{
							opacity: promo_code.data ? 1 : 0,
							'z-index': promo_code.data ? 999 : 'auto',
						}"
                    >
                        <div class="left">
                            <span class="_code">{{ getPromoCode() }}</span> |
                            <span class="_text">{{ getPromoSavingPrice() }}</span>
                        </div>

                        <div class="right">
                            <a href="#" class="remove_promo_btn" @click="removePromoCode()"
                            >Remove</a
                            >
                        </div>
                    </div>

                    <div
                            class="form-group promo_code_block"
                            :style="getPromoCodeBlockStyle"
                    >
                        <input
                                :placeholder="promo_code.plac.text"
                                :readonly="!promo_code.isOpenInput"
                                @input="inputPromoCode"
                                :class="{
								promo_error: promo_code.error,
								disabled: promo_code.loading,
							}"
                                ref="promo_code_elem"
                                type="text"
                                class="form-control"
                                v-model="promo_code.code"
                        />
                        <a
                                href="#"
                                @click="checkPromoCode()"
                                class="checkActivePromocode"
                                :class="{ active: promo_code.code }"
                        >
							<span
                                    v-if="!promo_code.loading"
                                    class="promo_button"
                                    :class="{ act: promo_code.code }"
                            >
								<svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="40"
                                        height="38"
                                        viewBox="0 0 40 38"
                                >
									<g transform="translate(-616 -646)">
										<g
                                                fill="none"
                                                stroke="#ececec"
                                                class="_border"
                                                transform="translate(616 646)"
                                        >
											<rect stroke="none" width="40" height="38" rx="4" />
											<rect
                                                    class="_bg"
                                                    fill="none"
                                                    x="0.5"
                                                    y="0.5"
                                                    width="39"
                                                    height="37"
                                                    rx="3.5"
                                            />
										</g>
										<path
                                                fill="#fa6400"
                                                class="_arrow"
                                                d="M7.344,6.656l-.369.334L1.344,12.058l.739.845L7.344,8.169,12.6,12.9l.739-.845L7.713,6.991Z"
                                                transform="translate(645.902 657.656) rotate(90)"
                                        />
									</g>
								</svg>
							</span>

                            <span v-if="promo_code.loading" class="promo_loader">
								<div class="lds-ring">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</span>
                        </a>
                    </div>
                </div>
            </div>
            <div
                    class="card-data"
                    ref="card_1">
                <div class="form-group" v-if="!is_pwa">
                    <div class="label editorpage">Name on Card</div>
                    <input
                            type="text"
                            v-model="register.name"
                            value="test111"
                            class="form-control input-drop-shadow"
                            @input="changeCardName"
                    />
                </div>
                <div class="form-group">
                    <div class="label editorpage">Card Number</div>
                    <div class="input-drop-shadow" id='cardNumber' ref='cardNumber'></div>
                </div>
                <div class="form-group-flex" v-if="!is_pwa">

                    <div class="form-group col-6">
                        <div class="label editorpage">MM/YY</div>
                        <div class="input-drop-shadow" id='cardExpiry' ref='cardExpiry'></div>
                    </div>
                    <div class="form-group col-6 margin-left-cvc">
                        <div class="label editorpage">CVC</div>
                        <div class="input-drop-shadow" id='cardCvc' ref='cardCvc'></div>
                    </div>
                </div>

                <div v-if="!isLoggedIn" class="form-group sign-up">
                    <div class="label sign-up">Email</div>
                    <input
                            type="email"
                            v-model="register.email"
                            ref="reg_email"
                            class="form-control input-drop-shadow"
                    />
                    <div class="label sign-up">Password</div>
                    <input
                            type="password"
                            v-model="register.password"
                            ref="reg_password"
                            class="form-control input-drop-shadow"
                    />
                </div>

            </div>


            <div class="form-group test_gpay" v-if="is_pwa && !is_twa">
                <div id="gpayButton" ref="gpayButton"></div>
            </div>

            <div class="form-group test_gpay" v-if="is_pwa && is_twa">
                <a href="#" id="androidPayButton" ref="androidPayButton" class="btn-orange" style="color: #fff;margin:0;">Subscribe</a>
            </div>

            <div
                    v-if="!is_pwa"
                    class="btn-wrap"
                    :style="{ 'margin-top': '15px', 'margin-bottom': '30px' }"
            >
                <a href="#" @click="payStripe" class="btn-orange" style="color: #fff;margin:0;">Subscribe</a>
            </div>

            <div class="form_errors" v-if="formErrors">
                <ul id="example-1">
                    <li v-for="item in formErrors">{{ item }}</li>
                </ul>
            </div>

            <div class="agreement_detail">
                <p>By confirming your subscription, you agree to the Colorcinch <a href="https://www.cartoonize.net/privacy-policy/"><u>Privacy Policy</u></a> and <a href="https://www.cartoonize.net/terms/"><u>Terms of Service</u></a>.</p>
            </div>
        </div>

<!------------------------------------------------Congrats Popup--------------------------->

        <div class="popup save_popup upgrade-popup small width-padding" v-if="congratPopup">
            <a class="close_popup_btn" v-on:click="closeAllPopups()">
                <span class="inch-icon inch-close-icon"></span>
            </a>
            <div class="icon margin-bottom">
                <img src="../assets/images/smiley-img.png" alt=":)" />
            </div>
            <h2
                    class="title sm title-success-signin"
            >
                Thanks for joining - we're thrilled to have you!
            </h2>
            <p class="box-success-signin">
                You now have full access to Colorcinch Plus and everything it has to
                offer!
            </p>
          <div class="btn-wrap margin-top" >
            <a href="#" @click="closeAllPopups" class="btn-orange" style="margin: 0; color: #fff;">Start Editing</a>
          </div>
        </div>
    </div>
</template>
<script>

    import CGPStripe from "@/libs/cgp-stripe";
    import Gpay from "@/libs/gpay";
    import GpayAndroid from "@/libs/gpay-android";


    import Offline from "@/components/Offline.vue";
    import DropdownSelect from "@/components/DropdownSelect.vue";
    import PromoCodeParser from "@/libs/promoCode";

    const analitics = require("../libs/analytics");

    export default {
        name: "UpgradePopupNew",
        components: { DropdownSelect, Offline },
        props: {
            yesUpgrade: Function,
            allowOnlyAnnual:  {
                type: Number,
                default: 0
            },
        },
        beforeMount() {
          $('body').addClass("subscription-popup");
          $("meta[name=theme-color]").attr("content", "#F8F8F8");
        },
        async mounted(){
            let is_twa = 'getDigitalGoodsService' in window;
            console.log("upgrade popup mounted, is_twa", is_twa);
            //is_twa

            document.addEventListener("android_pay_success", (e)=>{
                let {token, payerData} = e.detail;

                this.paymentMethodDetails = {
                    payment_token: token,
                    payerData: payerData,
                    card: false,
                    is_twa: true,
                    is_pwa: true,
                };

                if(this.$store.state.USER){
                    this.createUserAndSubscribe(true);
                }else{
                    // console.log("register popup");
                    // this.popupOpen("newRegisterPopup");
                }


            });

            document.addEventListener("gpay_token_generated", (e)=>{
                let {paymentToken, promocode} = e.detail;
                this.gpay_promo  = promocode;
                paymentToken.pwa = true;

                if(this.$store.state.USER){
                    this.paymentMethodDetails = paymentToken;
                    if(this.$store.state.USER.status === 'free') this.updateGTMEvent('add_payment_info','free_user')
                    this.createUserAndSubscribe(true);
                }else{
                    this.paymentMethodDetails = paymentToken;
                    this.updateGTMEvent('add_payment_info','visitor')
                    //this.popupOpen("newRegisterPopup");
                }
            });

        },

        beforeDestroy() {
          $('body').removeClass("subscription-popup");
          $("meta[name=theme-color]").attr("content", "#2A2A2A");
        },

        async created() {
            this.digitalGoodsServiceAvailable = 'getDigitalGoodsService' in window;

            this.plans = this.$store.state.plans || [];
            if (this.plans.length) {
                this.selectedPlanId = this.plans[0].id;
                this.selectedPlanAndroid = this.plans[0].google_id;
            }


            this.is_pwa = window.matchMedia('(display-mode: standalone)').matches && typeof PaymentRequest!='undefined';
            this.is_twa =  this.digitalGoodsServiceAvailable;
            this.is_debug_mode = document.cookie.indexOf('debug_payments_1=')===-1?false:true;

//		this.$nextTick(async ()=>{

            if(this.is_twa){ //если установлено как андроид приложение
                console.log(">>> RUN IN TWA MODE <<<");
                this.gpayAndroid = new GpayAndroid(this.$store, this);
                this.gpayAndroid.init(this.$refs.androidPayButton, this.selectedPlanAndroid);
                if(this.is_twa){
                    this.exist_subs  = await this.gpayAndroid.checkExistSubscribtion();
                    if(this.exist_subs){
                        let end_at = false;
                        try{
                            let sub_info = await fetch(`/android/android_check_subscribe?method=check&plan_id=${this.exist_subs.itemId}&token=${this.exist_subs.purchaseToken}`).then((res)=>{ return res.json();});
                            end_at = sub_info ? sub_info.ended_at:false;
                        }catch(e){
                            console.error("check google subscription error");
                        }
                        this.exist_subs.end_at = end_at;
                        this.qq+=1;
                        this.selectedPlanAndroid = this.exist_subs.itemId;

                        this.plans.forEach((item)=>{
                            if(item.google_id==this.exist_subs.itemId){
                                this.selectedPlanId = item.id;
                            }
                        })

                        this.gpayAndroid.subscribe_exist = this.exist_subs;
                    }
                }

//				let subs = await this.gpayAndroid.checkExistSubscribtion();
//				console.log("subs is ", subs);
//				console.log("user is ", this.$store.state.USER);

            }else if(this.is_pwa && !this.is_twa){ //если установлено из браузера
                console.log(">>> RUN IN PWA MODE <<<");
                this.gpay = new Gpay(this.$store, this);
                this.gpay.init(this.$refs.gpayButton, this.selectedPlanId);

            }else{
                console.log(">>> RUN IN BROWSER <<<");
                this.cgpstripe = new CGPStripe(this.$refs);
            }
//		})
        },

        data: function () {
            return {
                qq: 1,
                exist_subs: false,
                gpay_token_generated: false,
                is_pwa: false,
                is_debug_mode: false,
                cgpstripe: false,
                gpay_promo: "",
                promo_code: {
                    code: "",
                    isFree: false,
                    isOpenInput: false,
                    loading: false,
                    data: false,
                    updater: 1,
                    plac: {
                        text: "Enter Promo Code",
                        default: "Enter Promo Code",
                        error: "Oops! The code you entered doesn't exist.",
                    },
                },
                howUse: {
                    selectDropdown: "",
                    options: [
                        "Personal use – I'm just having fun with it",
                        "I'm a photographer – to edit personal and client photos",
                        "I'm a freelancer/business owner – to manage my business",
                        "Company use – at my job at another company",
                        "Other...",
                    ],
                    select: "",
                },
                register: {
                    name: "",
                    email: "",
                    email_confirm: "",
                    password: "",
                    howUse: "",
                },
                formErrors: [],
                paymentMethodDetails: {},
                confirmSubscriptionPopup: true,
                createAccountPopup: false,
                congratPopup: false,
                // newRegisterPopup: false,
                stripe: stripe_pub, //"pk_test_pjaHBMeEKNEHwWTtWWc8GrXf00Xwm5gLxc",
                complete: false,
                number: false,
                expiry: false,
                cvc: false,
                cardName: "",
                isLoading: false,
                plans: [],
                selectedPlanId: null,
                selectedPlanAndroid: null,
                digitalGoodsServiceAvailable: false,

                options: {
                    elements: {
                        fonts: [{}],
                    },
                    style: {
                        base: {
                            fontFamily: "Proxima Nova",
                        },
                    },
                },
            };
        },

        methods: {

//		onGpayToken(){
//			if(this.$store.state.paymentToken && this.$store.state.paymentToken.pwa){
//				if(this.$store.state.USER){
//					this.paymentMethodDetails = this.$store.state.paymentToken;
//					this.createUserAndSubscribe(true);
//				}else{
//					this.paymentMethodDetails = this.$store.state.paymentToken;
//					this.popupOpen("newRegisterPopup");
//				}
//			}
//			return this.$store.state.paymentToken;
//		},

            renderPlanDescription(plan) {
                const parse = new PromoCodeParser(this.promo_code, this.plans);
                let ret = parse.replacePlanDescription(plan);
                if(this.exist_subs && this.exist_subs.itemId==plan.google_id){
                    if(!this.exist_subs.end_at){
                        return "";
                    }
                    return "Free until "+this.exist_subs.end_at; // +",<br>after "+parse.replacePlanDescription(plan).toLowerCase()+" $"+plan.price;
                }

                return ret;
            },

            getPromoSavingPrice() {
                const parse = new PromoCodeParser(this.promo_code, this.plans);
                return parse.getPromoSavingPrice();
            },

            getPromoCode() {
                return this.promo_code.code;
            },

            parsePlanPrice(plan = {}, isFull = false) {
                const parse = new PromoCodeParser(this.promo_code, this.plans);
                return parse.parsePlanPrice(plan, isFull);
            },

            removePromoCode(isOpenInput = false, focusInput = false) {
                this.promo_code.data = false;
                this.promo_code.isOpenInput = isOpenInput;
                this.promo_code.error = false;
                this.promo_code.code = "";

                if (focusInput) {
                    this.$refs.promo_code_elem.focus();
                }
            },

            async checkPromoCode() {
                if (!this.promo_code.code) return;
                if (this.promo_code.loading) return;
                this.promo_code.loading = true;

                try {
                    const response = await fetch(
                        `${window.prefixURL}/check_promocode?code=${this.promo_code.code}`
                    ).then((res) => res.json());
                    if (response.success) {
                        const plan = this.plans.find(
                            (pl) => pl.stripe_id == response.plan_id
                        );
                        if (!plan) throw Error("Bad code");

                        this.selectedPlanId = plan.id;
                        this.promo_code.data = response;
                        this.promo_code.updater += 1;
                    } else {
                        throw Error("Bad code");
                    }
                } catch (err) {
                    this.promo_code.plac.text = this.promo_code.plac.error;

                    console.error(err);
                    this.promo_code.error = true;
                    this.promo_code.code = "";
                    this.$refs.promo_code_elem.focus();
                }

                this.promo_code.loading = false;
            },

            inputPromoCode() {
                this.promo_code.error = false;
                this.promo_code.plac.text = this.promo_code.plac.default;
            },

            openDropdown(option) {
                this[option].selectDropdown =
                    this[option].selectDropdown == "active" ? "" : "active";
            },

            selectDrop(value) {
                this.register.howUse = value;
                this.howUse.selectDropdown = "";
            },

            showLoginPopUp() {
                this.closeAllPopups();
                this.$store.dispatch("popupsToggle", {
                    property: "signIn",
                    value: true,
                });
            },

            selectPlan(e) {
                this.promo_code.code = "";
                this.promo_code.isOpenInput = false;
                this.promo_code.loading = false;
                this.promo_code.data = false;
                this.promo_code.datupdatera += 1;
                this.selectedPlanId = e.target.value;
                this.selectedPlanAndroid = e.target.dataset.android_plan_id;
                console.log("Debug:", this.selectedPlanAndroid);
            },

            changeCardName(e) {
                this.cardName = e.target.value;
            },
            //TODO remove?
            async createCustomer(paymentMethod, card) {
                return fetch(`${this.$store.state.prefixURL}/create_customer`, {
                    method: "post",
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        payment_method: paymentMethod,
                        card,
                        promo_code: this.promo_code.code,
                        plan_id: this.selectedPlanId,
                    }),
                }).then((res) => res.json());
            },

            async createUserAndSubscribe(only_payment=false) {
                this.formErrors = null;
                if(only_payment===true){
                    only_payment = true;
                }else{
                    only_payment = false;
                }

                this.isLoading = true;
                var is_gpay  =false;
                let register_endpoint = "register3";

                console.log("this.paymentMethodDetails", this.paymentMethodDetails);

                if(this.paymentMethodDetails.is_twa){
                    register_endpoint = "register-android";
                    var payment_data = {
                        is_gpay: true,
                        promo_code: this.gpay_promo,
                        plan_id: this.selectedPlanId,
                        ...this.paymentMethodDetails
                    };
                    is_gpay = true;
                }else{
                    if(this.paymentMethodDetails.pwa){
                        var payment_data = {
                            is_gpay: true,
                            promo_code: this.gpay_promo,
                            plan_id: this.selectedPlanId,
                            ...this.paymentMethodDetails
                        };
                        is_gpay = true;
                    }else{
                        var payment_data = {
                            seti: this.paymentMethodDetails,
                            promo_code: this.promo_code.code,
                            plan_id: this.selectedPlanId,
                            is_gpay: false,
                        }
                    }
                }


                const result = await fetch(`${this.$store.state.prefixURL}/${register_endpoint}`, {
                    method: "POST",
                    body: JSON.stringify(
                        Object.assign(this.register, {
                            _token: $('[name="csrf-token"]').attr("content"),
                            only_payment: only_payment,
                            promo_code: this.promo_code.code,
                            payment_data: payment_data,
                            is_gpay: is_gpay,
                        })
                    ),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-CSRF-TOKEN": $('[name="csrf-token"]').attr("content"),
                    },
                }).then((res) => res.json()).catch((err)=>{
                    this.isLoading = false;
                    alert("Error #5");
                });

                this.formErrors = result.errors || null;
                this.isLoading = false;


                if (result.success == false) {
                    this.formErrors = [paymentRes.message];
                    //window.location.reload();
                    this.isLoading = false;
                    this.confirmSubscriptionPopup = true;
                    // this.newRegisterPopup = false;
                    return;
                }
                result.data.user.isPremium = true;
                if(this.$store.state.USER){
                    const subscriptionId = result.data.customer.subscription.id ?? null
                    if(this.$store.state.USER.status === 'free') this.updateGTMEvent('purchase','free_user',subscriptionId);
                } else {
                    this.updateGTMEvent('purchase','visitor',result.data.customer.subscription.id);
                }
                this.$store.state.USER = result.data.user;
                if(!only_payment) analitics.sendCustomEvent("add_account_info",{user_email:this.$store.state.USER.email});


                this.popupOpen("congratPopup");
                this.isLoading = false;
                await fetch("/createAffilate", {
                    headers: {
                        "X-CSRF-TOKEN": $('[name="csrf-token"]').attr("content"),
                    },
                }).then((r) => r.text());
                rewardful("convert", { email: this.register.email });

                this.success_payment = true;

                return false;
            },

            async payStripe() {
                this.formErrors = [];

                if(!this.cardName){
                    this.formErrors.push(
                        "Please enter the name as it appears on your card"
                    );
                    return false;
                }
                this.isLoading = true;
                var payment_data = await this.cgpstripe.pay(this.cardName);
                this.isLoading = false;
                if(payment_data.error){
                    this.formErrors.push(payment_data.error);
                    return false;
                }
                if(!this.$store.state.USER) {
                    if (
                        !this.register.email ||
                        !this.register.password
                    ) {
                        this.formErrors = ["Please enter email and password"];
                        return false;
                    }

                    if (
                        !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                            this.register.email
                        )
                    ) {
                        this.formErrors = ["Please enter a valid email address"];
                        return false;
                    }


                    if (this.register.password.length < 8) {
                        this.formErrors = ["Your password must be at least 8 characters"];
                        return false;
                    }
                }


                this.paymentMethodDetails = payment_data;

                console.log("1 this.paymentMethodDetails", this.paymentMethodDetails);
                if(this.$store.state.USER){
                    if(this.$store.state.USER.status === 'free') this.updateGTMEvent('add_payment_info','free_user')
                    this.createUserAndSubscribe(true);
                }else{
                    this.updateGTMEvent('add_payment_info','visitor')
                    //this.popupOpen("newRegisterPopup");
                    this.createUserAndSubscribe();
                }
                return false;

                //TODO old


                createToken({ name: this.cardName }).then(async (result) => {
                    if (result.error) {
                        this.formErrors.push(result.error.message);
                        this.isLoading = false;
                        return false;
                    }
                    if (result.token && result.token.card) {
                        console.log("result.token.card", result.token);
                        var payment_method = await createPaymentMethod(
                            "card",
                            result.token.id
                        );
                        console.log("payment.method", payment_method);

                        if (payment_method.error) {
                            this.formErrors.push(payment_method.error.message);
                            this.isLoading = false;
                            return false;
                        }

                        if (result.success == false) {
                            this.formErrors.push(result.message);
                            this.isLoading = false;
                            return false;
                        }

                        this.paymentMethodDetails = {
                            payment_method_id: payment_method.paymentMethod.id,
                            card: result.token.card,
                        };
                        //this.popupOpen("newRegisterPopup");

                        this.isLoading = false;
                    }

                });


                // console.log(this.number, this.expiry, this.cvc);
            },
            updateGTMEvent(event,origin,transaction_id){
                const data = {
                    currency: 'USD',
                    value: this.getSelectedPlan.price ?? 0,
                    items: [
                        {
                            item_id: this.getSelectedPlan.id ?? 0,
                            item_name: this.getSelectedPlan.title ?? 0,
                            price: this.getSelectedPlan.price ?? 0,
                            origin,
                        }
                    ]
                }
                if(transaction_id) data.transaction_id = transaction_id

                analitics.sendCustomEvent(event,data);

            },
            update() {
                this.complete = this.number && this.expiry && this.cvc;
                if (this.number) {
                    if (!this.expiry) {
                        this.$refs.cardExpiry.focus();
                    } else if (!this.cvc) {
                        this.$refs.cardCvc.focus();
                    }
                } else if (this.expiry) {
                    if (!this.cvc) {
                        this.$refs.cardCvc.focus();
                    } else if (!this.number) {
                        this.$refs.cardNumber.focus();
                    }
                }

            },

            tokenCreated(token) {
                console.log(token);
            },

            changePopup(property, value) {
                this.$store.dispatch("popupsToggle", { property, value });
            },
            closeAllPopups() {
                this.$store.dispatch("popupsToggle", {
                    property: "upgradePopup",
                    value: false,
                });
            },
            popupOpen(popupName) {
                //this.newRegisterPopup = false;

                (this.confirmSubscriptionPopup = false),
                    (this.createAccountPopup = false),
                    (this.congratPopup = false),
                    (this[popupName] = true);
            },
        },

        computed: {
            isLoggedIn() {
                return this.$store.state.USER;
            },



            openHidePromoInput() {
                return this.promo_code.isOpenInput;
            },

            promoCodeUpdate() {
                return this.promo_code.data;
            },

            getPromoCodeBlockStyle() {
                try {
                    const isOpen = this.promo_code.isOpenInput;
                    const data = this.promo_code.data;

                    let height = isOpen && !data ? "58px" : "0";
                    if (data) {
                        height = "58px";
                    }
                    let marginTop = isOpen && !data ? "38px" : "";

                    return { marginTop, height, opacity: isOpen && !data ? 1 : 0 };
                } catch (err) {
                    console.error(err);
                    return {};
                }
            },
            getSelectedPlan(){
                return this.plans.find(item => item.id == this.selectedPlanId) || [];
            }

        },
        watch: {
            paymentToken(){

            },

            openHidePromoInput() {
                this.inputPromoCode();
            },

            promoCodeUpdate(data) {
                if (data) {
                    const plan = this.plans.find(
                        (pl) => pl.stripe_id == this.promo_code.data.plan_id
                    );

                    this.promo_code.isFree = this.parsePlanPrice(plan) == "FREE";
                } else {
                    this.promo_code.isFree = false;
                }
            },

            number() {
                this.update();
            },
            expiry() {
                this.update();
            },
            cvc() {
                this.update();
            },
        },
    };
</script>

<style scoped>
    .form_loading {
        display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 938px;
    }

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .all-plus-future {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }

    .title-success-signin {
        font-weight: inherit !important;
        font-family: inherit;
        font-size: 32px !important;
        font-weight: 400px;
    }

    .box-success-signin {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
    }
    .subs_popup .form-group label {
        font-weight: 400;
    }

    .footer_mobile .form-group .label {
        font-weight: 700 !important;
    }

    .popup.save_popup.upgrade-popup .checkbox {
        font-weight: 400 !important;
    }

    .popup.save_popup.upgrade-popup {
        padding: 70px 100px 60px 100px;
    }

    select:active {
        background: #f5f5f5 0% 0% no-repeat padding-box;
    }

    .promoButton {
        font: normal normal 600 12px/15px Proxima Nova;
        letter-spacing: -0.29px;
        color: #9a9c9e;
        float: right;
        position: absolute;
        bottom: -20px;
        right: 7px;
        text-decoration: underline;
    }

    .plan-type-wrap {
        position: relative;
    }

    .promo_code_block {
        margin-top: 38px;
        z-index: 99;
        transition: 0.3s;
        height: 0;
        overflow: hidden;
        position: relative;
    }

    .promo_code_block > input {
        border: 1px solid rgba(0, 0, 0, 0);
        background: #f5f5f5;
        border-radius: 5px;
        width: 100%;
        padding: 14px;
        display: block;
        border: 1px solid #ececec;
        border-radius: 5px;
        color: #757575;
        font-size: 14px;
        font-family: inherit;
        padding-right: 52px;
    }

    .promo_code_block > input:focus {
        border: 1px solid #ececec;
    }

    /* .checkActivePromocode.active {
        background: #fa6401;
        color: #fff;
        pointer-events: all;
    } */

    .promo_error {
        border: 1px solid #ff5352 !important;
    }

    .promo_error::placeholder {
        color: #ff5352;
    }

    .promo_code_info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background: #a4ecdc 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 18.5px;
        margin-bottom: 12px;
        position: absolute;
        width: 100%;
        top: 0px;
        transition: 0.3s;
        opacity: 0;
    }

    .promo_code_info > .left {
        flex: 1;
        text-align: left;
    }

    .promo_code_info > .left > ._code {
        letter-spacing: 0.2px;
        color: #2a2a2a;
        font: normal normal 600 14px/17px Proxima Nova;
        margin-right: 4px;
    }

    .promo_code_info > .right {
        font: normal normal bold 12px/15px Proxima Nova;
        opacity: 0.8;
        color: #2a2a2a;
    }

    .remove_promo_btn:hover {
        color: #d40000;
    }

    .promo_applied {
        position: absolute;
        right: 0;
        top: -6px;
        background: #4adaba;
        padding: 6px;
        border-radius: 0px 5px 3px 3px;
        text-align: left;
        font: normal normal 600 9px/11px Proxima Nova !important;
        letter-spacing: 0px;
        color: #ffffff !important;
        text-transform: uppercase;
        opacity: 1;
    }

    .decoration {
        text-decoration: line-through;
    }

    .promo_button {
        top: 0;
        width: 40px;
        display: block;
        cursor: default;
        height: 38px;
        right: 0;
        position: relative;
        pointer-events: none;
    }

    .promo_button.act ._bg {
        fill: #fa6400;
    }

    .promo_button.act ._arrow {
        stroke: #fff;
    }

    .promo_button.act {
        pointer-events: all;
        cursor: pointer;
    }

    .popup.save_popup.upgrade-popup .checkbox {
        transition: 0.3s;
    }

    .popup.save_popup.upgrade-popup .checkbox.hide_card {
        margin-top: 87px;
    }
    .popup.save_popup.upgrade-popup.editorpage{
        width: 100%;
        max-width: 428px;
    }

    .upgrade-popup {
        transition: 0.2s;
    }

    .card-data.hide_card {
        bottom: 275px;
        pointer-events: none;
        opacity: 0;
    }

    .wrap_promo_plan {
        background: #fff;
        z-index: 999999999;
        position: relative;
        display: block;
    }


    .btn-gpay {
        width: 100%;
        background-color: #000000;
        font-size: 14px;
        box-shadow: 0 8px 35px -5px rgb(250 100 0 / 60%);
        border-radius: 4px;
        padding: 15px;
        display: block;
        color: #ffffff;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
    }

    .btn-gpay span {
        margin-right: 10px;
    }

    .btn-gpay-icon {
        height: 17px;
        width: 44px;
        object-fit: contain;
        object-position: center;
    }

    .popup.save_popup.upgrade-popup.editorpage.subs_popup.new_twa_popup{
        padding: 16px !important;
    }
    label.plan-type.editorpage {
        height: 100% !important;
    }
    .checkmark.editorpage {
        height: 123px !important;
        padding: 20px !important;
    }
    .margin-top-price {
        display: flex;
        margin-top: 12px;
    }
    .margin-top-price strong{
        font-size: 16px;
    }
    .margin-left-price {
        margin-left: 12px;
        font-size: 13px;
        letter-spacing: -0.52px;
    }

    h2.title.new_update_popup_1 {
        font-size: 28px !important;
        letter-spacing: -1.12px;
        line-height: 32px;
    }
    .label.editorpage, .label.sign-up {
        margin-bottom: -5px !important;
    }

    .footer_mobile .popup_wrpr > div {
      margin: 0 !important;
    }
    .footer_mobile .popup.save_popup.upgrade-popup.small.width-padding {
      max-width: 100vw;
      width: 100%;
      height: 100%;
      padding-top: 136.7px !important;
      padding-left: 21px !important;
      padding-right: 21px !important;
    }
    .footer_mobile .popup.save_popup.upgrade-popup.small .btn-wrap.margin-top {
      position: absolute;
      bottom: 40px;
      width: 100vw;
      margin: 0 !important;
      padding: 0 16px;
      left: 0;
      border-radius: 6px;
    }
    .footer_mobile .popup.save_popup.upgrade-popup.small .btn-wrap.margin-top a {
      text-transform: none;
      padding: 14px;
      margin: 0px;
      color: #FFFFFF;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.6px;
      font-weight: 400;
    }
    .footer_mobile .popup.save_popup.upgrade-popup.small p {
      color: #828C9C;
      font-size: 16px;
      line-height: 22px;
      padding: 0 43px;
      font-weight: lighter;
    }
    .footer_mobile .popup.save_popup.upgrade-popup.small .title {
      font-weight: 600 !important;
      font-size: 24px !important;
    }
    .footer_mobile .popup.save_popup.upgrade-popup.small .icon img {
      width: 60px;
      height: 60.27px;
    }
    .footer_mobile div.top_icon img {
        margin-top: 16px;
        width: 26px !important;
    }
    .footer_mobile .wrapper_box {
        padding: 0px 28px;
    }
    .footer_mobile .form-group-flex {
        display: flex;
        justify-content: space-around;
    }
    .footer_mobile .checkmark.editorpage strong {
        font-size: 16px !important;
        letter-spacing: -0.64px;
        line-height: 18px;
    }
    .footer_mobile .form-group {
        margin-bottom: 7px !important;
    }
    .footer_mobile .form-group.sign-up {
        margin-top: 36px;
    }
    .footer_mobile .input-drop-shadow {
      box-shadow: 0px 1px 2px #00000014;
       -webkit-appearance: none;
    }
    .footer_mobile .form-group.col-6 {
        width: calc(50% - 6px);
    }
    .footer_mobile .margin-left-cvc {
        margin-left: 12px;
    }
    .footer_mobile a.btn-orange {
        border-radius: 6px;
        padding: 13px !important;
        text-transform: capitalize !important;
        background-color: #FA6400 !important;
    }
    .footer_mobile .popup.save_popup.upgrade-popup .checkmark {
      border-radius: 6px;
    }
</style>
