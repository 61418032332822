<template>
  <div class="tool-item-panel draw_panel with_popup" :class="sidebarPanelClass">
    <div class="panel-top without_hover">
      <div class="back">
        <h3>drawing</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img src="@/assets/img/help-icon.svg" alt />
      </div>
    </div>
    <div class="panel-content wo_pad wo_pad_wo_overfl" :key="rendererInc">
      <div v-bar>
        <div class="text_panel_scroll">
          <div class="panel_row">
            <a
              href="#"
              class="blue_btn full_w_btn"
              :class="{ disabled: !settingsPanel }"
              v-on:click="settingsShowHandle"
              >Add drawing</a
            >
          </div>
          <div
            class="tripple_btns_block panel_row"
            v-bind:class="{ disabled: settingsPanel }"
          >
            <label class="text_label has_mb">Tool</label>
            <div class="btns_wrpr">
              <div class="multi_line_checkboxes_wrpr">
                <label class="btn-action-left multi_line_checkbox" v-tooltip.top="'Draw'">
                  <input
                    type="radio"
                    name="tools_radio"
                    @change="changeDrawingType('brush')"
                    :checked="drawParams.isDrawing"
                    id
                  />
                  <span class="btn-action-left multi_line_checkbox_text">
                    <span class="inch-icon inch-drawing-mode-icon"></span>
                  </span>
                </label>
                <label class="btn-action-right multi_line_checkbox" v-tooltip.top="'Erase'">
                  <input
                    type="radio"
                    name="tools_radio"
                    @change="changeDrawingType('eraser')"
                    :checked="drawParams.isEraser"
                    id
                  />
                  <span class="btn-action-right multi_line_checkbox_text">
                    <span class="inch-icon inch-erase-icon"></span>
                  </span>
                </label>
              </div>
              <a
                href="#"
                class="btn-action-all btn-action btn-action-w-74"
                @click="undoLastDrawing"
                v-tooltip.top="'Reset'"
              >
                <span class="inch-icon inch-clear-reset-icon"></span>
              </a>
            </div>
          </div>
          <div class="panel_row" v-bind:class="{ disabled: settingsPanel }">
            <DropdownSelect
              textLabel="Brush Style"
              :current="drawParams.brush"
              :selectClass="selectDropdown"
              @resetSelectClass="selectDropdown = ''"
              :selectOptions="selectOptions"
              @toggleDropdown="openDropdown"
              @selectDropdown="selectDrop"
            />
          </div>
          <div class="panel_row" v-bind:class="{ disabled: settingsPanel }">
            <ColorIput
              :key="suckerRerender"
              :defColor="drawParams.color"
              :changeColorValue="setBrushColor"
              textLabel="Color"
              :checkmarkDisabled="true"
            />
          </div>
          <div class="panel_row" v-bind:class="{ disabled: settingsPanel }">
            <Range
              rangeSliderId="editOpacity"
              :rangeSliderValue="drawParams.width"
              rangeSliderLabel="Size"
              rangeSliderMeasure="%"
              rangeSliderClass="range-blue"
              :minValue="1"
              :maxValue="100"
              :changeValue="setBrushSize"
              @dragEnd="setBrushSize($event, true)"
              :defaultValue="10"
            />
          </div>
          <div class="panel_row" v-bind:class="{ disabled: settingsPanel }">
            <Range
              rangeSliderId="editOpacity"
              :rangeSliderValue="drawParams.softness"
              rangeSliderLabel="Softness"
              rangeSliderMeasure="%"
              rangeSliderClass="range-blue"
              :minValue="0"
              :maxValue="100"
              :defaultValue="0"
              :changeValue="setSoftness"
            />
          </div>
          <div
            class="panel_row pb_clr"
            v-bind:class="{ disabled: settingsPanel }"
          >
            <Range
              rangeSliderId="editOpacity"
              :rangeSliderValue="drawParams.opacity"
              rangeSliderLabel="Intensity"
              rangeSliderMeasure="%"
              rangeSliderClass="range-blue"
              :minValue="0"
              :defaultValue="100"
              :maxValue="100"
              :changeValue="setOpacity"
            />
          </div>
          <!-- <div class="panel_row panel_row_border_top" v-bind:class="{ disabled: settingsPanel }">
            <div class="actions-group double-btn popup_double_btns_wrpr">
              <a class="button btn-action duplicate" v-on:click="popupLayerPropShow">Layer Properties</a>
              <a class="btn-action trash" v-on:click="removeDrawHandler">
                <span class="inch-trash-icon"></span>
              </a>
            </div>
          </div>-->

          <div
            class="popup_body_row_item"
            :style="{ 'padding-top': '25px' }"
            v-if="!settingsPanel"
          >
            <div class="btns-group mt0">
              <a class="grey_btn" @click="settingsShowHandle">Cancel</a>
              <a href="#" class="blue_btn" @click="applyDrawing">Apply</a>
            </div>
          </div>
          <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
        </div>
      </div>

    </div>

    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>

    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-drawing-mode-icon"></span>
            Draw with your mouse cursor
          </p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon icon_erase"></span>
            Erase unwanted parts of your drawing
          </p>
        </div>
        <div class="tooltip_row_item">
          <p class="simple_light_p with_icon">
            <span class="inch-icon inch-rotate-left-icon"></span>
            Clear all drawings
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="tooltip_row_item">
          <p class="simple_light_p">
            Use <i>Brush Style, Color, Size</i> and <i>Intensity</i> features to
            stylize your brush strokes.
          </p>
        </div>
      </template>
    </Tips>

    <PopupLayerProp
      v-if="popupLayerPropVisible"
      :popupLayerPropShow="popupLayerPropShow"
    />
    <div
      class="pop_up_closer"
      v-if="popupLayerPropVisible"
      v-on:click="popupLayerPropShow"
    ></div>
  </div>
</template>


<script>
import ColorIput from "@/components/ColorInput";
import Range from "@/components/Range";
import Tips from "@/components/Tips.vue";
import DropdownSelect from "@/components/DropdownSelect.vue";
import PopupLayerProp from "@/components/popupPanel/PopupLayerProp.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: "DrawPanel",
  props: {
    sidebarPanelClass: String,
  },
  components: {
    ColorIput,
    Range,
    Tips,
    DropdownSelect,
    PopupLayerProp,
    AdLeftPanel,
  },
  data: function () {
    return {
      selectDropdown: "",
      selectOptions: ["Simple"], // , /* "Vline", "Square", "Diamond", "Texture" */
      tipsText:
        "The Drawing tool is a smart design feature that allows you to create freehand drawings over your image.",
      settingsPanel: true,
      popupLayerPropVisible: false,
      drawParams: {
        isDrawing: true,
        isEraser: false,
        width: 10,
        opacity: 100,
        softness: 0, // 100
        color: "#ffffff",
        brush: "Simple",
      },
      maxWidthBrush: 140,
      drawingType: "draw",
      suckerRerender: 0,
      rendererInc: 1,
    };
  },
  mounted() {
    this.maxWidthBrush =
      Math.min(
        this.$lib.app.stage.width / this.$lib.app.zoomProcentage,
        this.$lib.app.stage.height / this.$lib.app.zoomProcentage
      ) * 0.5;
  },
  beforeDestroy() {
    this.$lib.Drawing.initDraw(false);
  },
  methods: {
    resetDrawing() {
      this.drawParams = {
        isDrawing: true,
        isEraser: false,
        width: 10,
        opacity: 100,
        softness: 0, // 100
        color: "#ffffff",
        brush: "Simple",
      };

      this.drawingType = "draw";
      this.suckerRerender = 0;
      this.rendererInc += 1;
    },

    undoLastDrawing() {
      this.$lib.Drawing.removeLastDraw();
      this.$store.dispatch("isUpdateCache");
    },
    async applyDrawing() {
      this.suckerRerender += 1;
      this.$store.dispatch("preloader", {
        message: "Wait...",
        isShow: true,
      });
      
      await this.$lib.Drawing.applyDrawing();
      await this.$lib.Drawing.initDraw(false);

      this.$store.dispatch("isUpdateCache");
      this.$store.dispatch("changesApplied");
      this.settingsPanel = true;
      this.drawParams.isDrawing = true;
      this.$store.dispatch("preloader", { isShow: false, message: "" });

      this.resetDrawing();
    },

    async changeDrawingType(type) {
      if (type == "eraser") {
        await this.$lib.Drawing.setEraser();
      } else {
        this.$lib.Drawing.setBrush(this.$lib.Drawing.brush);
      }
    },

    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },

    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },

    settingsShowHandle() {
      this.settingsPanel = !this.settingsPanel;

      this.suckerRerender += 1;
      this.$lib.Drawing.payloadCursor = {};
      this.$lib.Drawing.initDraw(!this.settingsPanel, {
        color: this.drawParams.color,
        width: this.$lib.lib.conv(this.drawParams.width, 1, this.maxWidthBrush),
        brush: this.drawParams.brush.toLowerCase(),
        opacity: this.drawParams.opacity / 100,
        maxWidthBrush: this.maxWidthBrush,
        softness: this.drawParams.softness,
      });      

      this.resetDrawing();
    },

    setBrushSize(value, isDragEnd) {
      this.$lib.Drawing.setSize((value / 100) * this.maxWidthBrush, !isDragEnd);
      this.drawParams.width = value;
    },

    setBrushColor({ hex }) {
      this.$lib.Drawing.setColor(hex);
      this.drawParams.color = hex;
    },

    setBrushType(brush) {
      this.$lib.Drawing.setBrush(brush.toLowerCase());
    },

    setOpacity(opacity) {
      this.drawParams.opacity = opacity;
      this.$lib.Drawing.setOpacity(opacity / 100);
    },

    setSoftness(softness) {
      this.drawParams.softness = softness;
      this.$lib.Drawing.setSoftness(softness);
    },

    removeDrawHandler() {
      this.settingsPanel = true;
    },

    openDropdown() {
      this.selectDropdown = "active";
    },

    selectDrop(value) {
      this.setBrushType(value);

      this.drawParams.brush = value;
      this.selectDropdown = "";
    },

    popupLayerPropShow() {
      return (this.popupLayerPropVisible = !this.popupLayerPropVisible);
    },
  },

  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyDrawing();
      } catch(err) {}
      this.$store.state._callbackSave += 1;
    }
  },

  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },

    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },
  },
};
</script>

<style scoped>
.full_w_btn.disabled {
  background: rgba(36, 154, 207, 0.5);
  color: rgba(255, 255, 255, 0.5);
}
</style>
