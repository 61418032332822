<template>
  <div class="popup" v-if="popUpVisible" v-custom-drag>
    <div class="popup_header">
      <h3>{{popUpLabel}}</h3>
      <a class="close_popup_btn" v-on:click="popUpPropChange(popUpLabel)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
    </div>
    <div class="popup_body">
      <div class="popup_body_row">
        <div class="popup_body_row_item">
          <ColorInput
            textLabel="Color"
            :defColor="frameParams.frame.color"
            :changeColorValue="changeColor"
            :checkmarkDisabled="true"
          />
        </div>
        <div class="double_btns_block popup_body_row_item">
          <div class="double_btns_left">
            <label class="text_label has_mb">Orientation</label>
            <div class="btns_wrpr">
              <a href="#" class="btn-action-left btn-action btn-action-w-74" v-on:click="changeAngle(-90)">
                <span class="inch-icon inch-rotate-left-icon"></span>
              </a>
              <a href="#" class="btn-action-right btn-action btn-action-w-74" v-on:click="changeAngle(90)">
                <span class="inch-icon inch-rotate-right-icon"></span>
              </a>
            </div>
          </div>
          <div class="double_btns_right">
            <label class="text_label has_mb">Flip</label>
            <div class="btns_wrpr">
              <a href="#" class="btn-action-left btn-action btn-action-w-74" v-on:click="flipXFrame()">
                <span class="inch-icon inch-flip-horizontal-icon"></span>
              </a>
              <a href="#" class="btn-action-right btn-action btn-action-w-74" v-on:click="flipYFrame()">
                <span class="inch-icon inch-flip-verical-icon"></span>
              </a>
            </div>
          </div>
        </div>
        <div class="popup_body_row_item">
          <Range
                  rangeSliderId="editOpacity"
                  :rangeSliderValue="frameParams.spr.frame"
                  rangeSliderLabel="Position"
                  rangeSliderClass="range-blue"
                  :minValue="-100"
                  :maxValue="100"
                  :defaultValue="0"
                  :changeValue="updatePosition"
          />
        </div>
        <!-- <div class="popup_body_row_item">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="frameParams.frame.alpha * 100"
            rangeSliderLabel="Opacity"
            rangeSliderMeasure="%"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            :changeValue="changeOpacity"
          />
        </div>-->
        <div class="popup_body_row_item">
          <div class="btns-group">
            <a tabIndex="0" href="#" class="grey_btn" @click="resetChanges()">Cancel</a>
            <a tabIndex="0" @click="$emit('applyFrame')" class="blue_btn">Apply</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Range from "@/components/Range.vue";
import ColorInput from "@/components/ColorInput.vue";

export default {
  name: "PopupFrames",
  props: {
    popUpVisible: Boolean,
    popUpPropChange: Function,
    popUpLabel: String,
    frameParams: Object,
    selectedFrameData: Object,
    updateFrameParams: Function
  },
  components: {
    Range,
    ColorInput
  },

  data: () => {
    return {
      configColor: null
    };
  },

  methods: {
    resetChanges() {
      let color = "#FFFFFF";

      if (
        this.selectedFrameData &&
        this.selectedFrameData.params &&
        this.selectedFrameData.params.color
      ) {
        color = this.selectedFrameData.params.color;
      }

      this.frameParams.frame.color = color;
      this.frameParams.spr.frame = 0;
      this.frameParams.frame.flip = undefined;
      this.frameParams.frame.angle = undefined;

      this.updateFrameParams(this.frameParams);

      this.popUpPropChange(this.popUpLabel);
    },

    changeColor(color) {
      this.frameParams.frame.color = color.hex;

      this.updateFrameParams(this.frameParams);
    },

    updatePosition(position) {
      this.frameParams.spr.frame = position;

      this.updateFrameParams(this.frameParams);
    },

    /* changeOpacity(alpha) {
      this.frameParams.frame.alpha = alpha / 100;

      this.updateFrameParams(this.frameParams);
    }, */

    flipXFrame() {
      this.frameParams.frame.flipX = !this.frameParams.frame.flipX;

      this.updateFrameParams(this.frameParams);
    },
    
    flipYFrame() {
      this.frameParams.frame.flipY = !this.frameParams.frame.flipY;

      this.updateFrameParams(this.frameParams);
    },

    changeAngle(angle) {
      this.frameParams.frame.angle = (this.frameParams.frame.angle || 0) + Number(angle);

      this.updateFrameParams(this.frameParams);
    }
  }
};
</script>
