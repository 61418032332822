<template>
    <ul v-if="dropdownMenuOption && dropdownMenuOption.list" :style="calculateStyle"
        class="dropdown_select_list vb-content dropdown_overflow">
        <li v-for="(item, index) in dropdownMenuOption.list" :class="{ active: isActiveItem(item) }"
            :key="index" @mousedown="selectItem(item)">
            {{ item.title }}
        </li>
    </ul>
</template>


<script>
    export default {
        name: 'DropdownMenuOverflow',
        data() {
            return { }
        },

        computed: {
            dropdownMenuOption: {
                get() { return this.$store.state.dropdownMenuOption; },
                set(val) { this.$store.state.dropdownMenuOption = val; }
            },

            calculateStyle() {
                if(!this.dropdownMenuOption.position) return {};
                const { left, top, width } = this.dropdownMenuOption.position;

                return {
                    left: `${left + width}px`, top: `${top}px`
                };
            },

            selectGlobalDropdown: {
                get() { return this.$store.state.selectGlobalDropdown; },
                set(val) { this.$store.state.selectGlobalDropdown = val; }
            }
        },

        methods: {
            isActiveItem(item) {
                if(this.dropdownMenuOption && this.dropdownMenuOption.current
                    && typeof this.dropdownMenuOption.current == 'object'
                    && this.dropdownMenuOption.current.item
                    && typeof this.dropdownMenuOption.current.item == 'object') {
                    return this.dropdownMenuOption.current.item.id == item.id;
                }

                return false;
            },

            selectItem(item) {
                const data = {
                    id: this.dropdownMenuOption.id,
                    index: this.dropdownMenuOption.index,
                    item, preset: this.dropdownMenuOption.preset
                };

                this.selectGlobalDropdown = data;
                console.log('DATA: ', JSON.parse(JSON.stringify(data)));
            }
        }
    }
</script>

<style scoped>
    .dropdown_overflow {
        width: 222px !important;
        overflow: hidden;
        max-height: 300px;
        position: absolute;
        background: red;
        z-index: 2;
        display: block;
    }
</style>