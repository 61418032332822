<style>
.m-success {
  background: #edfbf8 0% 0% no-repeat padding-box;
  border: 1px solid #4adaba;
  border-radius: 6px;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  color: #4adaba;
}
</style>
<template>
  <div>
    <div class="popup save_popup upgrade-popup sign-up" v-if="signupPopup">
      <a class="close_popup_btn" v-on:click="changePopup('checkoutRegister', false)">
        <span class="inch-icon inch-close-icon"></span>
      </a>
      <div class="m-success">Successfully Upgraded to Colorcinch Plus</div>
      <h2 class="title sm sign-up">Create your account</h2>
      <br />
      <form id="login_form" v-on:submit.prevent="onSubmit" v-if="isOnline">
        <div class="form_loading" v-if="isLoading"></div>

        <div class="form-group sign-up">
          <div class="label sign-up">Email</div>
          <input
            readonly
            type="email"
            ref="xemail"
            placeholder="Email address"
            name="email"
            class="form-control xemail sign-up"
            :value="email"
          />
        </div>
        <div class="form-group sign-up">
          <div class="label sign-up">Password</div>
          <input
            type="password"
            ref="xpassword"
            placeholder="Password"
            name="password"
            class="form-control sign-up"
            :value="password"
          />
        </div>
        <div class="form_errors" v-if="formErrors">
          <ul id="example-1">
            <li v-for="(item, index) in formErrors" :key="index">{{ item[0] }}</li>
          </ul>
        </div>

        <div class="btn-wrap sign-up" style="">
          <input
            type="submit"
            value="Create Account"
            class="btn-orange"
            style="padding: 15px 40px; width: 100%"
          />
        </div>
      </form>
    </div>
    <div
      class="popup save_popup upgrade-popup small width-padding"
      v-if="congratPopup"
    >
      <a class="close_popup_btn" v-on:click="closeAllPopups()">
        <span class="inch-icon inch-close-icon"></span>
      </a>
      <div class="icon margin-bottom">
        <img src="../assets/images/sun.png" alt />
      </div>
      <h2
        class="title sm title-success-signin"
        :style="{
          'font-weight': '600 !important',
          'font-size': '28px !important',
        }"
      >
        Thanks for joining! We're thrilled to have you!
      </h2>
      <p class="box-success-signin">
        You now have full access to Colorcinch Plus and everything it has to
        offer!
      </p>
      <!-- :style="{ 'margin-top': '120px !important' }" -->
      <div class="btn-wrap margin-top">
        <a
          @click="closeAllPopups"
          class="btn-orange"
          style="margin: 0; color: #fff"
          >Start Editing</a
        >
      </div>
      <span class="sm-descr">
        or visit
        <a href="/my-account">My Account</a> instead
      </span>
    </div>
  </div>
</template>


<script>
import Acc from "@/libs/acc.js";
const analitics = require("../libs/analytics");

//client@mail.com
//123123123

export default {
  name: "checkoutRegister",
  data() {
    return {
      signupPopup: false,
      congratPopup: false,
      isLoading: false,
      acc: new Acc(),
      formErrors: null,
      email: window.subEmail,
      password: "",
      name: "",
      plans: [],
      selectedPlanId: null,
    };
  },
  components: {
  },
  created(){
			this.plans = this.$store.state.plans || [];
			if (this.plans.length) {
				this.selectedPlanId = this.plans[0].id;
      }
	},
  mounted(){
	if (typeof window.subEmail != 'undefined') {
		this.signupPopup = true;
		this.congratPopup = false;
	}
	else{
		this.signupPopup = false;
    if (this.$store.state.USER) {
      this.selectedPlanId = this.$store.state.USER.plan_id;
      this.updateGTMEvent('purchase','visitor',this.$store.state.USER.subscribe_id);
			this.congratPopup = true;
		}
		else{
			this.congratPopup = false;
		}
	}
  },
  computed: {
    getSelectedPlan(){
				return this.plans.find(item => item.id == this.selectedPlanId) || [];
			}
  },

  methods: {
    updateGTMEvent(event,origin,transaction_id){
      const data = {
        currency: 'USD',
        value: this.getSelectedPlan.price ?? 0,
        items: [
          {
            item_id: this.getSelectedPlan.id ?? 0,
            item_name: this.getSelectedPlan.title ?? 0,
            price: this.getSelectedPlan.price ?? 0,
            origin,
          }
        ]
      }
      if(transaction_id) data.transaction_id = transaction_id

      analitics.sendCustomEvent(event,data);

    },
    async onSubmit() {
      this.isLoading = true;
      this.formErrors = null;
      var resp = await this.acc.registerCheckout({
        email: this.$refs.xemail.value,
        password: this.$refs.xpassword.value,
      });
      console.log("after register");

      this.formErrors = resp.errors;
      this.isLoading = false;

      if (resp.success) {
        console.log("resp.success", resp.success);
        analitics.sendCustomEvent("sign_up");
        resp.data.user.isPremium = true;

        if(this.$store.state.USER){
          this.selectedPlanId = this.$store.state.USER.plan_id;
					const subscriptionId = resp.data.customer.subscription.id ?? null
					if(this.$store.state.USER.status === 'free') this.updateGTMEvent('purchase','free_user',subscriptionId);
				} else {
          this.$store.state.USER = resp.data.user;
          this.selectedPlanId = this.$store.state.USER.plan_id;
					this.updateGTMEvent('purchase','visitor',resp.data.customer.subscription.id);
				}

        if (resp.data.user) {
          var metaTag = document.querySelector('meta[name="uniq_id"]');
          if (metaTag) {
              metaTag.setAttribute('content', resp.data.user.id);
          }
        }

        this.popupOpen("congratPopup");
        var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        await fetch("/createAffilate", {
					headers: {
						"X-CSRF-TOKEN": csrfToken,
					},
				}).then((r) => r.text());
      }
    },
    popupOpen(popupName) {
      (this.signupPopup = false),
        (this.congratPopup = false),
        (this[popupName] = true);
    },
    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },
    showLoginPopUp() {
      this.closeAllPopups();
      this.$store.dispatch("popupsToggle", {
        property: "signIn",
        value: true,
      });
    },
    closeAllPopups() {
      this.$store.dispatch("popupsToggle", {
        property: "checkoutRegister",
        value: false,
      });
      this.$router.push({ name: "home" });

      //   this.$store.dispatch("popupsToggle", {
      //     property: "checkoutRegister",
      //     value: false,
      //   });
    },
  },
};
</script>

<style scoped>
.form_loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #8080809c;
  z-index: 10000;
}

.btn-orange {
  border: none;
}
.form_errors {
  /*color: red;*/
  /*font-weight: bold;*/
  text-align: left;
  font: normal normal normal 14px/34px Soleil;
  color: #ff5353;
}
.popup.save_popup.upgrade-popup.sign-up .title {
  font-size: 26px;
}

.form-group .label {
  font-size: 13px !important;
}

.popup.save_popup.upgrade-popup .btn-wrap a {
  margin-bottom: 12px;
  display: inline-block;
  text-transform: uppercase;
  /*color: #1d1d1d;*/
  font: normal normal normal 14px/18px Soleil;
  letter-spacing: -0.28px;
  color: #ffffff;
}

.popup.save_popup.upgrade-popup .bottom-info.sign-up {
  display: inline-block !important;
  text-align: left;
  font: normal normal normal 14px/18px Soleil;
  letter-spacing: -0.28px;
  color: #000000;
}
.popup.save_popup.upgrade-popup .bottom-info.sign-up {
  margin-top: 16px;
}
.popup.save_popup.upgrade-popup .bottom-info.sign-up span {
  opacity: 0.4;
}
.popup.save_popup.upgrade-popup .bottom-info.sign-up a {
  /*margin-bottom: 12px;*/
  display: inline-block;
  text-transform: none;
  color: #fa6400 !important;
  text-align: left;
  text-decoration: underline;
  letter-spacing: -0.28px;
  margin: 0;
}
.signup-description {
  text-align: center;
  font: normal normal normal 16px/24px Soleil;
  letter-spacing: -0.32px;
  color: #000000;
  opacity: 0.4;
  padding: 16px 5px 20px;
}
</style>
