<template>
  <span><div class="circle-drawing" :style="drawingStyle"></div></span>
</template>

<script>
import storage from "@/libs/storage.js";
import indexdb from '../libs/indexdb';

export default {
  name: "InitLib",
  components: {},
  props: {},

  data() {
    return {
      selectedLayerCacheId: null,
      isInitialFavorites: false,
      isLockHistory: false,
      autosaveInterval: false,
      drawEvent: false
    };
  },

  mounted() {
    this.$lib._cache.updatecallback = () => {
      this.$store.state.autosave.qq += 1;
    };

    this.updateAutosave();

    document.addEventListener("keydown", async (e) => {
    	//console.log(e.keyCode);
		if (e.keyCode == 27) {
			e.preventDefault();
			
			const closeAllPops = store => {
				for(const key in store.state.popups) {
					store.state.popups[key] = false;
				}				
			}
			
			closeAllPops(this.$store);
			return;
		}
    
      const isFocus = !!Array.from($("input")).find((el) => $(el).is(":focus"));
      if (!this.$lib.app || isFocus) return;

      const keyCode = e.keyCode;
      this.$store.state.keyDownList.push(keyCode);

      //if(keyCode == 32) e.preventDefault();
      // add cursor
      if (keyCode == 17 && this.$lib.fabric.isZoomMode) {
        this.$lib.app.fabricCanvas.defaultCursor = "pointer";
        this.$lib.app.fabricCanvas.selection = false;
      }

      if (keyCode == 187) {
        // zoom out
        this.incrementZoom(10);
        return;
      } else if (keyCode == 189) {
        // zoom in
        this.incrementZoom(-10);

        return;
      }

      const isShift = !!this.$lib.fabric.keyboardCodes.find((k) => k == 16);
      const isCtrl = !!this.$lib.fabric.keyboardCodes.find((k) => k == 17);

      this.$lib.fabric.keyboardCodes.push(keyCode);
      this.$lib.fabric.keyboardActions(keyCode);

      if (isCtrl && keyCode == 90) {
        // ctrl + z
        if (this.$lib.Drawing.isDrawingMode) {
          if (!isShift) {
            this.$lib.Drawing.removeLastDraw();
          }

          return;
        }

        let step = "prev";
        if (isShift) {
          step = "next";
        }

        this.isLockHistory = true;
        await this.$lib._cache.showChangeByStep(step);
        this.$store.dispatch("isUpdateCache");
        this.isLockHistory = false;
      }
    });

    document.addEventListener("keyup", (e) => {
      if (!this.$lib.app) return;
      if (e.keyCode == 32) e.preventDefault();

      this.$store.state.keyDownList = this.$store.state.keyDownList.filter(
        (key) => key != e.keyCode
      );

      // remove cursor
      if (e.keyCode == 17 && this.$lib.fabric.isZoomMode) {
        this.$lib.app.fabricCanvas.defaultCursor = "default";
        this.$lib.app.fabricCanvas.selection = true;
      }

      this.$lib.fabric.keyboardCodes = this.$lib.fabric.keyboardCodes.filter(
        (c) => c != e.keyCode
      );
    });

    this.crashHandler();
  },

  methods: {
    crashHandler() {
      // if(sessionStorage.getItem('good_exit') &&
      //     sessionStorage.getItem('good_exit') !== 'true') {
      //       this.loadStorageProject();
      // }

      sessionStorage.setItem('good_exit', 'pending');
      window.addEventListener('beforeunload', function () {
          sessionStorage.setItem('good_exit', 'true');
          indexdb.deleteAll('autosave');
      });      
    },
    
    changePopup(property, value) {
			this.$store.dispatch("popupsToggle", { property, value });
		},

    setupPreloader(message, isShow) {
			this.$store.dispatch("preloader", { isShow, message });
		},

    setupPreloadedAndHide(message, time) {
			this.setupPreloader(message, true);

			setTimeout(() => this.setupPreloader("", false), time);
		},

    async loadStorageProject() {
      const autosaves = await indexdb.getAll('autosave');
      const lastSave = autosaves[autosaves.length - 1];
      if(lastSave && lastSave.base64) {
        this.setupPreloader("Importing project 0%", true);

        this.changePopup("projectManager", false);
        this.$store.state.openAbove = 1;
        await new Promise((resolve, reject) => {
          this.$router.push({
            path: this.$store.state.routerPrefixURL + "/image-manager",
            hash: this.$route.hash
          }, resolve, resolve);
          // this.$router.push(this.$store.state.routerPrefixURL + "/image-manager", resolve, resolve);
        });

        await this.$store.dispatch("isInitialLib", true);
        const blob = this.$lib.lib.b64toBlob(lastSave.base64);
        this.$lib._project.uploadProject(blob, (err, data = {}) => {
					this.setupPreloader(
						data.progress || data.progress === 0
							? `Importing project ${parseInt(data.progress)}%`
							: null,
						true
					);

					// \n${data.size ? "(" + data.size + " byte)" : ""}

					if (err) {
						console.error(err);
						this.setupPreloadedAndHide("Error project upload!", 1000);
					}

					if (data.isCompleted) {
						this.setupPreloadedAndHide("Project imported!", 1000);
						this.$store.dispatch("isUpdateCache");

						this.$store.dispatch("zoomDashboard", this.$lib.app.zoomProcentage);
						this.$lib.app.addTransparentPNGMask();
						this.$store.state.sidebarPanel.panelName = "Edit";
					}
				});
      }
    },

    async resetAutosave () {
        await indexdb.deleteAll('autosave');

        this.$store.state.autosave = {
          loading: false,
          isHaveChanges: false,
          project: false,
          lastSaveId: false,
          qq: 1
        };
    },

    async updateAutosave(enable=true) {
    	if(enable==false && this.autosaveInterval){
    		console.log("auto save disabled manualy?");
    		clearInterval(this.autosaveInterval);
    		return;
    	}
    
      clearInterval(this.autosaveInterval);
      if(!this.isPremium) return;
      
      if(!this.$store.getters.SETTINGS.enableAutosave){
      	return;
      }
      
      this.autosaveInterval = setInterval(async () => {
        if(!this.hasSave || location.pathname == '/') {
          // if(this.$store.state.autosave.qq != 1) {
          //   console.log('resetAutosave');
          //   this.resetAutosave();
          // }

          return;
        }

        console.log('TRRRR');

        this.$store.state.autosave.loading = true;
        try {
          clearInterval(this.autosaveInterval);

          const historyChanges = this.$lib._cache.getHistoryChanges();
          const lastSave = historyChanges[historyChanges.length - 1];

          const projectSave = this.$store.state.projectSave;
          let saveProjectPayload = { };
          if(projectSave && typeof projectSave == 'object') {
            saveProjectPayload = {
              id: projectSave.id || projectSave.project_id,
              pname: projectSave.name,
              name: projectSave.name,
              project_id: projectSave.id || projectSave.project_id
            };
          }

          const data = {
            ...(await _LIB._project.downloadProjectCache(undefined, false)),
            id: Date.now(), lastSave: lastSave._id,
            ...saveProjectPayload
          };

          await indexdb.deleteAll('autosave');
          await indexdb.add('autosave', data);

          if(projectSave && projectSave.project_id && !projectSave._isLocal) {
            await this.$store.dispatch("saveProject", { ...data,
              //...saveProjectPayload
            });
          }

          this.autosave.lastSaveId = lastSave._id;
          this.$store.state.autosave.loading = false;

          this.updateAutosave();
        } catch (err) {
          this.resetAutosave();
        }
      }, 5 * 60 * 1000);
    },

    incrementZoom(icr) {
      if(this.$lib.app.fabricCanvas.getActiveObject() && this.$lib.app.fabricCanvas.getActiveObject().isEditing) return;
      let zoom = this.$lib.app.zoomProcentage + icr / 100;
      if (zoom < 0.1) return;

      this.$lib.app.scaleStage(zoom);
      this.$lib.app.setZoomProcentage(zoom, true);

      this.$store.state.global.zoomDashboard = zoom;
      this.$store.state.global.zoomDashboardCounter += 1;
    },

    async initialLib(isInitial) {
      if (!this.$lib.app && isInitial) {
        await this.$lib.initialLib();
        this.$store.getters.SETTINGS.enableAutosave = localStorage.getItem('autoSaveEnabled') == 1;

        await this.initFavorites();
        this.addEventsOnFabric();
        //.renderAll();

        await this.$store.dispatch("isInitialLib");

        this.$lib.fabric.drawingCanvas.on('mouse:move', e => {
            this.drawEvent = { x: e.e.pageX, y: e.e.pageY, size: 50 };
        });

        this.$lib.fabric.drawingCanvas.on('mouse:out', e => {
            this.drawEvent = false;
        });
      }
    },

    mouseDownEvent(e) {
      this.$store.state.incrBodyDownFabric += 1;

      if (e.target) {
        if (!e.target._isFrame && !e.target._cropFrame) {
          if (
            this.selectedLayerCacheId &&
            this.selectedLayerCacheId !== e.target.cacheKey
          ) {
            this.changeSelectPopupLayer(e.target, true);
          }
        }
      }
    },

    async initFavorites() {
      const arr = [{
          store: "overlays",
          api: "overlays",
        }, {
          store: "filters",
          api: "filters",
        }, {
          store: "masks",
          api: "masks",
        }, {
          store: "frames",
          api: "frames",
        }, {
          store: "effects",
          api: "effects",
        },
      ];

      const favorites = storage.getItem("favorites");
      if (favorites && typeof favorites == "object") {
        for (const fav of arr) {
          if (favorites[fav.api]) {
            this.$store.state.favorites[fav.store] = favorites[fav.api];
          }
        }
      }

      // Graphics
      const graphics = storage.getItem('myGraphics');
      if(graphics && graphics.length) {
        this.$store.state.popupGraphicsData = graphics.map(gr => {
          gr.image = undefined;

          return gr;
        }).filter(gr => (gr.isPremium && this.$store.state.isPremiumAccount) || !gr.isPremium);
      }

      if (
        this.$store.state.USER &&
        this.$store.state.USER.email &&
        !this.isInitialFavorites
      ) {
        this.isInitialFavorites = true;

        for (const item of arr) {
          if (item.store != "effects") {
            try {
              this.$store.state.favorites[item.store] = await fetch(
                `${this.$store.state.prefixURL}/api/${item.api}/favorites`
              ).then((res) => res.json());
            } catch (err) {
              console.error(err);
            }
          }
        }
      }
    },

    addEventsOnFabric() {
      this.$lib.app.setFabricEvents([
        [
          "object:modified", e => {
            this.$store.dispatch("isUpdateCache");
          },
        ],
        [
          "object:scaling", (event) => {
            if (event && event.target && /^text-/.test(event.target.cacheKey)) {
              this.$store.state.textEvent = {
                ...event.transform,
                cacheKey: event.target.cacheKey,
              };
            }
          },
        ],
        [
          "object:rotating", (event) => {
            if (event && event.target && /^text-/.test(event.target.cacheKey)) {
              this.$store.state.textEvent = {
                ...event.transform,
                cacheKey: event.target.cacheKey,
              };
            }
          },
        ],
        [
          "mouse:down", (e) => {
            this.mouseDownEvent(e);
          },
        ],
        [
          "mouse:up", () => {
            this.$store.state.incrBodyDownFabric += 1;

            setTimeout(() => {
              this.mouseDownEvent({ target: this.$lib.app.fabricCanvas.getActiveObject() });
            }, 100);
          },
        ],
        [
          "selection:created", (e) => {
            //console.log("selection:created");
            if (e.target && !e.target._isFrame) {
              this.changeSelectPopupLayer(e.target, true);
              this.$store.state.selectedLayer = e.target;
            }
          },
        ],
        [
          "object:added", e => {
            setTimeout(() => {
              this.$store.dispatch("isUpdateCache");
            }, 500);
          },
        ],
        // [
        //   "text:editing:exited",
        //   (event) => {
        //     const target = event.target;
        //     if (!target || target._textBeforeEdit == target.text) return;

        //     this.$lib.fabric.onMidifiedFabric(event);
        //   },
        // ],
        [
          "object:removed", () => {
            this.$store.state.global.showPropertiesGraphics = false;
            this.$store.state.global.popupLayerPropVisible = false;
            this.$store.state.selectedLayer = false;

            setTimeout(() => {
              this.$store.dispatch("isUpdateCache");
            }, 500);
          },
        ],
        [
          "selection:cleared", (e) => {
            this.$store.state.selectedLayer = false;
            // console.log("selection:cleared", e.deselected);
            if (e.deselected) {
              this.changeSelectPopupLayer(e.deselected);
            }

            if (e.deselected && e.deselected.length) {
              for (const deselected of e.deselected) {
                if (deselected._isFrame) {
                  this.$lib.app.fabricCanvas.remove(deselected);
                }
              }
            }
          },
        ],
        /* [
          "path:created",
          () => {
            this.$store.dispatch("isUpdateCache");
          }
        ] */
      ]);
    },

    async destroyCanvas() {
      this.resetAutosave();
      this.updateAutosave();

      const isWhile = true;
      let count = 0;
      while (isWhile) {
        count += 1;
        this.$lib.app.renderer.texture.managedTextures.forEach((t) => {
          try {
            t.destroy(true);
          } catch (err) {
            console.log(err);
          }
        });

        if (
          this.$lib.app.renderer.texture.managedTextures.length == 1 ||
          count >= 100
        ) {
          console.log("Reset canvas");
          break;
        }
      }

      try {
        this.$store.state.sidebarPanel.panelName = "Edit";
        this.$lib.app.renderer.destroy();
        this.$lib.app.fabricCanvas.dispose();
        this.$lib.app = undefined;
        this.$lib.PIXI.Loader.shared.resources = {};
        this.$store.state.isInitialLib = false;
      } catch (err) {
        console.error("Destroy error", err);
      }
    },

    isTextObjects(target) {
      return target._objects && target._objects.length
        ? target._objects.reduce((isText, obj) => {
            if (!/^text-/g.test(obj.cacheKey)) {
              isText = false;
            }

            return isText;
          }, true)
        : /^text-/g.test(target.cacheKey);
    },

    async changeSelectPopupLayer(target, isShow) {
      if (target._cropFrame || target._maskText) return;
      const isTextGroup = this.isTextObjects(target);

      if (isShow) {
        await this.$store.dispatch("globalLayerPopUpParams", {
          opacity: parseInt(target.opacity * 100),
        });
        this.dispatchBlendMode(target);

        if (target._name === "graphics") {
          //this.$store.state.sidebarPanel.panelName = "Graphics";
          this.$store.state.global.showPropertiesGraphics = true;
          this.$store.state.global.popupLayerPropVisible = false;
        } else if ((/^text-/g.test(target.cacheKey) || isTextGroup) && !target._maskText) {
          this.$store.dispatch("textPanelUpdate");
          this.$store.state.sidebarPanel.panelName = "Text";
          this.$store.state.global.isTextPanelDisabled = false;
        } else {
          //this.$store.state.sidebarPanel.panelName = "Image manager";
          this.$store.state.global.popupLayerPropVisible = true;
          this.$store.state.global.showPropertiesGraphics = false;
        }

        this.$store.state.sidebarPanel.panelClass = "active";
        this.selectedLayerCacheId = target.cacheKey;

        //this.$store.dispatch("isUpdateCache");
      } else {
        await this.$store.dispatch("globalLayerPopUpParams", {});

        if (target.find((t) => t._name == "graphics")) {
          this.$store.state.global.showPropertiesGraphics = false;
        }

        if (target.find((t) => /^text-/g.test(t.cacheKey)) || isTextGroup) {
          this.$store.state.global.isTextPanelDisabled = true;
          this.$store.dispatch("textPanelUpdate");
        }

        this.$store.state.global.popupLayerPropVisible = false;

        this.selectedLayerCacheId = null;
        //this.$store.dispatch("isUpdateCache");
      }
    },

    async dispatchBlendMode(target) {
      if (!target) return;
      if (target.type == "group") {
        return this.dispatchBlendMode((target.getObjects() || [])[0]);
      }

      if (target.filters) {
        const blendMode = target.filters.find(
          (filter) => filter && filter._isBlendMode
        );
        if (blendMode) {
          let intensity = parseInt(blendMode.alpha * 100 || 0);
          /*  if (intensity < 50) {
            intensity = parseInt((100 - intensity * 2) * -1);
          } else {
            intensity = parseInt(intensity * 2 - 100);
          } */

          await this.$store.dispatch("globalLayerPopUpParams", {
            ...this.$store.state.globalLayerPopUp.params,
            blendMode: blendMode.mode,
            color: blendMode.color,
            intensity,
          });
        }
      }

      if (target.globalCompositeOperation) {
        const blendModes = this.$store.state.globalLayerPopUp.blendModes.filter(
          (bm) => bm !== "_divider"
        );

        const blendMode = blendModes.find(
          (bm) =>
            bm.toLowerCase().replace(/ /g, "-") ==
            target.globalCompositeOperation
        );
        if (blendMode) {
          await this.$store.dispatch("globalLayerPopUpParams", {
            ...this.$store.state.globalLayerPopUp.params,
            blendMode,
          });
        }
      }
    },
  },

  watch: {
  	'$store.getters.SETTINGS.enableAutosave':{
  		handler(val) {
        if(val) {
          this.updateAutosave(this.$store.getters.SETTINGS.enableAutosave);
          
          const historyChanges = this.$lib._cache._storeData;
          const lastSave = historyChanges[historyChanges.length - 1];

          if(lastSave) this.autosave.lastSaveId = lastSave._id;
        } else {
          this.resetAutosave();
          // this.$store.state.autosave.isHaveChanges = false;
        }
        
  			// this.updateAutosave(this.$store.getters.SETTINGS.enableAutosave);
  		}
  	
  	},
  
    isPremium(val) {
      if(val) {
        this.updateAutosave()
      }
    },

    hasSave(val) {
    	if(!this.$store.getters.SETTINGS.enableAutosave){
    		this.$store.state.autosave.isHaveChanges = false;
    	} else {
      		this.$store.state.autosave.isHaveChanges = !!val;
      }
    },

    async getInitStatus(isInitial) {
      await this.initialLib(isInitial);
    },

    async resetCanvas() {
      await this.destroyCanvas();
    },
    async USER() {
      this.$store.state.isPremiumAccount = await this.$store.dispatch("isPremiumCurrentUser");
      await this.$store.dispatch('ADINFO');
      this.initFavorites();
    },
  },

  computed: {
    earseConfig() {
      return this.$store.state.earseConfig;
    },

    drawingStyle() {
			const drawEvent = this.drawEvent;
			const drawEventVisible = this.$store.state.drawEventVisible;
      const earseConfig = this.earseConfig || {};

			if(!drawEvent || !drawEventVisible) return {};
      const css = _LIB.lib.getStylesObject($(".centered-content"));
      const cssZoom = parseFloat((css.transform || '').replace(/[a-zA-Z]|\(|\)/g, ''));

      const zoom = cssZoom ? cssZoom : _LIB.app.zoomProcentage;

      const size = (earseConfig._size || 50) * zoom;
			return {
				display: 'block',
				left: `${drawEvent.x - (size / 2)}px`,
				top: `${drawEvent.y - (size / 2)}px`,
				width: `${size}px`,
				height: `${size }px`,
				'pointer-events': 'none',
				'z-index': '999999'
			};
		},

    projectSave() {
      return this.$store.state.projectSave;
    },

    storeData() {
      if(!this.$lib || !this.$lib._cache || !this.$lib._cache._storeData) return [];
      return this.$lib._cache._storeData;
    },

    hasSave() {
      const nextTick = this.$store.state.autosave.qq + 1;
      
      let result = true;
      if(!this.$lib || !this.$lib._cache || !this.$lib._cache._storeData) {
      	result = false;
      }

      const historyChanges = this.$lib._cache._storeData;
      const lastSave = historyChanges[historyChanges.length - 1];
      if(!lastSave || lastSave.mapId == 10){
      	result = false; 
      }
      
      if(!this.$lib.app || lastSave._id == this.autosave.lastSaveId) {
      	result = false;
      }
      
//      console.log("return true?");
      // if(!this.$store.getters.SETTINGS.enableAutosave){
      //     result = false;
      // }

      return result;
    },

    autosave: {
      get() { return this.$store.state.autosave; },
      set(val) { return this.$store.state.autosave = val; }
    },

    isPremium() {
      if(!this.USER) return false;

      return !!this.USER.isPremium || this.USER.role == 'superadmin';
    },

    USER() {
      return this.$store.state.USER;
    },


    getInitStatus() {
      return this.$store.state.isInitialLib;
    },

    resetCanvas() {
      return this.$store.state.resetCanvas;
    },
  },
};
</script>

<style scoped>
  .circle-drawing {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #f00;
  }
</style>
