<template>
	<div class="popup graphics_popup project_manager_popup">
		<div class="popup_header">
			<h3>project manager</h3>
			<a
				class="close_popup_btn"
				v-on:click="changePopup('projectManager', false)"
			>
				<span class="inch-icon inch-close-icon"></span>
			</a>
		</div>
		<div class="popup_body" v-if="isOffline">
			<div class="is_offline">
				<Offline></Offline>
			</div>
		</div>
		<div class="popup_body" v-if="isOnline">
			<div class="graphics_popup_left">
				<div class="panel_row">
					<div class="input_with_icon">
						<input
							type="text"
							class="text_input search_input"
							style="padding-right: 36px"
							@input="filterProjectsByText($event)"
							placeholder="Search projects"
						/>
						<span class="inch-search-icon input_icon"></span>
					</div>
				</div>
				<ul class="categories_list">
					<li>
						<span>recent projects</span>
					</li>

					<li v-for="item in getLocalProjects" v-bind:key="item.id">
						<a v-if="!item.hide" v-on:click="selectProjectToOpen(item.id)">
							<template v-if="!item._isLocal">{{ item.name }}</template>
							<template v-else>Untitled Project</template>
						</a>
					</li>
				</ul>
			</div>

			<div class="graphics_popup_right">
				<div class="project_items_wrpr" :style="{ 'justify-content': 'left' }">
					<template v-for="item in getLocalProjects">
						<div
							:ref="'project-ref-' + item.id"
							:style="{
								width: '275px',
								height: '275px',
								background: `url(${item._isLocal ? item.thumb : 'storage/' + item.thumbnail}) #000 center center / cover no-repeat`,
							}"
							class="thumb-wrap project radius-4 hover-overlay project_item"
							v-if="!item.hide"
							v-bind:key="item.id"
						>
							<h3 class="project_item_title">
								<template v-if="!item._isLocal">{{ item.name || item.pname }}</template>
								<template v-else>Untitled Project</template>
							</h3>

							<div class="date_print">{{ item.date_plain ? parseDatePlain(item.date_plain) : 'Not saved on the server' }}</div>

							<div
								class="thumb-icons hidden bottom-right"
								:style="{ 'z-index': 7 }"
							>
								<span v-if="!item._isLocal"
									class="delete inch-icon active inch-trash-icon"
									v-on:click="deleteProject(item.id, true)"
								>
									<div class="thumb-tooltip">
										<p>Delete</p>
									</div>
								</span>
							</div>
							<div
								class="project_item_handle"
								:style="{ 'z-index': 6 }"
								@click="selectProjectToOpen(item.id)"
							></div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Offline from "@/components/Offline.vue";
import indexdb from '../libs/indexdb';

export default {
	name: "ProjectManager",
	props: {
		showLoader: Function,
	},

	components: {
		Offline,
	},

	methods: {
		parseDatePlain(str) {
			const monthNames = ["January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
			];

			const date = new Date(str);
			return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
		},

		filterProjectsByText(event) {
			const findText = event.target.value || "";
			this.filterText = findText.toLowerCase();

			if (!this.filterText) {
				this.userProjects = this.userProjects.map((pj) => {
					pj.hide = false;

					return pj;
				});
			} else {
				this.userProjects = this.userProjects.map((pj) => {
					pj.hide = true;
					if (pj.name && (pj.name || '').toLowerCase().includes(this.filterText)) {
						pj.hide = false;
					}

					return pj;
				});
			}
		},

		setupPreloader(message, isShow) {
			this.$store.dispatch("preloader", { isShow, message });
		},

		setupPreloadedAndHide(message, time) {
			this.setupPreloader(message, true);

			setTimeout(() => this.setupPreloader("", false), time);
		},

		selectProjectToOpen(id) {
			if (!this.$lib || !this.$lib.app) {
				this.openProject(id);
			} else {
				this.selectedId = id;
				this.$store.dispatch("confirmPopUpConfig", {
					isHide: false,
					popupTitle: "Confirm Importing Project",
					popupBody:
						"Are you sure you would like to continue? Any unsaved changes will be lost when you import a new project.",
					name: "loadProject",
				});
			}
		},

		loadFontList() {
			console.log('loadFontList runs');
			let fonts = '';
			
			this.$store.state.fonts.forEach(font => {
				
				fonts += `@font-face {
					font-family: "${font.title.replace(/[0-9]/g, '').trim()}";
					src: url("${font.url}") format("woff2")
				}\n`;
			});

			this.$store.state.currentFont = this.$store.state.fonts[0].title;

			const styleElement = document.createElement('style');
			styleElement.innerHTML = fonts;
			document.head.appendChild(styleElement);
		},

		async openProject(id) {
			if (!id) return;
			const project = this.getLocalProjects.find(pj => pj.id == id);
			this.setupPreloader("Importing project 0%", true);
			let b = false;
			if(project && project._isLocal) {
				b = project;
			} else {
				var token = document
					.querySelector('meta[name="csrf-token"]')
					.getAttribute("content");
				let user_id = document.head.querySelector("[name=uniq_id][content]")
					.content;
				b = await fetch(
					`${this.$store.state.prefixURL}/projects/get/${id}` +
						"?user_id=" +
						user_id,
					{
						method: "GET",
						headers: {
							"X-CSRF-TOKEN": token,
							"Content-Type": "application/json",
						},
					}
				).then((response) => {
					return response.json();
				});

				if(!b.success) return this.setupPreloadedAndHide("Error project upload!", 1000);
			}

			this.changePopup("projectManager", false);
			this.$store.state.openAbove = 1;
			await new Promise((resolve, reject) => {
				this.$router.push({
					path: this.$store.state.routerPrefixURL + "/image-manager",
					hash: this.$route.hash
				}, resolve, resolve);
				// this.$router.push(this.$store.state.routerPrefixURL + "/image-manager", resolve, resolve);
			});

			//if(!b.success) alert('ERROR ');
			// reset
			// await this.$store.dispatch("resetCanvas");
			await this.$store.dispatch("isInitialLib", true);
			await this.$store.dispatch("fontsList", {});
			this.loadFontList();
			this.$lib._project.uploadProject(
				this.$lib.lib.b64toBlob(b.base64),
				(err, data = {}) => {
					this.setupPreloader(
						data.progress || data.progress === 0 ? `Importing project ${parseInt(data.progress)}%` : null,
						true
					);

					// \n${data.size ? "(" + data.size + " byte)" : ""}

					if (err) {
						console.error(err);
						this.setupPreloadedAndHide("Error project upload!", 1000);
					}

					if (data.isCompleted) {
						this.setupPreloadedAndHide("Project imported!", 1000);
						this.$store.dispatch("isUpdateCache");
						setTimeout(function(){
							this.$lib.app.fabricCanvas.renderAll();
						}.bind(this),1800)

						this.$store.dispatch("zoomDashboard", this.$lib.app.zoomProcentage);
						this.$lib.app.addTransparentPNGMask();
						// this.$store.state.sidebarPanel.panelName = "Edit";
						this.$store.dispatch("sidebarPanelToggle", {
							panelName: this.hash ?? (this.isMobileView ? 'Effects' : 'Edit'),
							panelClass: "active"
						});

						const pj = this.getLocalProjects.find(glp => glp.id == id);
						if(pj) this.$store.state.projectSave = { ...pj, project_id: pj.id };
					}
				}
			);
		},
		changePopup(property, value) {
			this.$store.dispatch("popupsToggle", { property, value });
		},

		async deleteProject(id, isConfirm) {
			if (isConfirm) {
				this.selectedDelId = id;
				this.$store.dispatch("confirmPopUpConfig", {
					isHide: false,
					popupTitle: "DELETE PROJECT?",
					popupBody:
						"Are you sure you would like to delete this project? This change can’t be undone.",
					name: "deleteProject",
				});

				return;
			}

			if (!id) return;
			var token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute("content");
			this.$refs["project-ref-" + id][0].remove();
			//						e.target.closest(".project").remove();

			await fetch(`${this.$store.state.prefixURL}/projects/delete/${id}`, {
				method: "POST",
				body: JSON.stringify({
					user_id: document.head.querySelector("[name=uniq_id][content]")
						.content,
				}),
				headers: { "X-CSRF-TOKEN": token, "Content-Type": "application/json" },
			});

			this.$store.dispatch("getUserProjects", true);
		},
	},
	mounted: async function () {
		this.isMobileView =  this.$store.state.isMobileView;
		const localProjects = await indexdb.getAll('autosave');
		if(localProjects) {
			this.localProjects = localProjects.map(lp => ({ ...lp, _isLocal: true })) || [];
		}

		this.$store.dispatch("getUserProjects", true);
		//this.userProjects = this.$store.state.userProjects;
	},
	computed: {
		getProjects() {
			//this.showLoader("test 1", "test", 1000, "");

			return this.$store.state.userProjects;
		},
		getLocalProjects() {
			const filteredLocalProjects = (this.localProjects || [])
				.filter(q => !this.userProjects.find(up => (up.id == q.project_id)
					|| up.id == q.id));

			return [...filteredLocalProjects, ...this.userProjects];
		},
		getConfirmPopUpAnswer() {
			return this.$store.state.confirmPopUp.answer;
		},
		hash() {
			const hash = this.$route.hash.split('#')[1]
			if(hash) return `${hash[0].toUpperCase()}${hash.substring(1)}`.replace('-',' ');
			return null;
		},
	},

	watch: {
		getProjects(projects) {
			this.userProjects = projects || [];
			this.filterProjectsByText({ target: { value: this.filterText } });
		},

		getConfirmPopUpAnswer(answer) {
			if (answer && answer.name && answer.result) {
				if (answer.name === "loadProject") {
					this.openProject(this.selectedId);
				}

				if (answer.name === "deleteProject") {
					this.deleteProject(this.selectedDelId);
				}
			}
		},
	},
	/*		watch: {
				getProjects(val){

				}
		}, */
	data: function () {
		return {
			isMobileView: false,
			localProjects: [],
			userProjects: [],
			filterText: "",
			selectedId: null,
		};
	},
};
</script>

<style scoped>
	.graphics_popup {
		height: calc(100vh - 80px) !important;
		width: calc(100vw - 100px) !important;
	}

	.popup_body {
		height: 100% !important;
	}

	.date_print {
		position: absolute;
		bottom: 12px;
		left: 12px;
		color: #fff;
		z-index: 23;
		opacity: 0;
	}

	.project_item:hover .date_print {
		opacity: 1;
	}

	.project_item:hover .project_item_title {
		z-index: 23;
	}
</style>>
