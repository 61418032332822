<template>
  <div class="upload_imgs_item" v-if="uploadImgShow">
    <div
      class="hover_dropdown_list_close_block"
      v-on:click="uploadImgMenuHandle"
      v-if="uploadImgMenu"
    ></div>
    <ul
      :style="popupStyle"
      class="hover_dropdown_list"
      v-bind:class="{ active: uploadImgMenu }"
    >
      <li>
        <a
          v-on:cl1ick="popUpImgPropChange(), uploadImgMenuHandle()"
          v-on:click="addImageToProject(imgPath)"
          >Add to Project</a
        >
      </li>
      <li class="bordered-li">
        <a v-on:click="newProject(imgPath, true)">Start as New Project</a>
      </li>
      <li>
        <a class="redlink" v-on:click="uploadImgDelete" :eid="eid">Remove</a>
      </li>
    </ul>
    <div
      class="thumb-wrap image radius-4 hover-overlay"
      :data-image="imgPath"
      @dragstart="dragLayerStart"
      draggable="true"
      :style="{
        background: `url('${imgThumb || imgPath}') rgba(0,0,0,0) center center / cover no-repeat`,
        width: '109px',
        height: '109px',
        'background-size': 'cover',
      }"
      v-on:click="uploadImgMenuHandle"
      v-bind:class="{ active: uploadImgMenu }"
    >
      <div
        class="thumb-icons hidden top-right thumb-z"
      >
        <span
          class="inch-icon active inch-close-icon"
          :eid="eid"
          v-on:click="uploadImgDelete"
        ></span>
      </div>
      
      <div class="thumb-icons hidden thumb-z drag-or-click">
        <span class="tooltip-block">Drag or Click</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadImgsItem",
  props: {
    eid: String,
    imgPath: String,
    imgThumb: String,
    img: HTMLImageElement,
    popUpImgPropChange: Function,
  },
  data: function () {
    return {
      uploadImgMenu: false,
      uploadImgShow: true,
      popupStyle: {
        left: "30px",
        top: "65px",
        position: "absolute",
        "z-index": 99999,
      },
      base64Layer: "",
    };
  },
  methods: {
    dragLayerStart(e) {
      this.uploadImgMenu = false;
      const img = $(e.target)[0]; //this.$lib.lib.resizeDragImage(this.img);

      $(img).find(".thumb-icons").css({ display: "none" });

      const data = {
        src: img.getAttribute("data-image"),
        layerType: "layer",
        width: $(img).width(),
        height: $(img).height(),
        offsetX: $(img).width() / 2,
        offsetY: $(img).height() / 2,
        positionAuto: e.ctrlKey,
      };

      e.dataTransfer.setData("dataDrop", JSON.stringify(data));
      //$(img).find('.thumb-icons').css({ display: 'block' });
      e.dataTransfer.setDragImage(img, data.offsetX, data.offsetY);

      setTimeout(() => {
        $(img).find(".thumb-icons").attr('style', '');
      }, 100);
    },

    async addImageToProject(imgPath) {
      this.popUpImgPropChange();
      this.uploadImgMenuHandle();
      this.$lib.app.renderAsLayerSprite(imgPath, () => {
        this.$store.dispatch("isUpdateCache");
      });
    },

    async newProject(url, isConfirm) {
      if (!url) url = this.imgPath;
      if (isConfirm) {
        await this.$store.dispatch("confirmPopUpConfig", {
          isHide: false,
          popupTitle: "RESET PROJECT?",
          payload: { url },
          popupBody:
            "Are you sure you would like to continue? Your current project will be reset and all unsaved changes will be lost.",
          name: "newProjectFromLayer",
        });
        this.uploadImgMenuHandle();

        // const response = await fetch(`${url}`);
        // const blob = await response.blob();
        // this.base64Layer = await this.convertBlobToBase64(blob);
      } else {
        this.base64Layer = null;
        if (!url) return;

        this.$store.dispatch("preloader", {
          message: "Wait...",
          isShow: true,
        });

        this.$store.state.global.showPropertiesGraphics = false;
        this.$store.state.global.popupLayerPropVisible = false;
        await this.$store.dispatch("resetCanvas");
        await this.$store.dispatch("isInitialLib", false);
        await this.$store.dispatch("isInitialLib", true);

        let fileType = await this.getFileType(url);
        let reader = new FileReader();
        let blobUrl = this.dataURLToBlob(url);
        reader.onload = async (e) => {
          let dataUrl = await this.$lib.app.resizeImage(url, fileType);

          this.$lib.app.renderSprite({url: dataUrl},async (data) => {

            // this.$store.dispatch("zoomDashboard", this.$lib.app.zoomProcentage);

            document.querySelector(".editing_img_wrpr").removeAttribute("style");
            this.$store.dispatch("isUpdateCache");
            // this.$store.dispatch("preloader", {});
            // this.$lib.app.addTransparentPNGMask();

            // setTimeout(() => {
            //   this.$lib.app.reRenderCanvas();
            // }, 100);

            /* To Set Border Scale*/
            let value = this.$lib.app.zoomProcentage;
            if (this.$lib.app.zoomProcentage != 1) {
              this.$lib.app.scaleStage(1);
              this.$lib.app.setZoomProcentage(1, true);
            }
            this.$lib.app.addTransparentPNGMask();
            // this.$store.state.effectServerURL = this.$lib.app.uploadFileToEffectsServer();
            $(".centered-content").css({'transform': `scale(${value})`});
            this.$lib.app.setFabricZoom(value);

            this.$store.dispatch("zoomDashboard", value);

            setTimeout(() => {
              this.$lib.app.fabricCanvas.renderAll();
              this.$store.dispatch("preloader", {});
              this.$store.dispatch('fabricSlide', false);
              this.$lib.app.reRenderCanvas();
            }, 100);
          });
        }
        reader.readAsDataURL(blobUrl);
      }
    },

    async getFileType (url) {
      return new Promise((resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', url, true);

        xhr.onload = function() {
          resolve(xhr.getResponseHeader('Content-Type'));
        };
        xhr.send();
      });
    },

    dataURLToBlob (dataURL) {
      var BASE64_MARKER = ';base64,';
      if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];

        return new Blob([raw], {type: contentType});
      }

      var parts = dataURL.split(BASE64_MARKER);
      var contentType = parts[0].split(':')[1];
      var raw = window.atob(parts[1]);
      var rawLength = raw.length;

      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], {type: contentType});
    },


    async convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },
    uploadImgMenuHandle(e) {
      if (e) {
        this.popupStyle.left = e.clientX + "px";
        this.popupStyle.top = e.clientY + "px";
        this.popupStyle.position = "fixed";
      }
      return (this.uploadImgMenu = !this.uploadImgMenu);
    },
    async uploadImgDelete() {
      this.$store.dispatch("deleteUserImage", { id: this.$attrs.id });
      this.$emit('uploadImgDelete', { id: this.$attrs.id });
      // fetch(`${this.$store.state.prefixURL}/files/delete`, {
      //   body: JSON.stringify({
      //     user_id: document.head.querySelector("[name=uniq_id][content]")
      //       .content,
      //     id: this.$attrs.id
      //   }),
      //   method: "POST",
      //   headers: {
      //     "X-CSRF-TOKEN": document.head.querySelector(
      //       "[name=csrf-token][content]"
      //     ).content,
      //     "Content-Type": "application/json"
      //   }
      // }).then(response => response.json());

      return (this.uploadImgShow = !this.uploadImgShow);
    },
  },
  computed: {
    getConfirmPopUpAnswer() {
      return this.$store.state.confirmPopUp.answer;
    },
  },
  watch: {
    getConfirmPopUpAnswer(answer) {
      console.log('ASDASA: ', JSON.parse(JSON.stringify(answer)));
      if (answer && answer.name) {
        if (answer.name === "newProjectFromLayer" && answer.result) {
          this.newProject(answer.payload.url);
        }
      }
    },
  },
};
</script>

<style scoped>
.tooltip-block {
  letter-spacing: 0px;
  color: #d7dcdd;
  font-size: 12px;
  background: #2a2a2a 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 2px #1d1d1db3;
  border-radius: 2px;
  padding: 6px 8px;
}

.drag-or-click {
  z-index: 9999;
  bottom: 2px;
  left: 9px;
  transition: 0s 0s !important;
}

.thumb-wrap:hover .drag-or-click {
  transition: 0s 1s !important;
  left: 9px;
}

.thumb-z {
  z-index: 9999;
}
</style>
