<template>
  <div>
    <div v-if="!isMobileView"
            class="thumb-wrap thumbnail radius-4 hover-overlay effect_category_item"
            v-bind:class="{ active : active_el == itemId }"
            :style="getThumbCss()"
    >
      <div
              :tabIndex="tabIndex"
              class="thumb-click"
              v-on:click="activateHandle(itemId); filterPreview({ effect: effect, params: params, values: values, id: itemId })"
      ></div>
      <div class="thumb-icons top-right" :style="iconsStyle">
      <span
              class="inch-icon active inch-tune-icon"
              v-tooltip="'Settings'"
              v-if="isHaveValues() || !isValuesPanel || isEffect"
              v-on:click="popUpPropChange(label, params)"
      ></span>
        <span
                class="inch-icon active inch-heart-favorites-icon"
                v-tooltip="'Add to Favorites'"
                v-bind:class="{ checked: favoritesIconIsChecked }"
                v-on:click="favoritesPropChange(itemId, panelId, imgPath, label)"
        ></span>
        <span class="inch-icon inch-plus-membership-icon" v-if="isPremium"></span>
      </div>
      <div class="thumb-text-box hidden">
        <p>{{ label }}</p>
      </div>
      <div :class="{hidden: isMobileView}">
        <RangeLib
                rangeSliderId="editOpacity"
                rangeSliderClass="range-blue"
                :rangeSliderValue="sliderVal"
                :configOpacity="sliderVal"
                @update="sliderUpdate"
                :minValue="sliderMin || 0"
                :maxValue="sliderMax || 100"
                :defaultValue="defaultValue"
        />
      </div>

      <div class="effect_buttons" v-show="active_el == itemId">
        <div class="blue_btn" v-on:click="applyFilter(params)">{{ (!isPremiumAccount && !isPremium || isPremiumAccount)? 'Apply' : 'Upgrade' }}</div>
      </div>
      <slot name="with-mask"></slot>
    </div>

    <div v-if="isMobileView"
            class="category_card_mobile"
    >
      <div class="img_wrpr" :style="getThumbCss()">
          <div v-if="isActiveCurrent && isSelected" v-on:click.prevent="showSlider" class="tuneWrpr">
              <img class="tuneImage" :src="tuneImage">
          </div>
        <div
                class="thumb-click"
                :tabIndex="tabIndex"
                v-on:click="activateHandle(itemId, label); filterPreview({ effect: effect, params: params, values: values, id: itemId })"
        ></div>

        <div class="thumb-icons-mobile top-right" >
          <span class="inch-icon" v-if="isPremium">
            <img :src="plusIcon" />
          </span>
        </div>
      </div>

      <div class="text_wrpr">
        <span>{{ label }}</span>
      </div>

      <slot name="with-mask"></slot>
    </div>
  </div>
</template>

<script>
import RangeLib from "@/components/RangeLib.vue";

export default {
  name: "ThumbnailCard",
  components: {
    RangeLib
  },
  props: {
    isValuesPanel: {
      type: Boolean,
      default: false
    },
    isActiveCurrent: Boolean,
    isSelected: Boolean,
    isMobileView: Boolean,
    tabIndex: Number,
    st: String,
    itemId: Number,
    panelId: Number,
    imgPath: String,
    effect: String,
    preset: String,
    params: Object,
    values: Array,
    label: String,
    isPremium: Boolean,
    showSlider: Function,
    filterPreview: Function,
    applyFilter: Function,
    popUpPropChange: Function,
    favoritesPropChange: Function,
    favoritesIconIsChecked: Boolean,
    activateHandle: Function,
    active_el: Number,
    sliderVal: Number,
    sliderUpdate: Function,
    defaultValue: Number,
    sliderMin: Number,
    sliderMax: Number,
    isEffect: Boolean,
    isFrame: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
        tuneImage: require("@/assets/mobile/tune.svg"),
        plusIcon: require("@/assets/mobile/plus.svg"),
        isActive: false
    };
  },
  computed: {
    isPremiumAccount() {
      return this.$store.state.isPremiumAccount;
    },

    iconsStyle() {
      return { 'pointer-events': this.isActiveCurrent ? 'all' : 'none' };
    }
  },
  methods: {
    getThumbCss() {
      // if (this.isFrame) {
      //   return {
      //     background: `url('${this.imgPath}') center center / contain no-repeat`
      //   };
      // } else {
        return {
          background: `url('${this.imgPath}') center center / cover no-repeat`
        };
      //}
      /*
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position':' center', */
    },
    isHaveValues() {
      if (this.values && this.values.length) {
        for (const val of this.values) {
          try {
            //console.log('QWERTY: ', val, Object.entries(val));
            if (val.values && Object.entries(val.values).length) {
              return true;
            }
          } catch (err) {
            console.error(err);
          }
        }
      }

      return false;
    },

    effectsHandle: function() {
      this.isActive = !this.isActive;
    }
  }
};
</script>

<style>
  .hover-overlay:hover .thumb-text-box.hidden {
    display: block;
  }
  .thumb-icons-mobile {
    display: flex;
    align-items: center;
    position: absolute;
  }
  .thumb-icons-mobile.top-right {
    top: 4px;
    right: 8px;
  }
  .tuneWrpr {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000000;
      opacity: 0.9;
  }
  .tuneWrpr img {
      width: 22px;
      height: 18px;
  }
.effect_buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.effect_buttons .blue_btn {
  pointer-events: all;
}
/* body .panel-content.with_popup .popup {
    position: fixed;
} */
</style>
