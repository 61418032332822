<template>

    <div class="tool-item-panel" :class="editPanelClass">
        <div class="panel-top" v-on:click="closeIt">
            <div class="back">
                <a href="#" class="back-icon">
                    <span class="inch-icon inch-back-icon"></span>
                </a>
                <h3>Color</h3>
            </div>
            <div class="help-box" @click.stop="popUpPropChange('popupsToggleStatus', true)"><img :src="helpIcon" alt=""></div>
        </div>
        <div v-bar>
            <div class="panel-content wo_pad">
                <div class="panel_row">
                    <RangeLib
                    rangeSliderId="editOpacity"
                    :rangeSliderValue = "hue"
                    rangeSliderLabel="Hue"
                    rangeSliderMeasure="°"
                    rangeSliderClass="range-hue" 
                    :minValue = -100
                    :maxValue = 100
                    @update="uhue"
                    />
                </div>
                <div class="panel_row">
                    <RangeLib
                    rangeSliderId="editOpacity"
                    :rangeSliderValue = "saturation"
                    rangeSliderLabel="Saturation"
                    rangeSliderMeasure=""
                    rangeSliderClass="range-saturation" 
                    :minValue = -100
                    :maxValue = 100
                    :interval = 1 
                    @update="usaturation"
                    />
                </div>
                <div class="panel_row margin_bottom_15">
                    <RangeLib
                    rangeSliderId="editOpacity"
                    :rangeSliderValue = "temperature"
                    rangeSliderLabel="Temperature"
                    rangeSliderMeasure=""
                    rangeSliderClass="range-temperature" 
                    :minValue = -100
                    :maxValue = 100
                    :interval = 1
                    @update="utemperature"
                    />
                </div>
                <!-- <div class="panel_row margin_bottom_15">
                    <RangeLib
                    rangeSliderId="editOpacity"
                    :rangeSliderValue = "tint"
                    rangeSliderLabel="Tint"
                    rangeSliderMeasure=""
                    rangeSliderClass="range-tint" 
                    :minValue = -100
                    :maxValue = 100
                    @update="utint"
                    />
                </div> -->
                <div class="d-flex between two-column panel_row">
                    <div class="half">
                        <a tabIndex="0" class="button btn-action cancel" v-on:click="closeIt">cancel</a>
                    </div>
                    <div class="half">
                        <a tabIndex="0" class="button btn-action upgrade" v-on:click="applyIt">apply</a>
                    </div>
                </div>
                <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
            </div>
        </div>
        <div class="pop_up_closer" v-if="getPopupStatus" @click="popUpPropChange('popupsToggleStatus', false)"></div>
        <Tips v-if="getPopupStatus" :text="tipsText">
            <template v-slot:body>
                <div class="tooltip_row_item">
                    <p class="simple_light_p with_icon">
                        <span class="inch-icon inch-circle-icon"></span> 
                        <i>Hue</i> represents a shift in the color
                    </p>
                </div>
                <div class="tooltip_row_item">
                    <p class="simple_light_p with_icon">
                        <span class="inch-icon inch-circle-icon"></span> 
                        <i>Saturation</i> is the intensity of the color
                    </p>
                </div>
                <div class="tooltip_row_item">
                    <p class="simple_light_p with_icon">
                        <span class="inch-icon inch-circle-icon"></span> 
							<i>Temperature</i> will add a cooler or warmer <br>
							tone to your photo                        
                    </p>
                </div>

                <div class="tooltip_row_item">
                    <p class="simple_light_p with_icon">
                        <span class="inch-icon inch-circle-icon"></span> 
						<i>Tint</i> will add a color tint over your photo                        
                    </p>
                </div>


            </template>

        </Tips>
    </div>
</template>

<script>
 
    import RangeLib from '@/components/RangeLib.vue';
    import Tips from "@/components/Tips.vue";
    import AdLeftPanel from "@/components/AdLeftPanel.vue";

    export default {
        name: 'EditColorPanel',
        components: {
            RangeLib,
            Tips,
            AdLeftPanel
        },

        props: {
            editPanelClass: String,
            editPanelPropChange: Function,
            closeCurrentEditor: Function,
            applyChanges: Function
        },

        data: function() {
            return {
                tipsText: "The Color tool allows you to take control of the colors in your photo and their intensity.",
                helpIcon: require("@/assets/img/help-icon.svg"),
                hue: 0,
                saturation: 0,
                temperature: 0,
                tint: 0
            }
        },

        beforeDestroy() {
            this.closeCurrentEditor();
        },
        
        methods: {
            async applyIt(){
                await this.$lib._cache.addToBgCacheUpdating(
                    this.$lib.CONST.MAP_CHANGES.BG_COLOR_EFFECT,
                    {  }, true, { isThumb: true }
                );

                this.applyChanges(this.$lib);
                this.$store.dispatch('changesApplied');
            },

            closeIt(){
                this.closeCurrentEditor(this.$lib);
            },

            uhue(v){
                this.hue = v;
                this.updateColor();
            },

            usaturation(v){
                this.saturation = v;
                this.updateColor();
            },

            utemperature(v){
                this.temperature = v;
                this.updateColor();
            },

            utint(v) {
                this.tint = v;
                this.updateColor();
            },

            updateColor(){
                let hue = this.hue;
                let temperature = this.temperature;

                if(hue >= 0) {
                    hue = this.$lib.lib.conv(hue, 0, 180);
                } else {
                    hue = this.$lib.lib.conv(hue * -1, 0, 180) * -1;
                }

                if(temperature >= 0) {
                    temperature = this.$lib.lib.conv(temperature, 0, 25);
                } else {
                    temperature = this.$lib.lib.conv(temperature * -1, 0, 25) * -1;
                }

                this.color.setupEffect({
                    temperature: temperature / 100,
                    saturation: this.saturation / 100,
                    hue, tint: this.tint
                });
            },

            popUpPropChange(property, value) {
                this.$store.dispatch("popupsToggleStatus", {property, value});
            },

            changePopup(property, value) {
                this.$store.dispatch("popupsToggle", { property, value });
            },

            changesAppliedShow() {
                this.changePopup('changesApplied', true)
                this.editPanelPropChange();
                setTimeout(() => {
                    this.changePopup('changesApplied', false)
                }, 1000);
            }
        },
        mounted() {
            this.$store.dispatch('openHideTips', this.$vnode.componentOptions.tag);
            this.$store.dispatch('fabricSlide', true);
            this.color = new this.$lib.effects.ColorEffect(this.$lib.app.stage.children[0]);
        },
        
        watch: {
            async _applySelectedEffect() {
            try {
                await this.applyIt();
            } catch(err) {}
            this.$store.state._callbackSave += 1;
            }
        },

        computed: {
            isLeftAd() {
                return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
            },
            _applySelectedEffect() {
                return this.$store.state._applySelectedEffect;
            },

            getPopupStatus() {
                return this.$store.state.global.popupStatus.value
            }
        }
    }

</script> 
