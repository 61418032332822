var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.signupPopup)?_c('div',{staticClass:"popup save_popup upgrade-popup sign-up"},[_c('a',{staticClass:"close_popup_btn",on:{"click":function($event){return _vm.changePopup('checkoutRegister', false)}}},[_c('span',{staticClass:"inch-icon inch-close-icon"})]),_c('div',{staticClass:"m-success"},[_vm._v("Successfully Upgraded to Colorcinch Plus")]),_c('h2',{staticClass:"title sm sign-up"},[_vm._v("Create your account")]),_c('br'),(_vm.isOnline)?_c('form',{attrs:{"id":"login_form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.isLoading)?_c('div',{staticClass:"form_loading"}):_vm._e(),_c('div',{staticClass:"form-group sign-up"},[_c('div',{staticClass:"label sign-up"},[_vm._v("Email")]),_c('input',{ref:"xemail",staticClass:"form-control xemail sign-up",attrs:{"readonly":"","type":"email","placeholder":"Email address","name":"email"},domProps:{"value":_vm.email}})]),_c('div',{staticClass:"form-group sign-up"},[_c('div',{staticClass:"label sign-up"},[_vm._v("Password")]),_c('input',{ref:"xpassword",staticClass:"form-control sign-up",attrs:{"type":"password","placeholder":"Password","name":"password"},domProps:{"value":_vm.password}})]),(_vm.formErrors)?_c('div',{staticClass:"form_errors"},[_c('ul',{attrs:{"id":"example-1"}},_vm._l((_vm.formErrors),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item[0]))])}),0)]):_vm._e(),_vm._m(0)]):_vm._e()]):_vm._e(),(_vm.congratPopup)?_c('div',{staticClass:"popup save_popup upgrade-popup small width-padding"},[_c('a',{staticClass:"close_popup_btn",on:{"click":function($event){return _vm.closeAllPopups()}}},[_c('span',{staticClass:"inch-icon inch-close-icon"})]),_vm._m(1),_c('h2',{staticClass:"title sm title-success-signin",style:({
        'font-weight': '600 !important',
        'font-size': '28px !important',
      })},[_vm._v(" Thanks for joining! We're thrilled to have you! ")]),_c('p',{staticClass:"box-success-signin"},[_vm._v(" You now have full access to Colorcinch Plus and everything it has to offer! ")]),_c('div',{staticClass:"btn-wrap margin-top"},[_c('a',{staticClass:"btn-orange",staticStyle:{"margin":"0","color":"#fff"},on:{"click":_vm.closeAllPopups}},[_vm._v("Start Editing")])]),_vm._m(2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-wrap sign-up"},[_c('input',{staticClass:"btn-orange",staticStyle:{"padding":"15px 40px","width":"100%"},attrs:{"type":"submit","value":"Create Account"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon margin-bottom"},[_c('img',{attrs:{"src":require("../assets/images/sun.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"sm-descr"},[_vm._v(" or visit "),_c('a',{attrs:{"href":"/my-account"}},[_vm._v("My Account")]),_vm._v(" instead ")])
}]

export { render, staticRenderFns }