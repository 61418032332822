<template>
	<div :class="[{'tool-item-panel categories_panel': !isMobileView}, panelClass]">
		<div v-if="!isMobileView">
			<div class="panel-top" v-on:click="panelPropChange">
				<div class="back">
					<a href="#" class="back-icon">
						<span class="inch-icon inch-back-icon"></span>
					</a>
					<h3>{{ panelName }}</h3>
				</div>
				<div class="help-box" @click.stop="popUpHelpPropChange('popupsToggleStatus', true)">
					<img :src="helpIcon" alt />
				</div>
			</div>
			<div class="inner_pannel_outer" v-bar>
				<div class="panel-content wo_pad with_popup" @scroll="$emit('contentUpload', $event)">
					<div class="is_offline" v-if="hidePannelIfOffline">
						<Offline></Offline>
					</div>

					<div tabIndex="0" class="panel_row effects_categories_wrpr" v-if="!hidePannelIfOffline">
						<div class="loader-wrapper" v-if="panelLoading">
							<div class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
						<template v-else>
							<ThumbnailCard
									:isActiveCurrent="appliedPresetId == item.id"
									v-for="(item, index) in panelItems"
									v-bind:key="index"
									:tabIndex="0"
									:label="item.label || item.title"
									:imgPath="item.imgPath || item.url"
									:effect="item.effect"
									:preset="item.preset"
									:params="item.params"
									:values="item.values || []"
									:popUpPropChange="popUpPropChange"
									:filterPreview="filterPreview"
									:applyFilter="applyFilter"
									:st="item.st"
									:isEffect="isEffect"
									:favoritesIconIsChecked="item.favoritesIconIsChecked"
									:isPremium="isPremiumAccount ? false : item.isPremium"
									:favoritesPropChange="favoritesPropChange"
									:itemId="item.id"
									:configOpacity="configOpacity"
									:panelId="panelId || item.panelId"
									:sliderVal="sliderVal"
									:sliderMin="sliderMin"
									:sliderMax="sliderMax"
									:sliderUpdate="sliderUpdate"
									:activateHandle="activateChange"
									:active_el="active_el"
									:isValuesPanel="isValuesPanel"
									:isFrame="isFrame"
									:defaultValue="sliderDefaultValue"
							>
								<template v-slot:with-mask>
									<slot name="with-mask"></slot>
								</template>
							</ThumbnailCard>
							<div class="page-loader" v-if="isLoading" ref="pageLoader">Loading...</div>
						</template>
					</div>

					<slot name="popup"></slot>
				</div>
			</div>
		</div>

		<div v-if="isMobileView">
			<div class="panel_top_mobile">
				<a href="#" class="back-icon actionIcons" v-on:click="goback">
					<img v-if="!cancelButton" :src="backIcon" alt />
					<img v-if="cancelButton" :src="cancelIcon" alt />
				</a>
				<h3>{{ cancelButton ? currentLabel : panelName}}</h3>
				<div class="actionIcons">
					<img v-if="cancelButton" @click="applyEffect(params)" :src="saveIcon" alt />
				</div>
			</div>
			<div>
				<div class="is_offline" v-if="hidePannelIfOffline">
					<Offline></Offline>
				</div>

				<div class="panels_scroll hide_scroll" @scroll="$emit('contentUpload', $event)" tabIndex="0" v-if="!hidePannelIfOffline">
					<div class="loader-wrapper" v-if="panelLoading">
						<div class="lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
					<template v-else>
						<div v-if="!showOpacitySlider" class="thumbnailcard_row">
							<ThumbnailCard
									:isMobileView="isMobileView"
									:isActiveCurrent="appliedPresetId == item.id"
									:isSelected="isSelected"
									v-for="(item, index) in panelItems"
									v-bind:key="index"
									:tabIndex="0"
									:label="item.label || item.title"
									:imgPath="item.imgPath || item.url"
									:effect="item.effect"
									:preset="item.preset"
									:params="item.params"
									:values="item.values || []"
									:popUpPropChange="popUpPropChange"
									:filterPreview="setParamsAndFilterPreview"
									:showSlider="showSlider"
									:applyFilter="applyFilter"
									:st="item.st"
									:isEffect="isEffect"
									:favoritesIconIsChecked="item.favoritesIconIsChecked"
									:isPremium="isPremiumAccount ? false : item.isPremium"
									:favoritesPropChange="favoritesPropChange"
									:itemId="item.id"
									:configOpacity="configOpacity"
									:panelId="panelId || item.panelId"
									:sliderVal="sliderVal"
									:sliderMin="sliderMin"
									:sliderMax="sliderMax"
									:sliderUpdate="sliderUpdate"
									:activateHandle="activateChange"
									:active_el="active_el"
									:isValuesPanel="isValuesPanel"
									:isFrame="isFrame"
									:defaultValue="sliderDefaultValue"
							>
								<template v-slot:with-mask>
									<slot name="with-mask"></slot>
								</template>
							</ThumbnailCard>
						</div>
						<transition name="panel-slider">
							<div v-if="showOpacitySlider" class="opacity-slider">
								<RangeLib
										:isMobileView="isMobileView"
										rangeSliderId="editOpacity"
										rangeSliderClass="range-blue"
										:rangeSliderValue="sliderVal"
										:configOpacity="sliderVal"
										@update="sliderUpdate"
										:minValue="sliderMin || 0"
										:maxValue="sliderMax || 100"
										:defaultValue="sliderDefaultValue"
								/></div>
						</transition>

						<div class="page-loader" v-if="isLoading" ref="pageLoader">Loading...</div>
					</template>
				</div>

				<slot name="popup"></slot>
			</div>
		</div>


		<div v-if="!isMobileView">
			<div
					class="pop_up_closer"
					v-if="getPopupStatus"
					@click="popUpHelpPropChange('popupsToggleStatus', false)"
			></div>
			<div class="pop_up_closer" v-if="popUpVisible" @click="popUpPropChange('')"></div>
			<Tips v-if="getPopupStatus" :text="tipsText">
				<template v-slot:body>
					<div class="tooltip_row_item" v-for="list in pannelInfo.pannelList" >
						<p class="simple_light_p with_icon">

							<span class="inch-icon" :class="list.icon"></span>
							<span v-html="list.text"></span>
						</p>
					</div>

				</template>
				<template v-slot:footer v-if="!isPremiumAccount">
					<div class="tooltip_row_item">
						<p class="simple_light_p with_icon" v-if="!isUpgradePlan(pannelInfo.pannelFooter)" v-html="pannelInfo.pannelFooter">
						</p>
						<p class="simple_light_p with_icon" v-if="isUpgradePlan(pannelInfo.pannelFooter)">
							<span v-html="parseUpgradeText(pannelInfo.pannelFooter)"></span>
							<a
									@click="showUpgrade"
									class="colorcinchplus"
							>Colorcinch Plus</a>
						</p>
					</div>
				</template>
			</Tips>
		</div>

	</div>
</template>


<script>
import ThumbnailCard from "@/components/ThumbnailCard.vue";
import Tips from "@/components/Tips.vue";
import Offline from "@/components/Offline.vue";
import RangeLib from "@/components/RangeLib.vue";
import PremiumInfo from "./PremiumInfo";


export default {
	name: "ThumbsPanel",
	props: {
		isValuesPanel: {
			type: Boolean,
			default: false
		},
		appliedPresetId: {
			type: [Boolean, Number, String],
			default: () => false
		},
		pannelInfo: Object,
		panelName: String,
		panelClass: String,
		panelPropChange: Function,
		popUpPropChange: Function,
		filterPreview: Function,
		applyFilter: Function,
		panelItems: Array,
		panelLoading: Boolean,
		favoritesPropChange: Function,
		panelId: Number,
		popUpVisible: Boolean,
		sliderVal: Number,
		sliderMin: Number,
		sliderMax: Number,
		sliderUpdate: Function,
		sliderDefaultValue: Number,
		configOpacity: Number,
		isFrame: Boolean,
		isEffect: Boolean,
		isLoading: {
			type: Boolean,
			default: false
		}
	},
	components: {
		PremiumInfo,
		ThumbnailCard,
		Tips,
		Offline,
		RangeLib
	},
	data: function() {
		return {
			isMobileView: false,
      params: {},
			showOpacitySlider: false,
			cancelButton: false,
			isSelected: true,
			currentLabel: "",
			tipsText: this.pannelInfo ? (this.pannelInfo.pannelTitle || "") : '',
			helpIcon: require("@/assets/img/help-icon.svg"),
			backIcon: require("@/assets/mobile/back.svg"),
			cancelIcon: require("@/assets/mobile/cancel-grey.svg"),
			saveIcon: require("@/assets/mobile/save-tick.svg"),
			active_el: 0,
			isPremiumUser: false
		};
	},

	beforeDestroy() {
		clearInterval(this.loadingInterval);
	},

	updated(){
		this.$nextTick(()=>{
			setTimeout(()=>{
				let outer = this.$el,
            list = outer.querySelector(".panel_row.effects_categories_wrpr");

        if (this.isMobileView) {
          list = outer.querySelector(".panels_scroll");
        }

				let outer_height = outer.clientHeight,
					inner_height = list.clientHeight;

				if (inner_height < outer_height){
					this.$emit("loadSecondPageIfBigScreen");
				}
			}, 500);
		})
	},

	mounted() {
		this.isMobileView = this.$store.state.isMobileView;
		this.loadingInterval = setInterval(() => {
			if(this.$refs.pageLoader) {
				const points = this.$refs.pageLoader.innerHTML.split('Loading')[1].length;
				let pointsCount = points >= 3 ? 1 : points + 1;
				this.$refs.pageLoader.innerHTML = `Loading${'.'.repeat(pointsCount)}`;
			}
		}, 120);
	},

	methods: {

		showUpgrade(){
			// if (window.abVariant && window.abVariant == 2) {
				this.$store.dispatch('popupsToggle', { property: 'stripeCheckoutPopup', value: true })
			// }
			// else{
			// 	this.$store.dispatch('popupsToggle', { property: 'upgradePopup', value: true })
			// }
		},
		applyEffect(params) {
			if(this.showOpacitySlider) {
				this.applyFilter(params, true);
			} else this.applyFilter(params);
		},
    setParamsAndFilterPreview(params) {
	    this.params = {...params};
      this.filterPreview(params);
    },
		 showSlider() {
			 let height = _LIB.app.fabricCanvas.height;
			 let nowHeight, zoom;

			 nowHeight = height + 31;
			 zoom = nowHeight/_LIB.app.stage.height;
			$(".centered-content").css({'transform': `scale(${zoom})`});
			$(".drag-wrapper").css({'height': 'calc(100vh - 280px + 31px)'})
			_LIB.app.setFabricZoom(zoom);

			this.showOpacitySlider = true;
			this.cancelButton = true;
			this.$emit('panelView', false);
		},
		goback() {
		 	if (this.showOpacitySlider) {
				let height = _LIB.app.fabricCanvas.height;
				let nowHeight, zoom;

				nowHeight = height - 31;
				zoom = nowHeight/_LIB.app.stage.height;
				$(".centered-content").css({'transform': `scale(${zoom})`});
				$(".drag-wrapper").css({'height': 'calc(100vh - 280px)'})
				_LIB.app.setFabricZoom(zoom);

				this.showOpacitySlider = false;
				return;
			}
		 	if (this.cancelButton) {
		 		this.cancelButton = false;
		 		this.isSelected = false;
			}
      this.currentLabel = "";
      this.$emit('panelView', true);
      this.panelPropChange();
		},
		parseUpgradeText(html) {
			return html.split(`<a href='#get_plus'`)[0];
		},

		isUpgradePlan(html) {
			if(html.includes('#get_plus')) {
				return true;
			}

			return false;
		},

		upgradePlan() {
			// if (window.abVariant && window.abVariant == 2) {
				this.$store.dispatch("popupsToggle", {
					property: "stripeCheckoutPopup",
					value: true
				});
			// }
			// else{
			// 	this.$store.dispatch("popupsToggle", {
			// 		property: "upgradePopup",
			// 		value: true
			// 	});
			// }
		},

		popUpHelpPropChange(property, value) {
			this.cancelButton = false;
			this.$store.dispatch("popupsToggleStatus", { property, value });
		},

		activateChange: function(el, label) {
		 	this.isSelected = true;
			if (this.active_el == el) {
				this.active_el = 0;
			} else {
				this.active_el = el;
			}
			this.currentLabel = label;
			this.cancelButton = true;
		}

		// updateOpacity
	},
	computed: {
		hidePannelIfOffline(){
			return this.isOffline && ["Cartoonizer","Digital Art", "Sketcher", "Painting"].includes(this.panelName);
		},

		isPremiumAccount() {
			return this.$store.state.isPremiumAccount;
		},
		getPopupStatus() {
			return this.$store.state.global.popupStatus.value;
		}
	}
};
</script>
<style scoped>
.page-loader {
	display: block;
	padding: 15px 0 10px 0;
	text-align: center;
	color: #fff;
}
	.panel_top_mobile{
		border-top: 1px solid #303030;
		background: #2A2A2A;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 4px 0 4px 0px;
		cursor: pointer;
		position: fixed;
		bottom: 0;
		z-index: 110;
	}
	.panel_top_mobile .back {
		width: 100%;
		display: flex;
		align-items: center;
	}
 	.panel_top_mobile h3 {
		color: #FFFFFF;
		letter-spacing: -0.52px;
		text-transform: capitalize;
		font-size: 13px;
		position: relative;
		top: 1px;
		font-weight: 400;
	}
	.actionIcons {
		width: 36px;
		height: 36px;
    margin-right: 4px;
    margin-left: 4px;
	}
	.thumbnailcard_row {
		background: #2A2A2A;
		display: flex;
		padding: 12px 16px 16px 16px;
		z-index: 110;
	}
	.opacity-slider {
		z-index: 200;
		position: fixed;
		width: 100%;
		padding: 27px 20px 29px 20px;
		background: #2A2A2A;
		bottom: 46px;
	}
  .panels_scroll {
    overflow-x: scroll !important;
    width: 100vw;
  }
.trans-panel .panel-slider-enter-active {
	transition: all 0.1s ease;
}
.trans-panel .panel-slider-leave-active {
	transition: all 0.1s ease;
}
.trans-panel .panel-slider-enter {
	transform: translateY(100px);
	opacity: 0;
}
.trans-panel .panel-slider-leave-to {
	transform: translateY(100px);
	opacity: 0;
}
</style>
