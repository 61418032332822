<template>
  <div class="tool-item-panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="closeIt">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>Round</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" alt />
      </div>
    </div>
    <div v-bar>
      <div class="panel-content wo_pad">
        <div class="panel_row">
          <Range
            rangeSliderId="editOpacity"
            :rangeSliderValue="radius"
            rangeSliderLabel="Radius"
            rangeSliderClass="range-blue"
            :minValue="0"
            :maxValue="100"
            @update="setRadius"
          />
        </div>
        <div class="panel_row">
          <div class="btns-group mt_18">
            <a tabIndex="0" class="grey_btn" v-on:click="closeIt">Cancel</a>
            <a tabIndex="0" class="blue_btn" v-on:click="applyIt">Apply</a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p">
            The Round tool allows you to take control of your photo's corner
            radius. Rounded corners are easier on the eyes and give your image a
            softer and more elegant look.
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import Range from "@/components/RangeLib.vue";
import Tips from "@/components/Tips.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: "EditRoundPanel",
  components: {
    Range,
    Tips,
    AdLeftPanel,
  },

  props: {
    editPanelClass: String,
    editPanelPropChange: Function,
    applyChanges: Function,
    closeCurrentEditor: Function,
  },

  data: function() {
    return {
      tipsText: "",
      helpIcon: require("@/assets/img/help-icon.svg"),
      libRound: false,
      radius: 20,
    };
  },

  mounted: function() {
    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.libRound = new this.$lib.edit.Round(
      this.$lib.app.stage.children[0],
      this.$lib.app.stage.width,
      this.$lib.app.stage.height
    );

    this.libRound.setRadius(parseFloat(this.radius) * 4);
    //window.libRound = this.libRound;
  },

  watch: {
    getZoomState() {
      if (this.libRound) {
        this.libRound.optimizationCanvasToResize();
      }
    },
    async _applySelectedEffect() {
      try {
        await this.applyIt();
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
  },

  beforeDestroy: function() {
    this.libRound.destroyRound();
    this.libRound = false;
  },

  methods: {
    async applyIt() {
      this.libRound.isEnabled = false;
      await this.libRound.roundImage();

      await this.$lib._cache.addToBgCacheUpdating(
        this.$lib.CONST.MAP_CHANGES.BG_ROUND_EFFECT,
        {},
        true,
        { isThumb: true }
      );

      this.libRound.destroyRound();
      this.applyChanges(this.$lib);
      this.$store.dispatch("changesApplied");
      //this.$lib._cache.saveChanges();
    },

    closeIt() {
      this.libRound.destroyRound();
      this.closeCurrentEditor(this.$lib);
    },

    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },

    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },

    async changesAppliedShow() {
      this.libRound.isEnabled = false;
      await this.libRound.roundImage();
      this.libRound.destroyRound();
      // this.$lib._cache.saveChanges(false, (list, index) => this.$lib.UI.changes.renderHistoryChanges(list, index));

      this.changePopup("changesApplied", true);
      this.editPanelPropChange();
      setTimeout(() => {
        this.changePopup("changesApplied", false);
      }, 1000);
    },

    setRadius(value) {
      this.radius = value;
      this.libRound.setRadius(parseFloat(value) * 4);
    },

    cancelRound() {
      console.log("CANCEL");
      this.libRound.destroyRound();
      //this.libRound = new this.$lib.edit.Round(this.$lib.app.stage.children[0]);
      //this.libRound.initRound(this.$lib.app.stage.children[0], this.$lib.app.stage.children[0].width, this.$lib.app.stage.children[0].height, this.$lib.app.renderer);
    },
  },

  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },

    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },

    getZoomState() {
      return this.$store.state.global.zoom;
    },
  },
};
</script>
