<template>
    <a href="#" :class="[{category_card: !isMobileView, category_card_mobile: isMobileView}, classs]"  v-on:click="toolLinkClick(msg, categoryId)">
        <div class="img_wrpr">
            <img class="cardImg" :src="imgPath" alt="">
        </div>
        <div class="text_wrpr">
            <span>{{msg}} {{classs}}</span>
        </div>
    </a>
</template>


<script>
    export default {
        name: 'CategoryCard',
        props: {
            isMobileView: Boolean,
            imgPath: String,
            msg: String,
            classs: String,
            categoryId: Number,
            toolLinkClick: Function
        }
    }
</script>
