export default new class {
    constructor() {
        const name = 'edit.cartoonize.net';

        this.indexDB = window.indexedDB || window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
        const request = window.indexedDB.open(name, 3);

        request.onerror = err => this.error(err);
        request.onsuccess = ev => this.success(ev);
        request.onupgradeneeded = ev => this.upgradeneeded(ev);
    }

    get savesIds() {
        return this.autosaves;
    }

    canLoad() {}

    error(err) {
        console.error(err);
    }

    success(event) {
        this.db = event.target.result;

        this.canLoad();
    }

    add(tableName, data) {
        this.db.transaction([tableName], 'readwrite').objectStore(tableName).add(data);
    }

    deleteById(tableName, id) {
        this.db.transaction([tableName], 'readwrite').objectStore(tableName).delete(id);
    }
    
    deleteAll(tableName) {
        this.db.transaction([tableName], 'readwrite').objectStore(tableName).clear();
    }

    getAll(tableName) {
        return new Promise(resolve => {
            this.db.transaction(tableName).objectStore(tableName).getAll().onsuccess = event => resolve(event.target.result);
        });
    }

    upgradeneeded(ev) {
        const db = ev.target.result;
        
        const autosave = db.createObjectStore('autosave', { keyPath: 'id' });
        //autosave.createIndex('id', 'id', { unique: true });
    }
}
