export default class{
	constructor(){
		this.db = null;	
		this.projects_table = "offline_projects";
	}
	getDB(){
		return this.db;
	}

	async init(){
		this.db = false;
		var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
		var request = indexedDB.open("cartoonize-db", 3);
		this.db = await new Promise((res, rej)=>{
			request.onerror = function(evt){
				console.log("return false");
				res(false);
			}
			request.onsuccess = function(event) {
//				console.log("return from success");
//				if (!thisDB.objectStoreNames.contains(this.projects_table)){
					res(this.result);
//				}
			};

			request.onupgradeneeded = (evt)=>{
				var thisDB = evt.target.result;
				if (!thisDB.objectStoreNames.contains(this.projects_table)){
					var store = thisDB.createObjectStore(this.projects_table, { keyPath: 'id', autoIncrement: true });
				}
				evt.target.transaction.oncomplete = function(){
					res(thisDB);
				}
			}		
		});

		if(!this.db){
			return false;
		}
		return true;
	}
	async add(obj={}){
		return await new Promise((res, rej)=>{
			var transaction = this.db.transaction([this.projects_table], "readwrite");
			transaction.oncomplete = function(event) {
				res(true);
			};

			transaction.onerror = function(event) {
				console.error("idb trans error");
				res(false);
			};

			var objectStore = transaction.objectStore(this.projects_table);
			var request = objectStore.add(obj);
			request.onsuccess = function(event){
			};
		})
	}
	
	async delete(key){
		return await new Promise((res, rej)=>{
			var transaction = this.db.transaction([this.projects_table], "readwrite");
			transaction.oncomplete = function(event) {
				res(true);
			};
			transaction.onerror = function(event) {
				console.error("idb trans error");
				res(false);
			};
			var objectStore = transaction.objectStore(this.projects_table);
			var request = objectStore.delete(key);
			request.onsuccess = function(event){
			};
		});
	}
	
	async get(){
		return await new Promise((res, rej)=>{
			var transaction = this.db.transaction([this.projects_table], "readwrite");
			var objectStore = transaction.objectStore(this.projects_table);
			var request = objectStore.getAll();
			request.onerror = function(event) {
				res(false);
			};
			request.onsuccess = function(event) {
				res(request.result);
			};
		})
	}
}

//var op = new OfflineProjects();
//x = await op.init();
//await op.add({"ebola123": 1234});
//var added = await op.get();
//console.log("xxx", added);
