export default new class {
    constructor() {
        this.isActive = !!window.localStorage;
    }

    getItem(key, type = 'json') {
        try {
            const result = localStorage.getItem(key);
            if (type == 'json') {
                return result ? JSON.parse(result) : null;
            }

            return result;
        } catch (err) {
            return null;
        }
    }

    setItem(key, data) {
        try {
            localStorage.setItem(key, typeof data == 'object' ? JSON.stringify(data) : data);
        } catch (err) { }
    }

    updateFavorites(key, data) {
        let favorites = this.getItem('favorites') || {};

        if(!favorites) {
            favorites = {};
        }

        favorites[key] = data;

        this.setItem('favorites', favorites)
    }
};