<template>
  <div :class="[{'tool-item-panel': !isMobileView}, sidebarPanelClass]">
    <div v-if="!isMobileView" class="panel-top without_hover">
      <div class="back">
        <h3>OVERLAYS</h3>
      </div>
    </div>
    <div :class="{'panel-content': !isMobileView, 'wo_pad': !isMobileView}">
      <div v-if="!isMobileView" v-bar>
        <div class="panel_row effects_items_wrpr effects_categories_wrpr">
          <Favorites
            v-if="favoritesList.length"
            :favoritesCount="favoritesList.length"
            :favoritesOpen="favoritesOpen"
          />
          <template v-for="cardItem in panels">
            <CategoryCard
              v-bind:key="cardItem.id"
              :categoryId="cardItem.id"
              :toolLinkClick="selectCategory"
              :msg="cardItem.title"
              :imgPath="cardItem.url"
            />
          </template>
          <AdLeftPanel v-if="isLeftAd" margin="20px 0px" marginLeft="0" />
        </div>
      </div>
      <transition name="panel-slide">
        <div v-if="isMobileView && showPanel" class="panel_row_mobile">
          <template v-for="cardItem in panels">
            <CategoryCard
                :isMobileView="isMobileView"
                v-bind:key="cardItem.id"
                :categoryId="cardItem.id"
                :toolLinkClick="selectCategory"
                :msg="cardItem.title"
                :imgPath="cardItem.url_m || cardItem.url"
            />
          </template>
        </div>
      </transition>
    </div>

    <transition name="panel-slide">
      <template v-for="panel in panels">
        <ThumbsPanel
          :appliedPresetId="appliedPresetId"
          v-bind:key="panel.id"
          v-if="panelOpened === panel.title"
          :pannelInfo="pannelInfo"
          :panelName="panel.title"
          :panelItems="panel.panelItems"
          :panelPropChange="panelPropChange"
          :popUpPropChange="popUpPropChange"
          @panelView="panelView"
          :filterPreview="overlayPreview"
          :applyFilter="applyOverlay"
          :panelClass="panelClass"
          :panelLoading="panelLoading"
          :favoritesPropChange="favoritesPropChange"
          :panelId="panel.id"
          :popUpVisible="popUpVisible"
          :sliderUpdate="sliderUpdate"
          :sliderVal="uniformsOverlay.alpha * 100"
          :sliderMax="100"
          :sliderMin="0"
          @loadSecondPageIfBigScreen="loadSecondPageIfBigScreen"
          @contentUpload="contentUpload"
          :isLoading="pageControl.isLoading"
          :sliderDefaultValue="defaultValueAlpha"
        >
          <template v-slot:popup>
            <PopupOverlays
              :popUpVisible="popUpVisible"
              :popUpLabel="popUpLabel"
              :popUpPropChange="popUpPropChange"
              @applyOverlay="applyOverlay"
              @setOverlayOptions="setOverlayOptions"
              :overlayParams="overlayOptions"

              :eraseConfig="eraseData"
              @resetDrawing="resetDrawing(true, true)"
              @selectPointerEvents="selectPointerEvents"
              @changeConfig="changeConfig"
            />
          </template>
        </ThumbsPanel>
      </template>
    </transition>

    <transition name="panel-slide">
      <ThumbsPanel
        :appliedPresetId="appliedPresetId"
        v-if="panelOpened === 'Favorites'"
        panelName="Favorites"
        :pannelInfo="pannelInfo"
        :panelItems="favoritesList"
        :panelPropChange="panelPropChange"
        :popUpPropChange="popUpPropChange"
        :panelClass="panelClass"
        :filterPreview="overlayPreview"
        :applyFilter="applyOverlay"
        :panelLoading="panelLoading"
        :sliderUpdate="sliderUpdate"
        :sliderMax="100"
        :sliderMin="0"
        :sliderVal="uniformsOverlay.alpha * 100"
        :sliderDefaultValue="defaultValueAlpha"
        :favoritesPropChange="favoritesPropChange"
      >
        <template v-slot:popup>
          <PopupOverlays
            :popUpVisible="popUpVisible"
            :popUpLabel="popUpLabel"
            :popUpPropChange="popUpPropChange"
            @applyOverlay="applyOverlay"
            @setOverlayOptions="setOverlayOptions"
            :overlayParams="overlayOptions"

            :eraseConfig="eraseData"
						@resetDrawing="resetDrawing(true, true)"
						@selectPointerEvents="selectPointerEvents"
						@changeConfig="changeConfig"
          />
        </template>
      </ThumbsPanel>
    </transition>

    <div v-if="this.pageControl.isLoading">Loading...</div>
  </div>
</template>


<script>
import CategoryCard from "@/components/CategoryCard.vue";
import ThumbsPanel from "@/components/ThumbsPanel.vue";
import PopupOverlays from "@/components/popupPanel/PopupOverlays.vue";
import Favorites from "@/components/Favorites.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";
const analitics = require('../../../libs/analytics');
import EarseToolMixin from '../../../mixin/EarseToolMixin';
import LoaderMixin from '@/mixin/LoaderMixin';
import { EventBus } from '@/main.js';

export default {
  name: "OverlaysPanel",
  props: {
    sidebarPanelClass: String,
  },

  mixins: [EarseToolMixin, LoaderMixin],
  components: {
    CategoryCard,
    ThumbsPanel,
    PopupOverlays,
    Favorites,
    AdLeftPanel,
  },
  data: function () {
    return {
      isMobileView: false,
      showPanel: true,
      appliedPresetId: false,
      pannelInfo: {
        pannelTitle: "",
        pannelList: [
          { icon: "slider_icon", text: "Adjust the overlay intensity" },
          { icon: "icon_heart", text: "Add selected overlay to Favorites" },
          {
            icon: "icon_tune",
            text: "Access advanced settings to further fine-tune the overlay",
          },
        ],
        pannelFooter: `<span data-v-e02b15b2="" class="inch-icon inch-plus-membership-icon"></span>Indicates the overlay is a Colorcinch Plus feature. <a href='#get_plus'>Get Colorcinch Plus</a>`,
      },

      panelNameFavorite: "overlays",
      defaultValueAlpha: 80,
      uniformsOverlay: {
        alpha: 0.8,
      },
      favoritesCount: 0,
      favoritesList: [],
      favoritesPanelVisible: false,
      popUpVisible: false,
      popUpLabel: "",
      panelLoading: true,
      panelOpened: "",
      panelClass: "",
      panels: [],
      overlayOptions: {},
      selectedPresetId: null,
      pageControl: {
        currentPage: 1,
        totalPages: 1,
        isLoading: false,
      },
      selectedPanelId: null,
    };
  },

  beforeDestroy() {
    this.resetDrawing(false, true);

    this.discardChanges();
    this.$lib.Drawing.initDraw(false);
    this.$lib.watermark.remove();
    this.selectPointerEvents(false);
  },

  watch: {
    async _applySelectedEffect() {
      try {
        if (!(!this.selectedPresetId && this.selectedPresetId !== 0)) {
          await this.applyOverlay();
        }
        if (this.$store.state.saveOnMobile) {
          EventBus.$emit("save-on-mobile");
        }
      } catch(err) {}
      this.$store.state._callbackSave += 1;
    }
  },

  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },
  },

  async mounted() {
    if(this.$store.state.isMobileView) {
      this.isMobileView = this.$store.state.isMobileView;
      this.defaultValueAlpha = 100;
      this.uniformsOverlay.alpha = 1;
    }
    // if (this.$store.state.USER) {
    //   try {
    //     this.$store.state.favorites.overlays = await fetch(
    //       `/api/overlays/favorites`
    //     ).then(res => res.json());
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }
    window.qq = this;
    this.updateGlobalEarseConfig();
    this.selectPointerEvents(true);

    this.changeConfig({ key: 'brushSize', value: this.eraseData.brushSize });

    this.panels = await fetch(
      `${this.$store.state.prefixURL}/api/overlays`
    ).then((res) => res.json());
    this.favoritesList = this.$store.state.favorites.overlays;

    this.favoritesList = this.favoritesList.map((fav) => {
      fav.favoritesIconIsChecked = true;
      return fav;
    });

    //this.$store.state.favorites.overlays;
  },

  methods: {

	async loadSecondPageIfBigScreen(){
		if(!this.pageControl.isLoading && this.pageControl.currentPage < this.pageControl.totalPages){
			this.contentUpload(false, true);

		}
	},

    async contentUpload(event, skip_scroll_check=false) {
      let scrolled = false;
      if(event){
        if (this.isMobileView) {
          scrolled = (event.target.scrollLeft*window.devicePixelRatio) >=
              ((event.target.scrollWidth - event.target.clientWidth));
        } else {
          scrolled = (event.target.scrollTop*window.devicePixelRatio) >=
              event.target.scrollHeight - event.target.clientHeight;
        }
      }

      if (
        (scrolled &&
        !this.pageControl.isLoading &&
        this.pageControl.currentPage < this.pageControl.totalPages) || skip_scroll_check
      ) {
        const panel = this.panels.find((p) => p.id == this.selectedPanelId);
        if (!panel) return;

        this.pageControl.isLoading = true;

        const items = await this.getOverlaysByParams(
          this.pageControl.currentPage + 1
        );
        await this.checkAfterLoad([...panel.panelItems, ...items]);

        this.pageControl.isLoading = false;
      }
    },

    async setOverlayOptions(overlayOptions) {
      this.overlayOptions = {
        ...this.overlayOptions,
        ...overlayOptions,
      };

      await this.setupOverlay();
    },

    async favoritesPropChange(itemId, panelId) {
      const { favoritesList, panels } = await this.$store.dispatch(
        "favoritesPropChange",
        {
          panelOpened: this.panelOpened,
          panels: this.panels,
          favoritesList: this.favoritesList,
          panelName: this.panelNameFavorite,
          itemId,
          panelId,
        }
      );

      this.favoritesList = favoritesList;
      this.panels = panels;
    },

    popUpPropChange(label) {
      if (
        this.popUpVisible !== false &&
        this.popUpLabel !== label &&
        label !== ""
      ) {
        return (this.popUpLabel = label);
      } else {
        return (
          (this.popUpVisible = !this.popUpVisible), (this.popUpLabel = label)
        );
      }
    },

    async getOverlaysByParams(page = 1) {
      this.pageControl.currentPage = page;
      const overlays =
        (await fetch(
          `${this.$store.state.prefixURL}/api/overlays/${this.selectedPanelId}?page=${page}`
        ).then((res) => res.json())) || [];
      this.pageControl.totalPages = overlays.total_pages;

//      setTimeout(() => {
//        overlays.data.forEach(overlay => fetch(overlay.url));
//      }, 1000);

      return overlays.data;
    },

    async selectCategory(panelName, panelId) {
      this.selectedPanelId = panelId;
      this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
      const panelIndex = this.panels.findIndex((panel) => panel.id === panelId);
      if (panelIndex == -1) return;

      this.panelLoading = true;
      if (this.panelOpened === this.panels[panelIndex].title) {
        this.panelOpened = this.panels[panelIndex].title;
        this.panelClass = "";
        this.isLoading = true;
      } else {
        analitics.sendEvent(panelName, analitics.categ.overlay);

        this.panelOpened = this.panels[panelIndex].title;
        this.panelClass = "active";
      }

      let items = await this.getOverlaysByParams();
      await this.checkAfterLoad(items);
      this.showPanel = false;
    },

    async checkAfterLoad(items = []) {
      const panelIndex = this.panels.findIndex(
        (panel) => panel.id === this.selectedPanelId
      );
      if (panelIndex == -1) return;

      items = items.map((item) => {
        if (this.favoritesList.find((fi) => fi.id == item.id)) {
          item.favoritesIconIsChecked = true;
        }

        return item;
      });

      // this.panels[panelIndex].panelItems = items;
      await new Promise((resolve) => {
        this.$lib.TexturesHelper.loadingTextures(
          undefined,
          { paths: items.map((i) => i.thumb), isServer: true },
          async (err, res) => {
            if (err) {
              return this.selectCategory(panelName, this.selectedPanelId);
            }

            await this.generatePreviewOverlays(
              res,
              this.selectedPanelId,
              items
            );

            this.panelLoading = false;
            resolve();
          }
        );
      });
    },

    async generatePreviewOverlays(resources = {}, panelId, items = []) {
      const panelIndex = this.panels.findIndex((panel) => panel.id === panelId);
      const cachedResource = await this.getStageTexture();

      for (let i = 0; i < items.length; i++) {
        if (items[i] && !items[i].genImage) {
          const STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(
            items[i].thumb
          );

          const prevImage = await this.$lib.Overlays.previewByParams(
            cachedResource.texture, STexture,
            items[i].params ? items[i].params : this.uniformsOverlay
          );

          if (prevImage) {
            items[i].imgPath = prevImage;
            items[i].genImage = true;
          }
        }
      }

      this.panels[panelIndex].panelItems = items;
      this.panels = [...this.panels];
    },

    async discardChanges() {
      if (this.$store.state.premiumStatus) {
        this.$store.commit("changePremiumStatus", false);
      }

      this.$lib.watermark.remove(true);

      if (this.isPreviewOverlay) {
        this.isPreviewOverlay = false;

        await this.$lib._cache.renderSaveByTimestamp();
        this.$store.dispatch("fabricSlide", false);
        this.$store.dispatch("isUpdateCache");
        this.selectedPresetId = null;
      }
    },

    async panelPropChange() {
      this.appliedPresetId = false;
      await this.discardChanges();

      this.panelOpened = "";
      this.panelClass = "";
      this.panelLoading = true;
      this.popUpVisible = false;
    },

    async getStageTexture() {
      const stageBase64 = await this.$lib._cache.getLastThumbBase64(); //await this.$lib.app.extractStage();
      const cachedResource = await this.$lib.app.addToLoader(
        `${Date.now()}-thumb`,
        stageBase64
      );

      return cachedResource;
    },

    async favoritesOpen() {
      this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
      this.panelOpened = "Favorites";
      this.panelClass = "active";
      this.panelLoading = true;

      this.$lib.TexturesHelper.loadingTextures(
        undefined,
        { paths: this.favoritesList.map((i) => i.thumb), isServer: true },
        async (err, res) => {
          if (err) {
            return this.selectCategory(this.panelOpened);
          }

          const cachedResource = await this.getStageTexture();
          for (let i = 0; i < this.favoritesList.length; i++) {
            const STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(
              this.favoritesList[i].thumb
            );

            this.favoritesList[i].params = {
              ...this.uniformsOverlay,
              ...(this.favoritesList[i].params
                ? this.favoritesList[i].params
                : {}),
            };

            const prevImage = await this.$lib.Overlays.previewByParams(
              cachedResource.texture,
              STexture,
              {
                ...JSON.parse(JSON.stringify(this.favoritesList[i].params)),
                alpha: this.uniformsOverlay.alpha,
              }
            );

            if (prevImage) {
              this.favoritesList[i].imgPath = prevImage;
            }
          }

          this.panelLoading = false;
        }
      );
    },

    async overlayPreview(data = {}) {
      if(this.appliedPresetId == data.id) {
        this.appliedPresetId = null;
        this.discardChanges();

        return;
      }

      // this.resetDrawing();
      // this.popUpVisible = false;
      this.$store.dispatch("preloader", { message: "Loading...", isShow: true });

      await this.discardChanges();
      this.$store.dispatch("fabricSlide", true);
      await this.$store.dispatch("checkPremiumStatus", {
        panels: this.panels,
        favorites: this.favoritesList,
        presetId: data.id,
      });

      this.isPreviewOverlay = true;
      this.selectedPresetId = data.id;
      this.overlayOptions = JSON.parse(JSON.stringify(data.params || {}));
      this.selectedOverlayData = JSON.parse(JSON.stringify(data));

      await this.setupOverlay();

      this.$store.dispatch("preloader", { message: "Loading...", isShow: false });
      this.appliedPresetId = data.id;
    },

    setupEffect() {
			try {
				const container = this.$lib.app.stage.children[1];
				const filter = this.getFilterByContainer(container);
				if(!filter) return console.error('ERRR FILTER');

				container.children[1].visible = !!this.eraseData.showMask;
				container.children[0].visible = !this.eraseData.showMask;
				filter.uniforms.invert = !!this.eraseData.invertSelection;

				if (this.maskTexture && this.maskTexture.visible) {
					this.updateMask(container, this.maskTexture.data, this.maskTexture.width, this.maskTexture.height);
				} else {
					this.updateMask(container, new Uint8Array(4).fill(0), 1, 1);
				}

			} catch (err) {
				//console.error(err);
			}
		},

    async sliderUpdate(alpha) {
      if (!this.selectedOverlayData) {
        return;
      }

      this.uniformsOverlay = { ...this.uniformsOverlay, alpha: alpha / 100 };
      await this.setupOverlay(this.uniformsOverlay);
    },

    async setupOverlay(options = {}, params = { isApply: false }, isTick = false) {
      if (!this.selectedPresetId && this.selectedPresetId !== 0) return false;

      const ticker = () => {
				const sprite = this.exportDraw();

				this.setMaskTexture(sprite, true, true);
			};

      if(params.isApply) {
        this.eraseData.showMask = false;
			  this.setupEffect();
      }

      this.uniformsOverlay = {
        ...this.overlayOptions,
        ...options,
        alpha: this.uniformsOverlay.alpha,
      };

      let preset = this.panels
        .reduce((arr, panel) => {
          if (panel.panelItems) {
            arr = [...arr, ...panel.panelItems];
          }

          return arr;
        }, [])
        .find((p) => p.id == this.selectedPresetId);

      if (!preset) {
        preset = this.favoritesList.find((f) => f.id == this.selectedPresetId);
        if (!preset) return false;
      }

      if(params.isApply) {
        this.selectedPresetId = false;
      }

      if (preset.isPremium && params.isApply) {
        const isHavePremium = await this.$store.dispatch(
          "isPremiumCurrentUser"
        );
        if (!isHavePremium) {
        //  if (window.abVariant && window.abVariant == 2) {
						this.$store.dispatch("popupsToggle", {
							property: "stripeCheckoutPopup",
							value: true,
						});
					// }
					// else{
					// 	this.$store.dispatch("popupsToggle", {
					// 		property: "upgradePopup",
					// 		value: true,
					// 	});
					// }
          return false;
        }
      }
      // preset.params.mode = 'Multiply'

      const STexture = await this.$lib.TexturesHelper.loaderTextureByUrl(
        preset.url.replace('https://color2.webstaginghub.com', location.origin)
      );

      if (!params.updateId) {
        params.updateId = this.$lib.CONST.MAP_CHANGES.BG_OVERLAY_EFFECT;
      }

      this.$lib.watermark.renderPremium(preset.isPremium && !this.$store.state.isPremiumAccount, undefined, [false, true]);
      await this.$lib.Overlays.setup(
        {
          STexture,
          originalSprite: this.$lib.app.stage.children[0],
        },
        this.uniformsOverlay,
        { ...params, mask: { ticker, eraseConfig: this.eraseData } }
      );

      if(isTick) { ticker(); }

      return true;
    },

    async applyOverlay(data, slider=false) {
      if (!this.appliedPresetId) return;
      if (!this.selectedOverlayData) {
        return;
      }

      await this._preloader(true);
      await this.applyMixin();
      const resultApply = await this.setupOverlay(this.uniformsOverlay, {
        isApply: true,
      });
      if (!resultApply) {
        // await this._preloader(false);
        // return;

        //start:added for upgrade button not showing after first time
        const isHavePremium = await this.$store.dispatch(
                "isPremiumCurrentUser"
        );
        if (!isHavePremium) {
          // if (window.abVariant && window.abVariant == 2) {
						this.$store.dispatch("popupsToggle", {
							property: "stripeCheckoutPopup",
							value: true,
						});
					// }
					// else{
					// 	this.$store.dispatch("popupsToggle", {
					// 		property: "upgradePopup",
					// 		value: true,
					// 	});
					// }
          await this._preloader(false);
          return;
        }
        //end



      }

      await this._preloader(false);
      if(slider) {
        let height =this.$lib.app.fabricCanvas.height;
        let nowHeight, zoom;

        nowHeight = height - 31;
        zoom = nowHeight/this.$lib.app.stage.height;
        $(".centered-content").css({'transform': `scale(${zoom})`});
        $(".drag-wrapper").css({'height': 'calc(100vh - 280px)'})
        this.$lib.app.setFabricZoom(zoom);
      }
      this.$store.dispatch("changesApplied");
      this.$store.dispatch("isUpdateCache");
      this.$store.dispatch("fabricSlide", false);

      this.panelOpened = "";
      this.panelClass = "";
      this.popUpVisible = false;
      this.showPanel = true;
    },

    panelView(val) {
      this.showPanel = val;
    },
  },
};
</script>
<style scoped>
  .hidden {
    display: none;
  }
</style>
