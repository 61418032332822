export default {
    methods: {
        async _preloader(isShow = false, text = false) {
            if(isShow) {
                this.$store.dispatch("preloader", { isShow: true, message: text || 'Waiting...' });
                await new Promise(resolve => setTimeout(resolve, 100));
            } else {
                this.$store.dispatch("preloader", { isShow: false });
            }
        }
    }
};