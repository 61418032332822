<template>
  <div class="input_with_icon">
    <span class="inch-search-icon input_icon" @click="searchButton()"></span>
    <input
      type="text"
      name
      :value="searchValue || ''"
      class="text_input search_input"
      :placeholder="SearchInputPlaceholder"
      :disabled="SearchInputDisabled || false"
      @input="searchInput"
      @update="searchUpdate"
      v-on:keyup.enter="searchSendRequest"
    />
    <svg
      @click="searchSendRequest()"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fill="#1d7ea8"
        d="M10,20A10,10,0,1,1,20,10,10.011,10.011,0,0,1,10,20ZM6.314,8.985a1,1,0,0,0,0,2h5v1.4a.8.8,0,0,0,1.367.566l2.4-2.4a.8.8,0,0,0,0-1.131l-2.4-2.4a.8.8,0,0,0-.566-.235.8.8,0,0,0-.8.8v1.4Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "SearchInputUnsplash",
  props: {
    SearchInputPlaceholder: String,
    SearchInputDisabled: Boolean,
    searchSendRequest: Function,
    searchValue: String,
  },
  data: function () {
    return {
      inputValue: this.searchValue || "",
    };
  },
  methods: {
    searchInput(value) {
      this.inputValue = value.target.value;
      this.$emit("searchInput", value);
    },

    searchUpdate(value) {
      this.$emit("searchUpdate", value || this.inputValue);
    },

    searchButton() {
      this.$emit("searchUpdate", { target: { value: this.inputValue } });
    },
  },
};
</script>

<style scoped>
.inch-search-icon {
  pointer-events: all !important;
  cursor: pointer;
}

.inch-search-icon::before {
  padding: 10px 12px;
}

.input_with_icon .input_icon {
  left: 14px;
}

.popup .text_input {
  padding-left: 40px;
}

.popup .text_input {
  text-align: left;
  font: normal normal normal 16px Proxima Nova;
  letter-spacing: -0.77px;
  color: #8c8c8c;
  padding: 14px 0 14px 46px;
  height: 46px;
}

.inch-search-icon.input_icon {
  left: 18px;
}

.inch-search-icon.input_icon::before {
  font-size: 16px;
}

.text_input:focus ~ svg path {
  fill: #249acf;
}

.text_input {
  background: #1d1d1d 0% 0% no-repeat padding-box !important;
  border: 1px solid #3f3f3f !important;
}

.text_input ~ svg {
  position: absolute;
  top: 14px;
  right: 12px;
}

.text_input:focus {
  box-shadow: 0px 0px 8px #ffffff14 !important;
  border: 1px solid #249acf !important;
  background: #1d1d1d 0% 0% no-repeat padding-box !important;
  color: #D7DCDD !important;
}
</style>
