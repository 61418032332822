<template>
    <div class="effects_item_upper">
        <a class="category_card" v-on:click="favoritesOpen">
            <!-- <div class="img_wrpr">
                <img :src="imgPath" alt="">
            </div> -->
            <div class="text_wrpr">
                <span>Favorites</span>
                <span>{{favoritesCount}}</span>
            </div>
        </a>
    </div>
</template>

<script>

    export default {
        name: "Favorites",
        props: {
            imgPath: String,
            favoritesCount: Number,
            favoritesOpen: Function
        }
    };
</script>