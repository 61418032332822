<template>
    <ul class="list" :class="listClass"> 
        <slot />
    </ul>
</template>

<script>
    import { ContainerMixin } from 'vue-slicksort'
    export default {
        name: 'LayerList',
        mixins: [ContainerMixin],
        props: {
            listClass: String
        },
        methods: {
            onSortStart(e) {
                console.log({sortStart: e});
            },
            onSortEnd(e) {
                console.log({onSortEnd: e});
            },
        }
    }
</script>
    