<template>
  <div class="tool-item-panel" :class="editPanelClass">
    <div class="panel-top" v-on:click="closeIt">
      <div class="back">
        <a href="#" class="back-icon">
          <span class="inch-icon inch-back-icon"></span>
        </a>
        <h3>Vibrance</h3>
      </div>
      <div
        class="help-box"
        @click.stop="popUpPropChange('popupsToggleStatus', true)"
      >
        <img :src="helpIcon" alt />
      </div>
    </div>
    <div v-bar>
      <div class="panel-content wo_pad">
        <div class="panel_row margin_bottom_15">
          <RangeLib
            rangeSliderId="editVibrance"
            :rangeSliderValue="vibrance"
            rangeSliderLabel="Vibrance"
            rangeSliderMeasure="%"
            rangeSliderClass="range-vibrance"
            :minValue="0"
            :maxValue="100"
            :interval="1"
            :defaultValue="50"
            @update="updateVibrance"
          />
        </div>
        <div class="d-flex between two-column panel_row">
          <div class="half">
            <a tabIndex="0" class="button btn-action-all btn-action cancel" v-on:click="closeIt">cancel</a>
          </div>
          <div class="half">
            <a tabIndex="0" class="button btn-action-all btn-action upgrade" v-on:click="applyIt">apply</a>
          </div>
        </div>
        <AdLeftPanel v-if="isLeftAd" margin="20px 14px" />
      </div>
    </div>
    <div
      class="pop_up_closer"
      v-if="getPopupStatus"
      @click="popUpPropChange('popupsToggleStatus', false)"
    ></div>
    <Tips v-if="getPopupStatus" :text="tipsText">
      <template v-slot:body>
        <div class="tooltip_row_item">
          <p class="simple_light_p ">
            The Vibrance tool intelligently increases<br />
            the intensity of the more muted colors and<br />
            leaves the already well-saturated colors<br />
            alone.
          </p>
        </div>
      </template>
    </Tips>
  </div>
</template>

<script>
import RangeLib from "@/components/RangeLib.vue";
import Tips from "@/components/Tips.vue";
import AdLeftPanel from "@/components/AdLeftPanel.vue";

export default {
  name: "EditVibrancePanel",
  components: {
    RangeLib,
    Tips,
    AdLeftPanel,
  },
  props: {
    editPanelClass: String,
    editPanelPropChange: Function,
    closeCurrentEditor: Function,
    applyChanges: Function,
  },
  data: function() {
    return {
      tipsText: "",
      helpIcon: require("@/assets/img/help-icon.svg"),
      vibrance: 0,
      vibranceEffect: false,
    };
  },
  mounted() {
    this.$store.dispatch("openHideTips", this.$vnode.componentOptions.tag);
    this.$store.dispatch("fabricSlide", true);
    this.vibranceEffect = new this.$lib.effects.VibranceEffect(
      this.$lib.app.stage.children[0]
    );
    this.updateVibrance(50);
  },

  beforeDestroy() {
    this.closeCurrentEditor();
  },

  methods: {
    updateVibrance(value) {
      this.vibrance = value;
      this.vibranceEffect.setupEffect({ amount: value / 100 });
    },
    async applyIt() {
      await this.$lib._cache.addToBgCacheUpdating(
        this.$lib.CONST.MAP_CHANGES.BG_VIBRANCE_EFFECT,
        {},
        true,
        { isThumb: true }
      );

      await this.applyChanges(this.$lib);
      this.$store.dispatch("changesApplied");
    },
    closeIt() {
      this.closeCurrentEditor(this.$lib);
    },
    popUpPropChange(property, value) {
      this.$store.dispatch("popupsToggleStatus", { property, value });
    },
    changePopup(property, value) {
      this.$store.dispatch("popupsToggle", { property, value });
    },
    changesAppliedShow() {
      this.changePopup("changesApplied", true);
      this.editPanelPropChange();
      setTimeout(() => {
        this.changePopup("changesApplied", false);
      }, 1000);
    },
  },
  watch: {
    async _applySelectedEffect() {
      try {
        await this.applyIt();
      } catch (err) {}
      this.$store.state._callbackSave += 1;
    },
  },
  computed: {
    isLeftAd() {
      return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.left_panel_ad?.is_left_panel_ad) ?? false;
    },
    _applySelectedEffect() {
      return this.$store.state._applySelectedEffect;
    },
    getPopupStatus() {
      return this.$store.state.global.popupStatus.value;
    },
  },
};
</script>
